import React, { useState } from "react";
import L from "leaflet";
import { Polyline, useMap } from "react-leaflet";
import { MapPosition } from "./webMapContainer";

export interface WebPolyLineProps {
  positions: MapPosition[];
  polyOptions: { color: string };
  actionOnClick?: boolean;
}
// Defines the width of the drawn line
const lineWeights = {
  default: 2,
  selected: 5,
};

export const WebPolyLine = (props: WebPolyLineProps) => {
  const map = useMap();
  map.fitBounds(L.latLngBounds(props.positions));
  const [strokeWeight, setStrokeWeight] = useState(2);
  const mapColorHandler = () => ({
    click: () => {
      if (!props.actionOnClick) {
        setStrokeWeight(strokeWeight === lineWeights.default ? lineWeights.selected : lineWeights.default);
      }
    },
  });

  return (
    <Polyline
      eventHandlers={mapColorHandler()}
      pathOptions={{ color: props.polyOptions.color, weight: strokeWeight }}
      positions={props.positions}
    />
  );
};
