import styled from "styled-components";
import { InputGroup, MenuItem } from "@ais/ui";

export const CogIconWrapper = styled.div`
  box-shadow: 0 4px 8px 0 #857e92, 0 6px 20px 0 #857e92;
  background: #efecf4;
  padding: 3px;
  border-radius: 8px;
`;

export const CogMenuItem = styled(MenuItem)`
  flex-grow: 0;
  width: 200px;
`;

export const InputField = styled(InputGroup)`
  background-color: inherit;
  padding: 0px;
  .bp3-input {
    background-color: inherit;
    box-shadow: none;
    color: #4d4f5c;
    font-size: 12px;
    border-radius: 0px;
    :focus {
      border-bottom: 1px solid ${p => p.theme.primary};
      box-shadow: none;
      border-radius: 0px;
      color: ${p => p.theme.primary};
    }
    padding: 0px;
    text-overflow: ellipsis;
    flex: 1;
    height: 30px;
  }
`;
