import styled from "styled-components";

export interface GroupIndentProps {
  $level: number;
}

export const GroupIndent = styled.span<GroupIndentProps>`
  padding-left: ${props => `${props.$level * 15}px`};
`;

export interface GroupLabelWrapperProps {
  $isExpanded: boolean;
}

export const GroupLabelWrapper = styled.div<GroupLabelWrapperProps>`
  font-size: 11px;
  font-weight: bold;
  line-height: 29px;
  background-color: #efecf4;
  color: #43425d;
  padding-left: 6px;
  :first-child {
    line-height: 28px;
  }
`;

export interface RowWrapperProps {
  $isExpanded: boolean;
}

export const RowWrapper = styled.div<RowWrapperProps>`
  font-size: 11px;
  background-color: #efecf4;
  color: #43425d;
  border-bottom: 1px solid;
  border-color: ${props => props.$isExpanded ? "#f1f1f3" : "white"};
  :first-child {
    border-top: 1px solid #fff;
  }
`;

export const AggregatedWrapper = styled.div`
  padding-left: 10px;
  color: #43425d;
`;

export interface GroupValProps {
  $isEmpty: boolean;
}

export const GroupVal = styled.span<GroupValProps>`
  color: ${props => props.$isEmpty ? "grey" : "inherit"};
`;
