import React from "react";
import * as Core from "@blueprintjs/datetime";
import { Classes } from "@blueprintjs/core";
import { maxDate, minDate } from "./constants";

export interface DateRangeInputProps {
  value: Core.DateRange;
  onChange: (selectedRange: Core.DateRange) => void | undefined;
}

export const DateRangeInput = (props: DateRangeInputProps) => {
  const handleDateChange = (date: Core.DateRange) => {
    props.onChange?.(date);
  };
  return (
    <>
      <Core.DateRangeInput
        className={Classes.ELEVATION_1}
        formatDate={value => value.toLocaleString()}
        maxDate={maxDate}
        minDate={minDate}
        parseDate={str => new Date(str)}
        shortcuts={false}
        value={props.value}
        onChange={handleDateChange}
      />
    </>
  );
};
