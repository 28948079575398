import styled from "styled-components";
import { Icon } from "@ais/ui";

export const UserProfileIcon = styled.img`
  border-radius: 10px;
  width: 100%;
  height: 50%;
  align-items: center;
`;

export const IconLink = styled(Icon)`
  cursor: pointer;
`;

export const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;
