import { Troponic as TroponicClients } from "@aisalfaplan/troponic";
import { AccessToken } from "auth";

export function troponicClients(troponicUrl: string, token: AccessToken): TroponicClients {
  const { accessToken, identity } = token;
  const tenantId = identity.profile?.["ais.tenant"]?.toString() ?? "";
  return new TroponicClients({
    baseURL: troponicUrl,
    headers: { Authorization: `Bearer ${accessToken}` },
    tenantId,
  });
}
