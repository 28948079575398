import React from "react";

export interface SvgProps {
  src: string;
  width?: number;
  height?: number;
  fill?: string;
  style?: React.CSSProperties;
}

export class Svg extends React.PureComponent<SvgProps> {
  private readonly img = React.createRef<HTMLImageElement>();
  public render() {
    const { src, ...svgProps } = this.props;
    return (
      <img ref={this.img} src={src} {...svgProps} />
    );
  }
}
