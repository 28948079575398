import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslator } from "@ais/core";
import { IItemRendererProps, MenuItem, Select } from "@ais/ui";

import { TranslationResource } from "locales";
import { ResourceFilterSet } from "../../../utils";
import { useConfirmation } from "../../../components";

import {
  Actions,
  CloseIcon,
  DateInput,
  Dialog,
  Icon,
  Inline,
  Input,
  SelectButton,
  ShadowBox,
  Switch,
  Text
} from "./filterDialog.styles";

type FilterType = "text" | "number" | "date";

export interface FilterModalProps {
  columnName: React.ReactNode | string;
  defaultFilter: ResourceFilterSet[];
  isOpen: boolean;
  type: FilterType;
  onClose: () => void;
  onFilterUpdate: (fc: ResourceFilterSet[]) => void;
}

type FilterSetValue = keyof ResourceFilterSet | "and" | "or";
interface FilterSet {
  label: string;
  value: FilterSetValue;
}

type FilterSetKey = "optionOne" | "optionTwo" | "condition";

type FilterSwitchState = {
  [K in FilterSetKey]?: FilterSet;
};

type FilterInputState = {
  [K in FilterSetKey]?: string;
};

export interface PreviousFilterSet {
  selectFilters: FilterSwitchState;
  inputFilters: FilterInputState;
  ignoreCase: boolean;
}

type InputType = string | number | undefined;

function propsAreEqual(prevProps: FilterModalProps, nextProps: FilterModalProps) {
  return prevProps.isOpen !== nextProps.isOpen;
}

const FilterModal = (props: FilterModalProps) => {
  const { isOpen, type, columnName, defaultFilter = [], onClose } = props;
  const { t, translationKeys } = useTranslator<TranslationResource>();
  const confirm = useConfirmation();
  const [selectFilters, setSelectFilters] = useState<FilterSwitchState | undefined>(undefined);
  const [inputFilters, setInputFilters] = useState<FilterInputState>({ optionOne: "", optionTwo: "" });
  const [ignoreCase, setIgnoreCase] = useState(false);

  const stringOption: FilterSet[] = [
    { label: t(translationKeys.grid.filterOption.startsWith), value: "startsWith" },
    { label: t(translationKeys.grid.filterOption.endsWith), value: "endsWith" },
    { label: t(translationKeys.grid.filterOption.includes), value: "includes" },
  ];

  const conditionOption: FilterSet[] = [
    { label: t(translationKeys.grid.filterOption.and), value: "and" },
    { label: t(translationKeys.grid.filterOption.or), value: "or" },
  ];

  const integerOption: FilterSet[] = [
    { label: t(translationKeys.grid.filterOption.larger), value: "larger" },
    { label: t(translationKeys.grid.filterOption.smaller), value: "smaller" },
    { label: t(translationKeys.grid.filterOption.equalTo), value: "equalTo" },
  ];

  const dateOption: FilterSet[] = [
    { label: t(translationKeys.grid.filterOption.laterOn), value: "laterOn" },
    { label: t(translationKeys.grid.filterOption.before), value: "before" },
    { label: t(translationKeys.grid.filterOption.include), value: "include" },
  ];

  let options = stringOption;
  switch (type) {
    case "date":
      options = dateOption;
      break;
    case "number":
      options = integerOption;
      break;
    case "text":
    default:
      options = stringOption;
      break;
  }

  const resourceFilterSet = new Set([
    "startsWith",
    "endsWith",
    "includes",
    "larger",
    "smaller",
    "equalTo",
    "laterOn",
    "before",
    "include",
  ]);

  useEffect(() => {
    if (defaultFilter.length > 0) {
      const data = defaultFilter.reduce(
        (r: PreviousFilterSet, c: ResourceFilterSet, i: number) => {
          const keys: FilterSetKey[] = ["optionOne", "optionTwo"];
          Object.entries(c).forEach((val, objIndex) => {
            const index = Object.keys(c).length > 1 ? objIndex : i;
            const option = options.find(v => v.value === val[0]);
            const conditionType = Object.keys(c).length > 1 ? "and" : "or";
            r.selectFilters.condition = conditionOption.find(v => v.value === conditionType);
            r.selectFilters[keys[index]] = option;
            r.inputFilters[keys[index]] = type === "text" ? val[1].text : val[1];
            if (type === "text") {
              r.ignoreCase = !val[1].ignoreCase;
            }
          });
          return r;
        },
        { selectFilters: {}, inputFilters: {}, ignoreCase: false }
      );
      setSelectFilters(data.selectFilters);
      setInputFilters(data.inputFilters);
      setIgnoreCase(data.ignoreCase);
    }
  }, []);

  return (
    <Dialog canOutsideClickClose={false} isOpen={isOpen}>
      <CloseIcon icon="cross" iconSize={20} onClick={onClose} />
      <Text>{columnName}:</Text>
      <ShadowBox>
        <Inline>
          <Select
            activeItem={selectFilters?.optionOne}
            filterable={false}
            itemDisabled={(item: FilterSet, index: number) => isItemDisabled(item, index, "optionTwo")}
            itemRenderer={itemRenderer}
            items={options}
            itemsEqual={itemsEqual}
            onItemSelect={(v: FilterSet) => handleSelectionChange("optionOne", v)}
          >
            <SelectButton name="optionOne" rightIcon="caret-down" text={selectFilters?.optionOne?.label} />
          </Select>
          {inputField("optionOne", inputFilters?.optionOne)}
        </Inline>
        <Inline>
          <Select
            activeItem={selectFilters?.condition}
            filterable={false}
            itemRenderer={itemRenderer}
            items={conditionOption}
            itemsEqual={itemsEqual}
            onItemSelect={(v: FilterSet) => handleSelectionChange("condition", v)}
          >
            <SelectButton name="condition" rightIcon="caret-down" text={selectFilters?.condition?.label} />
          </Select>
        </Inline>
        <Inline>
          <Select
            activeItem={selectFilters?.optionTwo}
            filterable={false}
            itemDisabled={(item: FilterSet, index: number) => isItemDisabled(item, index, "optionOne")}
            itemRenderer={itemRenderer}
            items={options}
            itemsEqual={itemsEqual}
            onItemSelect={(v: FilterSet) => handleSelectionChange("optionTwo", v)}
          >
            <SelectButton name="optionTwo" rightIcon="caret-down" text={selectFilters?.optionTwo?.label} />
          </Select>
          {inputField("optionTwo", inputFilters?.optionTwo)}
        </Inline>
        {type === "text" && (
          <Inline>
            <Switch
              checked={ignoreCase}
              label={t(translationKeys.grid.filterOption.ignoreCase)}
              onChange={(checked: boolean) => setIgnoreCase(checked)}
            />
          </Inline>
        )}
      </ShadowBox>
      <Actions>
        <Icon
          className={Object.keys(selectFilters || {}).length === 0 ? "in-active" : ""}
          icon="trash"
          iconSize={24}
          onClick={onClickDelete}
        />
        <Icon icon="key-backspace" iconSize={24} onClick={onClose} />
        <Icon icon="floppy-disk" iconSize={24} onClick={onSaveFilter} />
      </Actions>
    </Dialog>
  );

  function handleSelectionChange(name: string, selected: FilterSet) {
    if (
      name === "condition"
      && selected.value === "and"
      && selectFilters
      && selectFilters.optionOne?.value === selectFilters.optionTwo?.value
      && resourceFilterSet.has(selectFilters.optionTwo?.value ?? "")
    ) {
      setSelectFilters(v => ({ ...v, [name]: selected, optionTwo: undefined }));
    } else {
      setSelectFilters(v => ({ ...v, [name]: selected }));
    }
  }

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setInputFilters(v => ({ ...v, [name]: value }));
  }

  function itemRenderer(item: FilterSet, itemProps: IItemRendererProps) {
    return (
      <MenuItem
        key={item.value}
        active={itemProps.modifiers.active}
        disabled={itemProps.modifiers.disabled}
        text={item.label}
        onClick={itemProps.handleClick}
      />
    );
  }

  function isItemDisabled(item: FilterSet, _: number, key: FilterSetKey): boolean {
    return !!(
      selectFilters
      && selectFilters.condition?.value === "and"
      && selectFilters[key]?.value === item.value
      && resourceFilterSet.has(item.value)
    );
  }

  function onClickDelete() {
    setIgnoreCase(false);
    setSelectFilters(undefined);
    setInputFilters({ optionOne: "", optionTwo: "" });
    props.onFilterUpdate([]);
  }

  function itemsEqual(itemA: FilterSet, itemB: FilterSet) {
    return itemA.value === itemB.value;
  }

  function onSaveFilter() {
    const filteredData: ResourceFilterSet[] = [];
    const keys: FilterSetKey[] = ["optionOne", "optionTwo"];
    if (selectFilters) {
      keys.forEach((key: FilterSetKey) => {
        if (selectFilters[key]) {
          if (type === "text") {
            filteredData.push({
              [selectFilters[key]?.value as string]: { text: inputFilters[key], ignoreCase: !ignoreCase },
            });
          } else if (
            (["larger", "smaller", "laterOn", "before", "include"].includes(selectFilters[key]?.value ?? "")
                && inputFilters[key])
              || selectFilters[key]?.value === "equalTo"
          ) {
            filteredData.push({
              [selectFilters[key]?.value as string]: inputFilters[key],
            });
          }
        }
      });
      let filterConf: ResourceFilterSet[] = filteredData;
      if (selectFilters?.condition?.value === "and") {
        const val = filteredData.reduce(function(result, current) {
          return Object.assign(result, current);
        }, {});
        filterConf = [val];
      }
      if (filterConf.length > 0) {
        props.onFilterUpdate(filterConf);
        props.onClose();
      } else {
        confirm({ description: t(translationKeys.grid.filterOption.errorMessage) });
      }
    } else {
      confirm({ description: t(translationKeys.grid.filterOption.noFilterErrorMessage) });
    }
  }

  function onDateChange(name: string, date: Date) {
    setInputFilters(v => ({ ...v, [name]: moment(date) }));
  }

  function inputField(name: string, value: number | string | Date | undefined): JSX.Element {
    if (type === "date") {
      const format = "DD/MM/YYYY";
      return (
        <div>
          <DateInput
            formatDate={date => moment(date).format(format)}
            maxDate={new Date(2120, 1, 1)}
            minDate={new Date(1920, 1, 1)}
            parseDate={str => moment(str, format).toDate()}
            placeholder={format}
            popoverProps={{
              usePortal: false,
            }}
            value={value ? moment(value).toDate() : null}
            onChange={date => onDateChange(name, date)}
          />
        </div>
      );
    }
    return <Input className="bp3-input" name={name} type={type} value={value as InputType} onChange={onInputChange} />;
  }
};

export const FilterDialog = React.memo(FilterModal, propsAreEqual) as typeof FilterModal;
