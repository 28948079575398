import styled, { css } from "styled-components";
import {
  Dialog as BPDialog,
  Icon as BPIcon,
  Switch as BPSwitch,
  HTMLTable as HtmlTableComponent
  , InputGroup } from "@ais/ui";

export const Dialog = styled(BPDialog)`
  position: relative;
  border-radius: 20px;
  padding: 36px 40px 20px;
  background: ${props => props.theme.background};
  width: max-content;
  .in-active {
    opacity: 0.5;
    pointer-events: none;
  }
  min-width: 920px;
  min-height: 450px;
  width: 70vw;
  height: 60vh;
  overflow-x: auto;
`;

export const CloseIcon = styled(BPIcon)`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  background: ${props => props.theme.primary};
  color: ${props => props.theme.icon.default};
  border-radius: 100%;
  padding: 4px;
`;

export interface HTMLTableProps {
  fixed?: boolean;
}

export const HTMLTable = styled(HtmlTableComponent)<HTMLTableProps>`
  ${props =>
    props.fixed
    && css`
      table-layout: fixed;
    `};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  min-height: 230px;
  height: 100%;
  width: 60vw;
  min-width: 820px;
  padding-bottom: 20px;
  user-select: none;
`;

export const ContainerBody = styled.div`
  overflow-y: auto;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex: 1;

  > table.bp3-html-table {
    width: 100%;
  }

  display: flex;

  thead th {
    position: sticky;
    top: 0;
    background: ${props => props.theme.background};
    z-index: 1;
  }
`;

export const Switch = styled(BPSwitch)`
  margin-bottom: 0px;
  & .bp3-control-indicator {
    outline: none !important;
  }
  & input:checked ~ .bp3-control-indicator {
    background: ${props => props.theme.primary} !important;
  }
`;

export const RowItem = styled.tr`
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.hover};
  }
`;

export const NameCell = styled.div`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Input = styled(InputGroup)`
  width: 100%;
  margin: 20px 0px;
`;

export const HeaderRow = styled.tr``;

export const TableHeader = styled.th``;

export const TableSwitchHeader = styled.th`
  width: 50px;
`;

export const ActionContainer = styled.div`
  margin-left: 20px;
  margin-top: 30px;
`;
