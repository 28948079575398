import React, { FunctionComponent } from "react";
import * as Core from "@blueprintjs/core";

export interface CardProps {
  className?: string;
  elevation?: Core.Elevation;
  interactive?: boolean;
}

export const Card: FunctionComponent<CardProps> = (props) => {
  const { className, elevation = Core.Elevation.ONE, interactive } = props;
  return (
    <Core.Card
      className={className}
      elevation={elevation}
      interactive={interactive}
    >
      {props.children}
    </Core.Card>
  );
};
