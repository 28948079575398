import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ColumnInstance, TableState } from "react-table";
import { useTranslator } from "@ais/core";
import { Popover, useLocalStorage } from "@ais/ui";
// TODO: import { useUserProfile } from "@ais/core";
import { TranslationResource } from "locales";
import {
  BaseRow,
  CogMenuState,
  PersistedViewConfig,
  ViewConfigurations
} from "types";
import { useConfirmation } from "../../../components/confirmationDialog";
import {
  HeaderColumn,
  SaveViewDialog,
  SelectView
} from "../../../components";
import {
  Constants,
  FilterConfigurations,
  resolveColumnMovable,
  resolveCurrentView,
  resolveViewConfig,
  storeColumnMovable
} from "../../../utils";
import { GridIcon, GridMenu } from "../../../styles/global";

import { CogIconWrapper, CogMenuItem, InputField } from "./cogMenu.styles";

type CogMenuItems = "showColumn" | "selectView" | "saveView" | "editView" | "showFilter" | "columnsMoveable";

const initialCogMenuState: CogMenuState = {
  showFilter: false,
  columnsMoveable: false,
  saveView: false,
  editView: false,
  selectView: false,
};

export interface CogMenuProps<D extends BaseRow> {
  appliedView: PersistedViewConfig<D>;
  columns: ColumnInstance<D>[];
  enableViews?: boolean;
  filterable?: boolean;
  gridKey: keyof TranslationResource;
  moveableColumns?: boolean;
  persistedColumn: string[];
  tableRef: TableState<D> | undefined;
  onShowFilterSelect: (show: boolean) => void;
  isColumnRemoveable: (id: string) => boolean;
  onColumnRemove: (id: string) => void;
  onColumnShow: (id: string) => void;
  onDeleteView: () => void;
  onSaveView: (viewState: PersistedViewConfig<D>, oldView?: ViewConfigurations) => void;
  onViewSelect: (view: PersistedViewConfig<D>) => void;
  onColumnMovableSelect: (columnMove: boolean) => void;
}

const isLastVisibleColumn = <D extends BaseRow>(columns: ColumnInstance<D>[]) => {
  const visibleColumns = columns.filter(c => c.isVisible);
  return visibleColumns.length === 1;
};

export const CogMenu = <D extends BaseRow>(props: CogMenuProps<D>) => {
  const {
    appliedView,
    columns,
    enableViews = false,
    filterable = false,
    gridKey,
    moveableColumns = false,
    persistedColumn,
    tableRef,
    onColumnRemove,
    isColumnRemoveable,
    onColumnShow,
    onViewSelect,
    onDeleteView,
    onSaveView,
    onShowFilterSelect,
    onColumnMovableSelect,
  } = props;

  const { t, translationKeys } = useTranslator<TranslationResource>();
  const confirm = useConfirmation();
  const localStorage = useLocalStorage();
  // TODO: This will be required -> const user = useUserProfile();

  const inputRef = React.createRef<HTMLInputElement>();
  const [query, setQuery] = useState("");
  const [cogMenuState, setCogMenuState] = useState(() => {
    return {
      ...initialCogMenuState,
      columnsMoveable: resolveColumnMovable<D>(localStorage, gridKey),
      showFilter: (appliedView?.states.filters?.length ?? 0) > 0,
    };
  });

  useEffect(() => {
    const currentView = resolveCurrentView(localStorage, gridKey);

    setCogMenuState(prevState => ({
      ...prevState,
      columnsMoveable: resolveColumnMovable<D>(localStorage, gridKey),
      showFilter: (currentView?.states.filters?.length ?? 0) > 0,
    }));
  }, [localStorage]);

  useEffect(() => {
    const hasLineFilter = (tableRef?.filters ?? []).length > 0;
    setCogMenuState(prevState => ({
      ...prevState,
      showFilter: hasLineFilter,
    }));
    onShowFilterSelect(hasLineFilter);
  }, [tableRef]);

  const filteredColumns = columns.filter(c => c.id !== "selection" && getLabel(c).includes(query.toLowerCase()));

  const cogMenuItems: CogMenuItems[] = ["showColumn"];

  if (enableViews) {
    cogMenuItems.push("selectView", "saveView", "editView");
  }

  if (filterable) {
    cogMenuItems.push("showFilter");
  }

  if (moveableColumns) {
    cogMenuItems.push("columnsMoveable");
  }

  const menu = (
    <GridMenu>
      {cogMenuItems.map(item => {
        if (item === "showColumn") {
          return (
            <CogMenuItem key={item} icon="blank" text={t(translationKeys.grid.cogMenu[item])}>
              <InputField autoFocus inputRef={inputRef} leftIcon="search" value={query} onChange={handleInputChanged} />
              {filteredColumns.length === 0 && <CogMenuItem disabled text={t(translationKeys.grid.cogMenu.noSearchResult)} />}
              {filteredColumns.map(col => {
                const column = (col as unknown) as HeaderColumn<D>;

                const toggleColumnVisibility = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  e.stopPropagation();
                  if (isLastVisibleColumn(columns) && column.isVisible) {
                    confirm({ description: t(translationKeys.grid.cogMenu.lastColumnErrorMessage) });
                    return;
                  }
                  if (column.isVisible) {
                    if (isColumnRemoveable(column.id)) {
                      column.toggleHidden(column.isVisible);
                      if (column.isGrouped) {
                        column.toggleGroupBy();
                      }
                      onColumnRemove(column.id);
                    }
                  } else {
                    column.toggleHidden(column.isVisible ?? false);
                    onColumnShow(column.id);
                  }
                };

                return (
                  <CogMenuItem
                    key={column.id}
                    icon={column.isVisible ? "small-tick" : "blank"}
                    text={column.render("Header")}
                    onClick={toggleColumnVisibility}
                  />
                );
              })}
            </CogMenuItem>
          );
        }
        return (
          <CogMenuItem
            key={item}
            icon={cogMenuState[item] ? "small-tick" : "blank"}
            text={t(translationKeys.grid.cogMenu[item])}
            onClick={() => onCogMenuItemClick(item)}
          />
        );
      })}
    </GridMenu>
  );

  return (
    <>
      <Popover autoFocus={false} content={menu} position="bottom-left" onClose={() => setQuery("")}>
        <CogIconWrapper>
          <GridIcon icon="cog" iconSize={11} />
        </CogIconWrapper>
      </Popover>
      {cogMenuState.saveView && (
        <SaveViewDialog
          defaultViewConfig={cogMenuState.editView ? appliedView : undefined}
          isOpen={cogMenuState.saveView}
          onClose={() => setCogMenuState(prevState => ({ ...prevState, saveView: false }))}
          onSave={handleViewSave}
        />
      )}
      {(cogMenuState.selectView || cogMenuState.editView) && (
        <SelectView<D>
          gridKey={gridKey}
          isOpen={cogMenuState.selectView || cogMenuState.editView}
          mode={cogMenuState.editView ? "edit" : "select"}
          onClose={() => setCogMenuState(prevState => ({ ...prevState, selectView: false, editView: false }))}
          onDelete={handleViewDelete}
          onEdit={handleViewSave}
          onEditViewSelect={handleEditViewDialog}
          onSelect={handleViewSelect}
        />
      )}
    </>
  );

  function handleEditViewDialog() {
    setCogMenuState(prevState => ({ ...prevState, selectView: false, editView: true }));
  }

  function getLabel(column: ColumnInstance<D>) {
    // TODO: This works for now but needs a better logic.
    const columnName = column.render("Header");
    if (typeof columnName === "string") {
      return columnName.toLowerCase();
    }
    return "";
  }

  function handleInputChanged(value: string) {
    setQuery(value);
  }

  async function onCogMenuItemClick(id: string) {
    switch (id) {
      case "showFilter":
        if (cogMenuState.showFilter) {
          const allEmpty = tableRef?.filters.every(f => _.isEmpty((f.value as FilterConfigurations).lineFilter));
          if (!allEmpty) {
            const decission = await confirm({
              title: "Confirm",
              description: t(translationKeys.grid.hideLineFilterConfirmation),
              variant: "confirm",
            });

            if (decission) {
              setCogMenuState(prevState => ({ ...prevState, [id]: !prevState[id] }));
              onShowFilterSelect(!cogMenuState.showFilter);
            }
          } else {
            setCogMenuState(prevState => ({ ...prevState, [id]: !prevState[id] }));
            onShowFilterSelect(!cogMenuState.showFilter);
          }
        } else {
          setCogMenuState(prevState => ({ ...prevState, [id]: !prevState[id] }));
          onShowFilterSelect(!cogMenuState.showFilter);
        }
        break;
      case "columnsMoveable":
        storeColumnMovable(localStorage, gridKey, cogMenuState.columnsMoveable);
        setCogMenuState(prevState => ({ ...prevState, [id]: !prevState[id] }));
        onColumnMovableSelect(!cogMenuState.columnsMoveable);
        break;
      case "saveView":
      case "editView":
      case "selectView":
        setCogMenuState(prevState => ({ ...prevState, [id]: !prevState[id] }));
        break;
      default:
        break;
    }
  }

  function handleViewSave(view: ViewConfigurations, oldView?: ViewConfigurations) {
    const viewState: PersistedViewConfig<D> = {
      ...view,
      states: {
        filters: view.filter.filter ? tableRef?.filters : undefined,
        groups: view.filter.grouping ? tableRef?.groupBy : [],
        columnOrders: view.filter.table ? persistedColumn : [],
        sortBy: tableRef?.sortBy ?? [],
      },
    };

    onSaveView(viewState, oldView);

    setCogMenuState(prevState => ({ ...prevState, saveView: false }));
    // TODO: Work on save the view of the grid
    // if (view.visibility === t(translationKeys.grid.saveView.all)) {
    //   users
    //     .filter(u => u.id !== user.id)
    //     .forEach(user => {
    //       const partitionKey = Constants.storage.partitionKey + "-" + user.id;
    //       const item = window.localStorage.getItem(partitionKey);

    //       const data = item ? ((JSON.parse(item) as unknown) as StoredMap) : [];
    //       const keyMap = new Map<string, string>(data);
    //       const viewConfigStr = keyMap.get(Constants.persistence.viewPartitionKey);
    //       const savedView = (viewConfigStr
    //         ? (JSON.parse(viewConfigStr) as unknown)
    //         : { configurations: [] }) as PersistedView<D>;

    //       savedView.configurations = savedView.configurations.filter(c => c.name.localeCompare(oldView?.name ?? "") !== 0);
    //       savedView.configurations = [
    //         ...savedView.configurations.filter(c => c.name.localeCompare(view?.name) !== 0),
    //         viewState,
    //       ];

    //       keyMap.set(Constants.persistence.viewPartitionKey, JSON.stringify(savedView));

    //       const updatedData: StoredMap = Array.from(keyMap.entries());
    //       const json = JSON.stringify(updatedData);

    //       window.localStorage.setItem(partitionKey, json);
    //     });
    // }
    const savedView = resolveViewConfig<D>(localStorage, gridKey) ?? { configurations: [] };

    savedView.configurations = savedView.configurations.filter(c => c.name.localeCompare(oldView?.name ?? "") !== 0);
    savedView.configurations = [...savedView.configurations.filter(c => c.name.localeCompare(viewState?.name) !== 0), viewState];

    localStorage.setItem(Constants.persistence.viewPartitionKey, JSON.stringify(savedView));
  }

  function handleViewDelete() {
    onDeleteView();
    // TODO: Work on deleting views
    // users
    //   .filter(u => u.id !== user.id)
    //   .forEach(runningUser => {
    //     views.forEach(view => {
    //       if (view.visibility === t(translationKeys.grid.saveView.all)) {
    //         // User is removing a shared view...
    //         const partitionKey = Constants.storage.partitionKey + "-" + runningUser.id;
    //         const item = window.localStorage.getItem(partitionKey);

    //         const data = item ? ((JSON.parse(item) as unknown) as StoredMap) : [];
    //         const keyMap = new Map<string, string>(data);
    //         const viewConfigStr = keyMap.get(Constants.persistence.viewPartitionKey);
    //         const savedView = (viewConfigStr
    //           ? (JSON.parse(viewConfigStr) as unknown)
    //           : { configurations: [] }) as PersistedView<D>;

    //         savedView.configurations = [...savedView.configurations.filter(c => c.name.localeCompare(view?.name) !== 0)];

    //         keyMap.set(Constants.persistence.viewPartitionKey, JSON.stringify(savedView));

    //         const updatedData: StoredMap = Array.from(keyMap.entries());
    //         const json = JSON.stringify(updatedData);

    //         window.localStorage.setItem(partitionKey, json);
    //       }
    //     });
    //   });
  }

  function handleViewSelect(view: PersistedViewConfig<D>) {
    onViewSelect(view);
    setCogMenuState({
      ...cogMenuState,
      showFilter: (view?.states.filters?.length ?? 0) > 0,
    });
  }
};
