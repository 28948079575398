import { Link } from "react-router-dom";
import styled from "styled-components";

interface NavItemProps {
  notHover?: boolean;
}

export const NavLink = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 58px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover { box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px };
`;

export const NavItem = styled.div<NavItemProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 58px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  ${props => props?.notHover ? "" : ":hover { box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px };  cursor: pointer;"};
`;

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 60px;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  box-shadow: 5px 0px 10px #0000001A;
  padding: 0;
`;

export const NavGroup = styled.div``;
export const Version = styled.div`
  color: grey;
  font-size: small;
`;
