import React from "react";
import { Placement, Popover2 } from "@blueprintjs/popover2";
import { Button, IRef } from "@blueprintjs/core";

export interface PopoverViewProps {
  content: string | JSX.Element;
  placement?: Placement;
}

export interface TProps {
  ref: IRef<HTMLButtonElement> | undefined;
  isOpen: boolean | undefined;
}

export const PopoverUncontrolledView = (props: PopoverViewProps) => {
  return (
    <>
      <Popover2
        content={props.content}
        renderTarget={({ isOpen, ref, ...targetProps }: TProps) => (
          <Button {...targetProps} elementRef={ref} intent="primary" text="Popover target" />
        )}
      />
    </>
  );
};
