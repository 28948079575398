import { StoragePartition } from "@ais/ui";
import { BaseRow, GridConfigurations, PersistedView, PersistedViewConfig } from "../types";

// TODO: the grid specific localStorage saving should be in a separate hook.

/**
 * Returns current view from persisted store if there any, otherwise return default view.
 * @param localStorage user specific local storage
 */
export function resolveCurrentView<D extends BaseRow>(localStorage: StoragePartition, key: string): PersistedViewConfig<D> {
  const gridConfigs: GridConfigurations<D> | null = JSON.parse(localStorage.getItem(key) ?? "null");
  if (gridConfigs?.currentView) {
    return gridConfigs.currentView;
  }
  return getDefaultView<D>();
}

/**
 * The default view configurations to use during initial load and on set to default action.
 */
export function getDefaultView<D extends BaseRow>() {
  return ({
    filter: { filter: true, grouping: true, table: true },
    states: {
      columnOrders: [],
      filters: [],
      groups: [],
      sortBy: [],
    },
  } as unknown) as PersistedViewConfig<D>; // TODO: Visit this later to remove teh unknown key word
}

/**
 * Stores the view into the persisted store
 */
export function storeView<D extends BaseRow>(localStorage: StoragePartition, key: string, view: PersistedViewConfig<D>) {
  let gridConfigs: GridConfigurations<D> | null = JSON.parse(localStorage.getItem(key) ?? "null");

  if (gridConfigs) {
    gridConfigs.currentView = view;
  } else {
    gridConfigs = {
      currentView: view,
    };
  }
  localStorage.setItem(key, JSON.stringify(gridConfigs));
}

export function resolveColumnMovable<D extends BaseRow>(localStorage: StoragePartition, key: string): boolean {
  const gridConfigs: GridConfigurations<D> | null = JSON.parse(localStorage.getItem(key) ?? "null");
  return gridConfigs?.columnMovable ?? true;
}

export function storeColumnMovable<D extends BaseRow>(localStorage: StoragePartition, key: string, columnMovable: boolean) {
  let gridConfigs: GridConfigurations<D> | null = JSON.parse(localStorage.getItem(key) ?? "null");

  if (gridConfigs) {
    gridConfigs.columnMovable = columnMovable;
  } else {
    gridConfigs = {
      columnMovable,
    };
  }
  localStorage.setItem(key, JSON.stringify(gridConfigs));
}

export function resolveViewConfig<D extends BaseRow>(localStorage: StoragePartition, key: string): PersistedView<D> | undefined {
  const gridConfigs: GridConfigurations<D> | null = JSON.parse(localStorage.getItem(key) ?? "null");
  return gridConfigs?.viewConfig;
}

export function storeViewConfig<D extends BaseRow>(localStorage: StoragePartition, key: string, viewConfig?: PersistedView<D>) {
  let gridConfigs: GridConfigurations<D> | null = JSON.parse(localStorage.getItem(key) ?? "null");

  if (gridConfigs) {
    gridConfigs.viewConfig = viewConfig;
  } else {
    gridConfigs = {
      viewConfig,
    };
  }
  localStorage.setItem(key, JSON.stringify(gridConfigs));
}
