export interface AccessTokenIdentity {
  access_token: string;
  state?: string;
  profile?: AccessUserClaims;
}

export declare const claims: {
  identityProvider: "idp";
  subject: "sub";
  tenant: "ais.tenant";
  clientId: "client_id";
  email: "email";
  userZone: "ais.User.Zones";
  name: "name";
};

export declare type ClaimType = typeof claims[keyof typeof claims];

export type AccessUserClaims = {
  [K in ClaimType]?: string | string[];
};

export class AccessToken {
  public identity: AccessTokenIdentity;

  constructor(identity: AccessTokenIdentity) {
    this.identity = identity;
  }

  public get accessToken(): string {
    return this.identity.access_token;
  }

  public get claims(): AccessUserClaims {
    return this.identity.profile ?? {};
  }

  public refresh(identity: AccessTokenIdentity): void {
    this.identity = identity;
  }

  public getClaim(claimType: ClaimType): string | null {
    const values = this.claims[claimType];
    if (typeof values === "string") {
      return values;
    }
    return null;
  }
}
