import React from "react";
import MarkerClusterGroup from "react-leaflet-markercluster";

export interface WebClusterWrapperProps {
  children?: React.ReactNode;
}

export const WebClusterWrapper = (props: WebClusterWrapperProps) => {
  return (
    <MarkerClusterGroup showCoverageOnHover zoomToBoundsOnClick>
      {props.children}
    </MarkerClusterGroup>
  );
};
