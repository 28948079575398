import React from "react";
import classnames from "classnames";
import {
  Intent as BluePrintIntent,
  Classes,
  Icon as CoreIcon,
  IconName as CoreIconName,
  IconSize,
  MaybeElement
} from "@blueprintjs/core";
import { AisIcons } from "../../assets/icons";
import { Svg } from "../svg/svg";

export type IconName = (keyof typeof AisIcons) | CoreIconName;
export const extendedIconNames = Object.keys(AisIcons) as (keyof typeof AisIcons)[];

const nativeIconNames = Object.keys(AisIcons);
export function isNativeIcons(icon: IconName | MaybeElement) {
  return typeof icon === "string" && nativeIconNames.includes(icon);
}

export interface IconProps {
  /** Used In Blueprint */
  children?: never;

  icon: IconName| MaybeElement;
  iconSize?: number;
  /** CSS style properties. */
  style?: React.CSSProperties;
  color?: string;
  className?: string;
  intent?: BluePrintIntent;
  /**
   * String for the `title` attribute on the rendered element, which will appear
   * on hover as a native browser tooltip.
  */
  htmlTitle?: string;
  title?: string | false | null;
}

export class Icon extends React.PureComponent<IconProps & React.DOMAttributes<HTMLElement>> {
  public static SIZE_STANDARD = IconSize.STANDARD;
  public static SIZE_LARGE = IconSize.LARGE;

  public render() {
    const { icon } = this.props;
    if (isNativeIcons(icon)) {
      return this.renderNativeIcon();
    }

    return this.renderBluePrintIcon();
  }

  private renderNativeIcon() {
    const {
      icon,
      intent,
      className,
      iconSize = Icon.SIZE_STANDARD,
      color,
      htmlTitle,
      ...restProps } = this.props;

    const extendedIcon = icon as keyof typeof AisIcons;
    const classes = classnames(Classes.ICON, Classes.intentClass(intent), className);

    const src = iconSize <= Icon.SIZE_STANDARD ? AisIcons[extendedIcon] : AisIcons[extendedIcon];

    return (
      <span className={classes} title={htmlTitle}>
        <Svg
          {...restProps}
          fill={color}
          height={iconSize}
          src={src}
          width={iconSize}
        />
      </span>
    );
  }

  private renderBluePrintIcon() {
    const { icon, intent, title, ...restProps } = this.props;
    const blueprintIcon = icon as CoreIconName | MaybeElement;
    return (
      <CoreIcon {...restProps} icon={blueprintIcon} title={title} />
    );
  }
}
