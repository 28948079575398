import React from "react";
import * as Core from "@blueprintjs/core";

export interface MenuViewItem {
  id?: string;
  icon?: Core.IconName | Core.MaybeElement;
  text?: string;
  shortcut?: string;
  divider?: boolean;
  active?: boolean;
  disabled?: boolean;
  title?: string;
  subMenu?: boolean;
  children?: MenuViewItem[];
  onClick?: undefined | ((event: React.MouseEvent) => void);
  onChosen?: ((text: string) => void);
  path?: string;
}
interface MenuView {
  items?: MenuViewItem[];
}

export const MenuView = ({ items }: MenuView) => {
  const renderItem = (item: MenuViewItem) => {
    return (
      <Core.MenuItem
        key={item.id}
        active={item.active}
        disabled={item.disabled}
        href={item.path}
        icon={item.icon}
        label={item.shortcut}
        text={item.text}
        onClick={(event: React.MouseEvent) => {
          if (item.onClick) {
            item.onClick(event);
          } else if (item.onChosen) {
            item.onChosen(item.id ?? "");
          }
        }}
      >
        {item.children?.map(i => renderItem(i))}
      </Core.MenuItem>
    );
  };

  return (
    <>
      <Core.Menu className={Core.Classes.ELEVATION_2}>
        {items?.map(item => {
          if (item.divider) {
            return <Core.MenuDivider key={item.id} />;
          }
          return renderItem(item);
        })}
      </Core.Menu>
    </>
  );
};
