import React from "react";
import { Popover2 } from "@blueprintjs/popover2";

export interface PopoverControlledViewProps {
  children: React.ReactNode;
  content: string | JSX.Element;
  isOpen?: boolean | undefined;
  onInteraction?: (nextOpenState: boolean, e?: React.SyntheticEvent<HTMLElement>) => void | undefined;
}

export const PopoverControlledView = (props: PopoverControlledViewProps) => {
  const { content, isOpen, children, onInteraction } = props;
  const handleInteraction = (nextOpenState: boolean) => {
    onInteraction?.(nextOpenState);
  };
  return (
    <>
      <Popover2
        content={content}
        isOpen={isOpen}
        onInteraction={handleInteraction}
      >
        {children}
      </Popover2>
    </>
  );
};
