import React, { useMemo, useState } from "react";
import { Rectangle, useMap } from "react-leaflet";

const innerBounds: [number, number][] = [
  [21.5726, -8.09],
  [25.5726, 8.09],
];
const outerBounds: [number, number][] = [
  [22.5726, -40.4796],
  [24.5726, 40.4796],
];

const redColor = { color: "red" };
const whiteColor = { color: "white" };

export const SetBoundsRectangles = () => {
  const [bounds, setBounds] = useState(outerBounds);
  const map = useMap();

  const innerHandlers = useMemo(
    () => ({
      click() {
        setBounds(innerBounds);
        map.fitBounds(innerBounds);
      },
    }),
    [map]
  );
  const outerHandlers = useMemo(
    () => ({
      click() {
        setBounds(outerBounds);
        map.fitBounds(outerBounds);
      },
    }),
    [map]
  );

  return (
    <>
      <Rectangle
        bounds={outerBounds}
        eventHandlers={outerHandlers}
        pathOptions={bounds === outerBounds ? redColor : whiteColor}
      />
      <Rectangle
        bounds={innerBounds}
        eventHandlers={innerHandlers}
        pathOptions={bounds === innerBounds ? redColor : whiteColor}
      />
    </>
  );
};
