import styled from "styled-components";

export const Main = styled.main`
  width: 100%;
  height: 100%;
  padding-top: 0;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const AppSection = styled.section`
  flex-grow: 1;
  padding: 0px 0px 0px 20px;
  background: #757575;
  /* color: white; // TODO Themeing */
`;
