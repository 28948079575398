import React from "react";
import _ from "lodash";
import { CellValue, Row } from "react-table";
import { useTranslator } from "@ais/core";
import { TranslationResource } from "locales";
import { AggregateActions } from "types";
import { AggregatedLabel } from "./aggregate.styles";

export interface AggregationTextProps {
  action: AggregateActions;
  value: CellValue;
}

export const AggregationText = (props: AggregationTextProps) => {
  const { action, value } = props;
  const { t, translationKeys } = useTranslator<TranslationResource>();

  const translatedCalcutionFunction = t(translationKeys.grid.contextMenu[action]);
  return (
    <div>
      <AggregatedLabel>{translatedCalcutionFunction}</AggregatedLabel>
      <div>{value}</div>
    </div>
  );
};

export function getAggregationAction(action: AggregateActions) {
  switch (action) {
    case "sum":
      return "sum";
    case "minimum":
      return getMinimum;
    case "maximum":
      return getMaximum;
    case "mean":
      return "average";
    case "countAll":
      return "count";
    case "countNotEmpty":
      return countNonEmpty;
  }
}

export function getAggregationText(action: AggregateActions, value: CellValue) {
  return <AggregationText action={action} value={value} />;
}

function getMinimum(leafValues: CellValue[]) {
  const min = leafValues.reduce((acc, value) => {
    acc = Math.min(acc, value);
    return acc;
  }, Number.MAX_SAFE_INTEGER);
  return min === Number.MAX_SAFE_INTEGER ? null : min;
}

function getMaximum(leafValues: CellValue[]) {
  const max = leafValues.reduce((acc, value) => {
    acc = Math.max(acc, value);
    return acc;
  }, Number.MIN_SAFE_INTEGER);
  return max === Number.MIN_SAFE_INTEGER ? null : max;
}

function countNonEmpty(leafValues: CellValue[]) {
  return leafValues.reduce((acc, value) => {
    if (value || _.isNumber(value)) {
      acc++;
    }
    return acc;
  }, 0);
}

function getSum(leafValues: CellValue[]) {
  return leafValues.reduce((acc, value) => {
    acc = acc + value;
    return acc;
  }, 0);
}

function getCountAll(leafValues: CellValue[]) {
  return leafValues.length;
}

function getAverage(leafValues: CellValue[]) {
  const mean = getSum(leafValues) / getCountAll(leafValues);
  return Number(mean.toFixed(2));
}

export function aggregateColumn<D extends {}>(columnId: string, rows: Row<D>[], operation: AggregateActions): number {
  const leafValues: CellValue[] = rows.map(r => r.values[columnId]);
  switch (operation) {
    case "sum":
      return getSum(leafValues);
    case "minimum":
      return getMinimum(leafValues);
    case "maximum":
      return getMaximum(leafValues);
    case "mean":
      return getAverage(leafValues);
    case "countAll":
      return getCountAll(leafValues);
    case "countNotEmpty":
      return countNonEmpty(leafValues);
  }
}
