import { BaseRow, EditableColumnWithStrictAccessor } from "types";

export interface DriverData extends BaseRow {
  driverGuid: string;
  name?: string;
  surName?: string;
  personalId?: string;
  driverCardId?: string;
  active: boolean;
}

export type DriverColumnNames = Omit<DriverData, keyof BaseRow>;

export const columnConfig: EditableColumnWithStrictAccessor<DriverData>[] = [
  { accessor: "driverGuid" },
  { accessor: "name" },
  { accessor: "surName" },
  { accessor: "personalId" },
  { accessor: "driverCardId" },
  { accessor: "active" },
];
