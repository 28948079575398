import React, { useState } from "react";
import _ from "lodash";
import { useTranslator } from "@ais/core";
import { NonIdealState, StoredMap, Tooltip } from "@ais/ui";

import { BaseRow, PersistedView, PersistedViewConfig } from "types";
import { TranslationResource } from "locales";
import { Constants } from "../../../utils";

import {
  ActionCell,
  ActionHeader,
  Button,
  CloseIcon,
  Container,
  ContainerBody,
  Dialog,
  HTMLTable,
  Input,
  NameCell,
  RowItem,
  SelectAll
} from "./shareViewDialog.styles";

export interface User {
  username: string;
  password: string;
  id: string;
  firstName: string;
  lastName: string;
  display: string;
}

export interface ShareViewProps<D extends BaseRow> {
  selectedViews: PersistedViewConfig<D>[];
  isOpen: boolean;
  onShare: () => void;
  onClose: () => void;
}

export const ShareView = <D extends BaseRow>(props: ShareViewProps<D>) => {
  const { t, translationKeys } = useTranslator<TranslationResource>();
  const [selectedUsers, setSelectedUsers] = useState<Map<string, User>>(new Map());
  const [searchInput, setSearchInput] = useState("");
  const { isOpen, onClose } = props;

  const handleUserSelect = (user: User) => {
    if (selectedUsers.has(user.id)) {
      selectedUsers.delete(user.id);
    } else {
      selectedUsers.set(user.id, user);
    }
    setSelectedUsers(selectedUsers);
  };

  const handleShareView = () => {
    const { selectedViews, onClose } = props;

    selectedViews.forEach(view => {
      selectedUsers.forEach(user => {
        const partitionKey = Constants.storage.partitionKey + "-" + user.id;
        const item = window.localStorage.getItem(partitionKey);

        const data = item ? ((JSON.parse(item) as unknown) as StoredMap) : [];
        const keyMap = new Map<string, string>(data);
        const viewConfigStr = keyMap.get(Constants.persistence.viewPartitionKey);
        const savedView = (viewConfigStr
          ? (JSON.parse(viewConfigStr) as unknown)
          : { configurations: [] }) as PersistedView<D>;

        savedView.configurations = [
          ...savedView.configurations.filter(c => c.name.localeCompare(view?.name) !== 0),
          { ...view, owner: user.id },
        ];

        keyMap.set(Constants.persistence.viewPartitionKey, JSON.stringify(savedView));

        const updatedData: StoredMap = Array.from(keyMap.entries());
        const json = JSON.stringify(updatedData);

        window.localStorage.setItem(partitionKey, json);
      });
    });

    onClose();
  };

  const renderRow = (user: User) => {
    return (
      <RowItem key={user.id}>
        <td>
          <Tooltip content={user.display}>
            <NameCell>{user.display}</NameCell>
          </Tooltip>
        </td>
        <ActionCell>
          <Button
            minimal
            className={selectedUsers.has(user.id) ? undefined : "select"}
            text={
              selectedUsers.has(user.id) ? t(translationKeys.grid.shareView.remove) : t(translationKeys.grid.shareView.select)
            }
            onClick={() => handleUserSelect(user)}
          />
        </ActionCell>
      </RowItem>
    );
  };

  // TODO: Implement shareview for other users
  // const otherUsers = users.filter(
  //   u => u.id !== user.id && u.display.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
  // );
  const otherUsers = [] as any; // TODO: This is an exception
  const isEmptyRecord = otherUsers.length === 0;

  return (
    <Dialog canEscapeKeyClose canOutsideClickClose isOpen={isOpen} onClose={onClose}>
      <CloseIcon icon="cross" iconSize={20} onClick={onClose} />
      <Input large leftIcon="search" type="search" value={searchInput} onChange={handleInputChange} />
      <Container>
        <ContainerBody>
          <HTMLTable striped>
            <thead>{headerRenderer()}</thead>
            {isEmptyRecord ? emptyRenderer() : <tbody>{otherUsers.map(renderRow)}</tbody>}
          </HTMLTable>
        </ContainerBody>
      </Container>
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <Button text={t(translationKeys.grid.shareView.cancel)} onClick={onClose} />
          <Button
            disabled={_.isEmpty(selectedUsers)}
            text={t(translationKeys.grid.shareView.share)}
            onClick={handleShareView}
          />
        </div>
      </div>
    </Dialog>
  );

  function headerRenderer() {
    return (
      <tr>
        <th>{t(translationKeys.grid.shareView.name)}</th>
        <ActionHeader>
          <SelectAll
            minimal
            // TODO
            // text={
            //   otherUsers.length === selectedUsers.size
            //     ? t(translationKeys.grid.shareView.removeAll)
            //     : t(translationKeys.grid.shareView.selectAll)
            // }
            text={t(translationKeys.grid.shareView.selectAll)}
            onClick={handleAddRemoveAll}
          />
        </ActionHeader>
      </tr>
    );
  }

  function emptyRenderer() {
    return (
      <tbody>
        <tr>
          <td colSpan={2}>
            <NonIdealState description={String(t(translationKeys.grid.shareView.noData))} />
          </td>
        </tr>
      </tbody>
    );
  }

  function handleAddRemoveAll() {
    if (otherUsers.length === selectedUsers.size) {
      selectedUsers.clear();
    } else {
      // TODO: Remove any when the Other user issue is fixed
      otherUsers.forEach((user: any) => selectedUsers.set(user.id, user));
    }
    setSelectedUsers(selectedUsers);
  }

  function handleInputChange(value: string) {
    setSearchInput(value);
  }
};
