import { BaseRow, EditableColumnWithStrictAccessor } from "types";

export interface PositionData extends BaseRow {
  timeStamp: Date;
  state: string;
  startStart: Date;
  startEnd?: Date;
  timespan?: string;
  speed?: number;
  odometer?: number;
  odometerDiff?: number;
  amountSatellites?: number;
  street?: string;
  gasLevel?: number;
  city?: string;
  latitude: number;
  longitude: number;
}

export type PositionColumnNames = Omit<PositionData, keyof BaseRow>;

// TODO: add translation here
export const columnConfig: EditableColumnWithStrictAccessor<PositionData>[] = [
  {
    accessor: "timeStamp",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Cell: ({ value }) => value.toLocaleString("de-DE"),
  },
  { accessor: "state" },
  {
    accessor: "startStart",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Cell: ({ value }) => value.toLocaleString("de-DE"),
  },
  {
    accessor: "startEnd",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Cell: ({ value }) => value?.toLocaleString("de-DE") ?? null,
  },
  { accessor: "timespan" },
  {
    accessor:  "speed",
  },
  { accessor: "odometer" },
  { accessor: "odometerDiff" },
  { accessor: "amountSatellites" },
  { accessor: "street" },
  { accessor: "city" },
  { accessor: "gasLevel" },
  { accessor: "latitude" },
  { accessor: "longitude" },
];
