import React, { useEffect, useState } from "react";
import { CarrierView, Order, OrderAddress } from "@aisalfaplan/troponic";
import { useNavSelection, useTranslator, useTroponic } from "@ais/core";
import { AisIntent, Button, ButtonGroup, CardWidget, CardWidgetProps, Spinner, SpinnerSize } from "@ais/ui";
import { OrderGrid } from "@ais/grid";
import { TranslationResource } from "locales";

import { HappyTreeFriends } from "../../../assets/images/images";
import { PageHeader } from "../../../components/page-header/pageHeader";
import { CardBody, CardIcon, Item, ItemRow, Row, RowContent } from "./orders.styles";
import { PageContainer } from "../common.styles";
import { RoundedImage } from "../../../components/common.styles";

export const Orders = () => {
  const troponic = useTroponic();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showTableView, setShowTableView] = useState(true);
  const [orders, setOrders] = useState<Order[]>([]);
  const [carrier, setCarrier] = useState<CarrierView>();
  const { t, translationKeys } = useTranslator<TranslationResource>();
  const { navigatorSelection } = useNavSelection();

  const fetchListOfOrders = async () => {
    const feedResult = await troponic.Order.query({
      queryOptions: { notInStateId: [30005] },
    });
    setOrders(feedResult.results);
    setIsLoaded(true);
  };

  const getAddress = ({ street, localId, addressname, city }: OrderAddress) => {
    return (
      <>
        <Row>
          <RowContent>{addressname}</RowContent>
        </Row>
        <Row>
          <RowContent>{street}</RowContent>
        </Row>
        <Row>
          <RowContent>{localId}</RowContent>
          <RowContent>{city}</RowContent>
        </Row>
      </>
    );
  };

  const getOrderTypeIcon = (type: any): string => {
    // eslint-disable-next-line no-console
    console.log("TYPE:" + type);
    switch (type) {
      case 10:
        return HappyTreeFriends.Cub;
      case 20:
        return HappyTreeFriends.Flippy;
      case 30:
        return HappyTreeFriends.Petunie;
      default:
        return HappyTreeFriends.TheMole;
    }
  };

  const getOrderId = (order: Order): string => {
    return order.hostId ?? "Id:" + order.resourceId;
  };

  const getDateRow = (order: Order) => {
    const pickup = order.pickupDateFrom ? "Pickup: " + order.pickupDateFrom : "";
    const deliver = order.deliveryDateFrom ? "Deliver: " + order.deliveryDateFrom : "";

    return (
      <Row>
        <label>{pickup}</label>
        <label>{deliver}</label>
      </Row>
    );
  };

  const render = (order: Order) => {
    return (
      <>
        <CardIcon>
          <RoundedImage src={getOrderTypeIcon(order.type)} />
        </CardIcon>
        <CardBody>
          <Item className="mini">
            <ItemRow className="header">
              {getOrderId(order)}
            </ItemRow>
          </Item>
          <Item className="minor">
            <ItemRow className="header">
              {t(translationKeys.aisweb.jobsComponent.sender)}
            </ItemRow>
            <Item>
              {getAddress(order.sender)}
            </Item>
          </Item>
          <Item className="minor">
            <ItemRow className="header">
              {t(translationKeys.aisweb.jobsComponent.recipient)}:
            </ItemRow>
            <Item>
              {getAddress(order.recipient)}
            </Item>
          </Item>
          <Item className="minor">
            <ItemRow className="header">
              {t(translationKeys.aisweb.jobsComponent.goods)}:
            </ItemRow>
            <Item>
              {order.type}
            </Item>
          </Item>
        </CardBody>
        {getDateRow(order)}

      </>
    );
  };

  const getKey = (item: Order) => item.resourceId;
  const isActive = () => false;

  const currentMobile = carrier?.devices[0]?.deviceResourceId ?? 0;
  const filteredOrders = currentMobile > 0 ? orders.filter(o => o.mobileDeviceId === currentMobile) : orders;

  const onFilter = () => {};

  const renderTableView = () => {
    setShowTableView(true);
  };

  const renderGridView = () => {
    setShowTableView(false);
  };

  const widgetProps: CardWidgetProps<Order> = {
    fullWidth: true,
    items: filteredOrders,
    render,
    textFilter: {
      onFilter,
      placeHolder: t(translationKeys.aisweb.jobsGreyComponent),
    },
    getKey,
    isActive,
  };

  useEffect(() => {
    fetchListOfOrders();
  }, []);

  useEffect(() => {
    setCarrier(navigatorSelection?.carrier ?? undefined);
  }, [navigatorSelection?.carrier]);

  const toggleView = () => {
    return showTableView ? <CardWidget {...widgetProps} /> : <OrderGrid data={filteredOrders} />;
  };
  const headline = `${t(translationKeys.aisweb.orderPage)}: ${orders?.length ?? "0"}`;
  return (
    <>
      <PageHeader headLine={headline}>
        <ButtonGroup>
          <Button icon="tick" onClick={renderTableView} />
          <Button icon="aisGrid" onClick={renderGridView} />
        </ButtonGroup>
      </PageHeader>
      <PageContainer>
        {!isLoaded
          ? (
            <Spinner
              colour={AisIntent.Primary}
              size={SpinnerSize.Small}
            />
          )
          : toggleView()}
      </PageContainer>
    </>
  );
};
