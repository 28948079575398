import React from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import _, { isEmpty } from "lodash";
import { Icon, IconName } from "../icon";
import { ClearButton, CustomInput } from "../inputgroup/inputGroup.styles";

export type HTMLInputProps = Omit<Core.HTMLInputProps, "defaultValue" | "value" | "onChange">;

export interface InputGroupProps extends Omit<Core.IInputGroupProps, "leftIcon" | "onChange"> {
  /**
   * If true, defaults the `rightElement` to a clear button that will clear the selection.
   * Ignored if `rightElement` is provided.  Defaults to `true` if type="search".
   * @default false
   */
  clearable?: boolean;

  disabled?: boolean;

  /**
   * Collapses the input and expands on focus.
   * @default false
   */
  expandable?: boolean;

  leftIcon?: IconName | Core.MaybeElement;

  /**
   * Gives the input a minimal style.
   * @default false
   */
  minimal?: boolean;

  name?: string;

  readonly?: boolean;

  onChange?: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface InputGroupState {
  value: string;
}

export class InputGroup extends React.PureComponent<InputGroupProps & HTMLInputProps, InputGroupState> {
  private readonly inputRef = React.createRef<HTMLInputElement>();

  constructor(props: InputGroupProps & HTMLInputProps) {
    super(props);
    this.state = {
      value: props.defaultValue ?? "",
    };
  }

  public static toNativeProps(props: InputGroupProps): Core.IInputGroupProps & HTMLInputProps {
    const { minimal, className, leftIcon, onChange, ...restProps } = props;

    const nativeLeftIcon: Core.MaybeElement | undefined = leftIcon ? <Icon icon={leftIcon} /> : undefined;

    return {
      ...restProps,
      leftIcon: nativeLeftIcon,
      className: classnames(minimal ? Core.Classes.MINIMAL : null, className),
    };
  }

  public static getDerivedStateFromProps(props: InputGroupProps, state: InputGroupState): InputGroupState {
    const { value: controlledValue } = props;
    const { value: stateValue } = state;
    const value = controlledValue !== undefined ? controlledValue : stateValue;

    return {
      value,
    };
  }

  public render() {
    const { clearable, expandable, ...restProps } = this.props;
    const { value } = this.state;
    const {
      onChange,
      name,
      intent,
      readOnly,
      disabled,
      defaultValue,
      value: controlledValue,
      rightElement: controlledRightElement,
      round,
      type,
      className,
      ...inputProps
    } = InputGroup.toNativeProps(restProps);

    let rightElement = controlledRightElement;
    const hasClearButton = !rightElement && (type === "search" ? clearable !== false : clearable === true);

    if (hasClearButton) {
      rightElement = (
        <ClearButton minimal $hidden={isEmpty(value)} disabled={false} icon="cross" onClick={this.handleClear} />
      );
    }

    return (
      <CustomInput
        {...inputProps}
        className={classnames(className, { expandable })}
        disabled={disabled}
        inputRef={this.inputRef}
        intent={intent}
        name={name}
        readOnly={readOnly}
        rightElement={rightElement}
        round={type === "search" ? round !== false : round === true}
        type={type === "search" ? "text" : type}
        value={value}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
      />
    );
  }

  private change = (value: string, event?: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;

    this.setState({ value });

    onChange?.(value, event);
  };

  private handleClear = (): void => {
    this.change("");
  };

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.change(event.currentTarget.value, event);
  };

  private handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;
    onBlur?.(event);
  };
}
