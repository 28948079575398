import React from "react";
import { useTranslation } from "@ais/core";
import { Button } from "@ais/ui";

export const LanguageSwitch = () => {
  const { changeLanguage } = useTranslation();
  const handleLanguageChange = (language: string) => {
    changeLanguage(language);
  };
  return (
    <div style={{ minWidth: 0 }}>
      <Button text="de" onClick={() => handleLanguageChange("de")} />
      <Button text="en" onClick={() => handleLanguageChange("en")} />
    </div>
  );
};
