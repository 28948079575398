import React from "react";
import { Driver } from "@aisalfaplan/troponic";
import { useTranslator } from "@ais/core";
import { EditableColumnWithStrictAccessor } from "types";
import { TranslationResource } from "../locales";
import { Grid } from "../components";
import { DriverColumnNames, DriverData, columnConfig } from "./columnConfig";

export interface DriverGridProps {
  data: Driver[];
}

export const DriverGrid = (props: DriverGridProps) => {
  const { data } = props;
  const { t, translationKeys } = useTranslator<TranslationResource>();

  const driverData: DriverData[] = data.map(p => ({
    rowId: Number(p.resourceId),
    lastUpdateDate: Date.now(),
    driverGuid: p.driverGuid,
    name: p.name,
    surName: p.surName,
    personalId: p.personalId,
    driverCardId: p.driverCardId,
    active: p.active,
  }));

  return <Grid<DriverData> columnConfig={getColumnConfig()} gridKey="driverGrid" initialData={driverData} />;

  function getColumnConfig(): EditableColumnWithStrictAccessor<DriverData>[] {
    return columnConfig.map(c => ({
      ...c,
      Header: t(translationKeys.driverGrid.columns[c.accessor as keyof DriverColumnNames]),
    }));
  }
};
