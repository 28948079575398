import React from "react";
import { HeadFilter, HeaderViewStyle } from "../common.styles";

export interface RightHeaderProps {
  children?: React.ReactNode;
}

export const RightHeader = (props: RightHeaderProps) => (
  <HeaderViewStyle>
    <HeadFilter>{props.children}</HeadFilter>
  </HeaderViewStyle>
);
