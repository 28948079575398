import React, { useEffect, useState } from "react";
import { Carrier, CarrierQueryOptions, Driver } from "@aisalfaplan/troponic";
import { AisIntent, Spinner, SpinnerSize } from "@ais/ui";
import { useTranslator, useTroponic } from "@ais/core";
import { CarrierGrid, DriverGrid } from "@ais/grid";
import { TranslationResource } from "locales";
import { PageHeader } from "../../components/page-header/pageHeader";
import { Content, GridWrapper, Wrapper } from "./jobsGrey.style";
import { PageContainer } from "./common.styles";

export const JobGSideLogo = (): React.ReactElement => {
  const troponic = useTroponic();
  const { t, translationKeys } = useTranslator<TranslationResource>();

  const [isCarrierLoading, setIsCarrierLoading] = useState(false);
  const [isDriverLoading, setIsDriverLoading] = useState(false);
  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);

  const fetchListOfCarriers = async () => {
    setIsCarrierLoading(true);

    const queryOptions: CarrierQueryOptions = {
      isActive: true,
    };

    const response = await troponic.Carrier.query({ queryOptions });
    setIsCarrierLoading(false);
    setCarriers(response.results);
  };

  const fetchListofDrivers = async () => {
    setIsDriverLoading(true);

    const response = await troponic.Driver.query({
      queryOptions: { active: true },
    });
    setIsDriverLoading(false);
    setDrivers(response.results);
  };

  const loadData = async () => {
    await fetchListOfCarriers();
    await fetchListofDrivers();
  };

  const renderLoader = () => {
    return (
      <Spinner
        colour={AisIntent.Primary}
        size={SpinnerSize.Small}
      />
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  const headline = `${t(translationKeys.aisweb.baseData)}`;
  const carriersHeader = `${t(translationKeys.carrierGrid.title)}`;
  const driversHeader = `${t(translationKeys.driverGrid.title)}`;
  return (
    <>
      <PageHeader headLine={headline} />
      <Wrapper>
        <PageContainer>
          <Content>
            <h2>{carriersHeader}</h2>
            {isCarrierLoading
              ? renderLoader()
              : (
                <GridWrapper>
                  <CarrierGrid data={carriers} />
                </GridWrapper>
              )}
          </Content>
          <Content>
            <h2>{driversHeader}</h2>
            {isDriverLoading
              ? renderLoader()
              : (
                <GridWrapper>
                  <DriverGrid data={drivers} />
                </GridWrapper>
              )}
          </Content>
        </PageContainer>
      </Wrapper>
    </>
  );
};
