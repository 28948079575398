import React, { useState } from "react";
import { isEmpty } from "lodash";
import { useTranslator, useUserProfile } from "@ais/core";
import { IItemRendererProps, MenuItem, Select } from "@ais/ui";

import { TranslationResource } from "locales";
import { ViewConfigurations, VisibilityOption } from "types";
import {
  Actions,
  CloseIcon,
  Container,
  Dialog,
  Field,
  FieldLabel,
  Icon,
  Input,
  SelectButton,
  Switch
} from "./saveViewDialog.styles";

export interface SaveViewModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (view: ViewConfigurations, oldView?: ViewConfigurations) => void;
  defaultViewConfig?: ViewConfigurations;
}

function propsAreEqual(prevProps: SaveViewModalProps, nextProps: SaveViewModalProps) {
  return prevProps.isOpen !== nextProps.isOpen;
}

export const SaveViewModal = (props: SaveViewModalProps) => {
  const { isOpen, onSave, defaultViewConfig, onClose } = props;

  const user = useUserProfile();
  const { t, translationKeys } = useTranslator<TranslationResource>();
  // TODO: This logic needs a re-write
  // const visibilityOption: VisibilityOption[] = [
  //   t(translationKeys.grid.saveView.all),
  //   t(translationKeys.grid.saveView.onlyMe),
  // ];

  // const [selectedVisibility, setSelectedVisibility] = useState<VisibilityOption | undefined>(
  //   () => defaultViewConfig?.visibility ?? t(translationKeys.grid.saveView.onlyMe)
  // );
  const visibilityOption: VisibilityOption[] = [];
  const [selectedVisibility, setSelectedVisibility] = useState<VisibilityOption | undefined>(undefined);

  const [isFilterSettingActive, setIsFilterSettingActive] = useState(
    () => defaultViewConfig?.filter.filter ?? true
  );
  const [isTableStructureActive, setIsTableStructureActive] = useState(
    () => defaultViewConfig?.filter.table ?? true
  );
  const [isGroupingActive, setIsGroupingActive] = useState(() => defaultViewConfig?.filter.grouping ?? true);
  const [isFavouriteActive, setIsFavouriteActive] = useState(() => defaultViewConfig?.filter.favourite ?? false);
  const [viewName, setViewName] = useState<string>(() => defaultViewConfig?.name ?? "");

  return (
    <Dialog canOutsideClickClose={false} isOpen={isOpen}>
      <CloseIcon icon="cross" iconSize={20} onClick={onClose} />

      <Container>
        <Field>
          <FieldLabel>{t(translationKeys.grid.saveView.name)}</FieldLabel>
          <Input autoFocus clearable maxLength={80} value={viewName} onChange={onInputChange} />
        </Field>
        <Field>
          <FieldLabel>{t(translationKeys.grid.saveView.visibility)}</FieldLabel>
          <Select
            activeItem={selectedVisibility}
            className="select"
            filterable={false}
            itemRenderer={itemRenderer}
            items={visibilityOption}
            onItemSelect={handleSelectionChange}
          >
            <SelectButton name="visibility" rightIcon="caret-down" text={selectedVisibility} />
          </Select>
        </Field>
        <div>
          <Switch
            checked={isFilterSettingActive}
            label={t(translationKeys.grid.saveView.filterSettings)}
            onChange={handleFilterSettingSwitch}
          />
          <Switch
            checked={isTableStructureActive}
            label={t(translationKeys.grid.saveView.tableStructure)}
            onChange={handleTableStructureSwitch}
          />
          <Switch
            checked={isGroupingActive}
            label={t(translationKeys.grid.saveView.grouping)}
            onChange={handleGroupSwitch}
          />
          <Switch
            checked={isFavouriteActive}
            label={t(translationKeys.grid.saveView.favorite)}
            onChange={handleFovoutiteSwitch}
          />
        </div>
        <Actions>
          <Icon
            className={isEmpty(viewName) ? "disabled" : undefined}
            icon="floppy-disk"
            iconSize={24}
            onClick={!isEmpty(viewName) ? handleSaveView : () => null}
          />
        </Actions>
      </Container>
    </Dialog>
  );

  function handleSaveView() {
    const view: ViewConfigurations = {
      name: viewName,
      owner: user.id,
      visibility: selectedVisibility ?? "Only for me",
      filter: {
        filter: isFilterSettingActive,
        favourite: isFavouriteActive,
        grouping: isGroupingActive,
        table: isTableStructureActive,
      },
    };

    onSave(view, defaultViewConfig);
  }

  function handleSelectionChange(selected: VisibilityOption) {
    return setSelectedVisibility(selected);
  }

  function onInputChange(value: string) {
    setViewName(value);
  }

  function itemRenderer(item: VisibilityOption, itemProps: IItemRendererProps) {
    return <MenuItem key={item} active={itemProps.modifiers.active} text={item} onClick={itemProps.handleClick} />;
  }

  function handleFilterSettingSwitch(checked: boolean) {
    setIsFilterSettingActive(checked);
  }

  function handleTableStructureSwitch(checked: boolean) {
    setIsTableStructureActive(checked);
  }

  function handleGroupSwitch(checked: boolean) {
    setIsGroupingActive(checked);
  }
  function handleFovoutiteSwitch(checked: boolean) {
    setIsFavouriteActive(checked);
  }
};

export const SaveViewDialog = React.memo(SaveViewModal, propsAreEqual) as typeof SaveViewModal;
