import React, { useContext, useEffect, useState } from "react";
import { CarrierView, CarrierViewCarrier } from "@aisalfaplan/troponic";
import { AccessTokenContext, useNavSelection, useTranslator, useTroponic } from "@ais/core";
import { CardWidget, CardWidgetProps, DateInput } from "@ais/ui";

import { TranslationResource } from "locales";
import { CarrierItem } from "../../components/carrier/carrierItem";
import { RightContainer, WidgetHeader } from "./carrierWidget.styles";

export const CarrierWidget = () => {
  const troponic = useTroponic();
  const { t, translationKeys } = useTranslator<TranslationResource>();
  const { navigatorSelection, onNavigatorSelection } = useNavSelection();
  const tokenContext = useContext(AccessTokenContext);
  const [activeCarrier, setActiveCarrierId] = useState<CarrierView | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [carriers, setCarriers] = useState<CarrierView[]>([]);
  const [filteredCarriers, setFilteredCarriers] = useState<CarrierView[]>([]);

  const randomBuilder = {
    int: () => Math.abs(Math.random() * 100 * Math.random()),
    guid: () => Math.abs(Math.random() * 100 * Math.random()).toString(),
  };

  const buildCarrierView = (): CarrierView => {
    return {
      guid: randomBuilder.guid(),
      resourceId: randomBuilder.int().toString(),
      tenantId: "-1",
      devices: [
        {
          onlineSince: "XX.XX.XXXX XX:XX:XX",
          deviceGuid: "XXXX",
          deviceName: "XXXX",
          isOnline: false,
        },
      ],
      carrier: {
        maxLoadWeight: 100000,
        licencePlate: "XX-XX-XXX",
        mobileCarrierName: "XXXX.XXXX",
        typeId: 1,
      },
      driver: {
        driverResourceId: -1,
        upcomingDrivingRest: "",
        driverCardId: "XXXXXXXXXXXXXXX",
        driverName: "XXXXX XXXXX",
        driverPersonnelNumber: "XXXXXX",
        driverPhoneNumber: "XXX-XXXXX",
      },
    };
  };

  const arr = Array.from({ length: 5 }).fill(0);
  const carrierSkeleton: CarrierView[] = arr.map(() => {
    const b = buildCarrierView();
    return b;
  });
  const onCarrierSelect = (carrier: CarrierView) => {
    const current = activeCarrier?.resourceId === carrier.resourceId ? null : carrier;
    setActiveCarrierId(current);

    // Sets the Selected Carrier to the Global Context
    navigatorSelection!.carrier = current;
    onNavigatorSelection(navigatorSelection!);
  };

  const getAllCarriers = async () => {
    const feedResult = await troponic.CarrierView.fetch({});
    setCarriers(feedResult.results);
    setFilteredCarriers(feedResult.results);
    setIsLoaded(true);
  };

  useEffect(() => {
    onNavigatorSelection({ carrier: null, filter: null });
    getAllCarriers();
  }, [tokenContext]);

  const onChangeHandler = (selectedDate: Date) => {
    navigatorSelection!.filter = { daterange: selectedDate, vehicleGroup: null };
    onNavigatorSelection(navigatorSelection!);
  };

  const getKey = (item: CarrierView) => item.resourceId;
  const render = (item: CarrierView, skeletonClass?: string) => {
    return (
      <CarrierItem
        key={item.resourceId}
        active={activeCarrier?.resourceId === item.resourceId}
        carrierView={item}
        skeleton={skeletonClass}
      />
    );
  };

  const checkInclude = (needle: string, haystack: string[]) => {
    return haystack.findIndex((hay: string) => hay?.includes(needle)) > -1;
  };

  const onFilter = (filter: string) => {
    const check = (carrier: CarrierViewCarrier) => {
      const f = filter.toLowerCase();
      return carrier === activeCarrier?.carrier || checkInclude(f, [carrier?.licencePlate?.toLocaleLowerCase() ?? "", carrier?.licencePlate?.toLocaleLowerCase() ?? ""]);
    };
    setFilteredCarriers(filter.length > 0 ? carriers.filter(item => check(item.carrier!)) : carriers);
  };
  const onClick = (item: CarrierView) => {
    onCarrierSelect(item);
  };
  const isActive = (item: CarrierView) => item?.resourceId === activeCarrier?.resourceId;
  const skeletonprops: CardWidgetProps<CarrierView> = {
    fullWidth: true,
    items: carrierSkeleton,
    render,
    textFilter: {
      disabled: true,
      onFilter,
      placeHolder: t(translationKeys.common.search),
    },
    getKey,
    onClick,
    isActive,
    isSkeleton: true,
  };
  const cardprops: CardWidgetProps<CarrierView> = {
    fullWidth: true,
    items: filteredCarriers,
    render,
    textFilter: {
      onFilter,
      placeHolder: t(translationKeys.common.search),
    },
    getKey,
    onClick,
    isActive,
  };
  return (
    <RightContainer>
      <WidgetHeader>
        <DateInput onChange={onChangeHandler} />
      </WidgetHeader>

      {!isLoaded
        ? (
          <CardWidget {...skeletonprops} />
        )
        : <CardWidget {...cardprops} />}
    </RightContainer>
  );
};
