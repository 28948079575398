import styled from "styled-components";
import { Icon } from "@ais/ui";

export const Card = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 2px solid ${props => props.theme.border};
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 250px;
  margin: 10px;
  height: 50px;
  padding: 12px;
  &:hover {
    background-color: ${props => props.theme.hover};
  }
  &.disabled {
    background-color: ${props => props.theme.neutrals.lightSilver};
    color: ${props => props.theme.neutrals.lightGray};
    cursor: not-allowed;
  }
`;

export const ActionName = styled.div`
  margin-left: 5px;
  font-weight: bold;
  user-select: none;
`;

export const ActionLeftIcon = styled(Icon)`
  color: ${props => props.theme.icon.action};
  &.disabled {
    color: ${props => props.theme.neutrals.lightGray};
  }
`;

export const ActionRightIcon = styled(Icon)`
  border-radius: 100%;
  border: 2px solid ${props => props.theme.border};
  padding: 4px;
  color: ${props => props.theme.icon.muted};
`;

export const ActionLeftView = styled.div`
  display: flex;
`;
