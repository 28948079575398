import React, { useEffect, useState } from "react";
import { OrderQueryOptions } from "@aisalfaplan/troponic";
import { useTranslator, useTroponic } from "@ais/core";
import { AisIntent, CardWidget, CardWidgetProps, IconName, Spinner, SpinnerSize } from "@ais/ui";
import { TranslationResource } from "locales";
import { PageHeader } from "../../components/page-header/pageHeader";
import { CardContainer, PageContainer, WidgetCard } from "./common.styles";

// WA: "TotalValue" is hard-coded for now, but in the future will be replaced by total number of counts that we get from backend.
interface DashboarItem {
  id: number;
  title: string;
  description: string;
  icon: IconName;
}

export const ChartPage = (): React.ReactElement => {
  const troponic = useTroponic();
  const [isLoaded, setIsLoaded] = useState(false);

  const [dashboardItems, setDashboardItems] = useState<DashboarItem[]>([]);
  const { t, translationKeys } = useTranslator<TranslationResource>();

  const fetchListOfOrders = async () => {
    const queryOptions: OrderQueryOptions = {
      stateId: [],
      notInStateId: [30005],
    };

    const ordersResult = await troponic.Order.query({ queryOptions });

    const orders = ordersResult.results;
    const diOrderDispatched: DashboarItem = {
      title: "Orders",
      description: `All: ${orders.length} Dispatched: ${orders.filter(o => o.mobileDeviceId ?? 0 > 0).length}`,
      icon: "aisJob",
      id: 1,
    };
    const diOrderTypes: DashboarItem = {
      title: "Types",
      description: `Pickup: ${orders.filter(o => Number(o.type) === 10).length} Delivery: ${orders.filter(o => Number(o.type) === 20).length} Transport: ${orders.filter(o => Number(o.type) === 30).length}`,
      icon: "aisJob",
      id: 2,
    };
    setDashboardItems([diOrderDispatched, diOrderTypes]);
    setIsLoaded(true);
  };

  useEffect(() => {
    fetchListOfOrders();
  }, []);

  // Const cardW1: DashboarItem[] = [{
  //   id: 1,
  //   title: "AIS Alfaplan GmbH",
  //   description: "way ahead",
  //   icon: "airplane",
  // }];
  const render = (item: DashboarItem) => {
    return (<>{item.title}<br />{item.description}</>);
  };

  const getKey = (item: DashboarItem) => item.id;
  const isActive = () => false;

  const cardprops: CardWidgetProps<DashboarItem> = {
    columnContainer: true,
    fullWidth: false,
    isSkeleton: false,
    items: dashboardItems,
    render,
    getKey,
    isActive,
  };

  return (
    <>
      <PageHeader headLine={t(translationKeys.aisweb.chartComponent.title)} />
      <PageContainer>
        <WidgetCard>{t(translationKeys.aisweb.chartComponent.mainView)}</WidgetCard>
        <CardContainer>
          {!isLoaded
            ? (
              <Spinner
                colour={AisIntent.Primary}
                size={SpinnerSize.Small}
              />
            )
            : <CardWidget {...cardprops} />}

        </CardContainer>
      </PageContainer>
    </>
  );
};
