export class Debouncer {
  private readonly timeMs: number;
  private current: ReturnType<typeof setTimeout> | null = null;

  constructor(timeMs: number) {
    this.timeMs = timeMs;
  }

  public delay = (callback: () => any): void => {
    this.clear();
    this.current = setTimeout(callback, this.timeMs);
  };

  public clear = (): void => {
    if (this.current) {
      clearTimeout(this.current);
      this.current = null;
    }
  };
}
