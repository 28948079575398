import { BaseRow, EditableColumnWithStrictAccessor } from "types";

export interface CarrierData extends BaseRow {
  name: string;
  license?: string;
  licenseExpiryDate?: Date;
}

export type CarrierColumnNames = Omit<CarrierData, keyof BaseRow>;

export const columnConfig: EditableColumnWithStrictAccessor<CarrierData>[] = [
  { accessor: "name" },
  { accessor: "license" },
  {
    accessor: "licenseExpiryDate",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Cell: ({ value }) => value?.toLocaleString("de-DE"),
  },
];
