import { de as gridDe } from "@ais/grid";
import { TranslationResource } from "./types";

export const de: TranslationResource = {
  ...gridDe,
  common: {
    online: "Online",
    offline: "Offline",
    unbound: " - ",
    driver: "Fahrer",
    coDriver: "Beifahrer",
    search: "Suche",
  },
  user: {
    language: "Sprache",
    changePassword: "Passwort ändern",
    logout: "Abmelden",
  },
  aisweb: {
    dashboard: "Armaturenbrett",
    aisComponent: "Ais - Aussicht",
    chatComponent: {
      total: "Gesamt Werte",
      show: "ANZEIGEN",
    },
    chartComponent: {
      title: "Diagramm - Aussicht",
      mainView: "Hauptansicht",
    },
    baseData: "Stammdaten",
    chatPage: "Chat",
    orderPage: "Aufträge",
    mapComponent: "Karte - Aussicht",
    headerMapMessagesCount: "Gesamt Mitteilungen ({{amount, number}})",
    moduleComponent: "Modul - Aussicht",
    securityComponent: "Sicherheit - Aussicht",
    jobsGreyComponent: "Arbeitsplätze - Grau - Aussicht",
    jobsComponent: {
      dateOfDelivery: "Zustelldatum",
      sender: "Absender",
      recipient: "Empfänger",
      goods: "Guter",
    },
  },
  i18nFeature: {
    selectLanguage: "Sprache auswählen",
    description: "Das Internet ist global. Mehrsprachige- und Pluralisierungsunterstützung ist entscheidend für große Web-Anwendungen. Sie können die Sprache unten verändern, ohne die Seite aktualisieren zu müssen.",
  },
  loginPage: {
    welcomeMessage: "Willkommen bei AIS alfaplan!",
    invalidLoginMessage: "Ungültiger Benutzername oder Passwort.",
  },
  templates: {
    helloMessage: "Guten Tag {{name, string}}! Na wie schlimm ist es?",
  },
  modal: {
    okButton: "Ok",
    cancelButton: "Abbrechen",
  },
};
