import * as Flags from "../flags";
import AisArrowDownWhite from "./ais_arrow_down_white.svg";
import AisArrowDown from "./ais_arrow_down.svg";
import AisArrowRight from "./ais_arrow_right.svg";
import AisBell from "./ais_bell.svg";
import AisBlueDot from "./ais_blue_dot.svg";
import AisChartBars from "./ais_chart_bars.svg";
import AisChartLines from "./ais_chart_line.svg";
import AisChartPie from "./ais_chart_pie.svg";
import AisChat from "./ais_chat.svg";
import AisClose from "./ais_close.svg";
import AisDashboardGrey from "./ais_dashboard_grey.svg";
import AisDashboard from "./ais_dashboard.svg";
import AisFilterDate from "./ais_filter_date.svg";
import AisFilterOnline from "./ais_filter_online.svg";
import AisFilterVehicle from "./ais_filter_vehicle.svg";
import AisFilter from "./ais_filter.svg";
import AisGrid from "./ais_grid.svg";
import AisJobClipboard from "./ais_job_clipboard.svg";
import AisJobSuccess from "./ais_job_success.svg";
import AisJob from "./ais_job.svg";
import AisLogo from "./ais_logo.svg";
import AisMagnifier from "./ais_magnifier.svg";
import AisMapCarrierFlat from "./ais_map_carrier_flat.svg";
import AisMapCarrierHeavy from "./ais_map_carrier_heavy.svg";
import AisMapCarrierTruckSmall from "./ais_map_carrier_truck_small.svg";
import AisMapCarrierTruck from "./ais_map_carrier_truck.svg";
import AisMapCarrierVan from "./ais_map_carrier_van.svg";
import AisMapMarker from "./ais_map_marker.svg";
import AisMasterData from "./ais_masterdata.svg";
import AisMessageReceived from "./ais_message_received.svg";
import AisMessageSent from "./ais_message_sent.svg";
import AisModules from "./ais_modules.svg";
import AisMore from "./ais_more.svg";
import AisProducts from "./ais_products.svg";
import AisReset from "./ais_reset.svg";
import AisSecurity from "./ais_security.svg";
import AisSettings from "./ais_settings.svg";
import AisSpaceman from "./ais_spaceman.svg";
import AisTrendDown from "./ais_trend_down.svg";
import AisTrendUp from "./ais_trend_up.svg";
import AisTruck from "./ais_truck.svg";
import AisViewSingle from "./ais_view_single.svg";
import AisGermanFlag from "./ais_german_flag.svg";

export const AisIcons = {

  aisArrowDownWhite: AisArrowDownWhite,
  aisArrowDown: AisArrowDown,
  aisArrowRight: AisArrowRight,
  aisBell: AisBell,
  aisBlueDot: AisBlueDot,
  aisChartBars: AisChartBars,
  aisChartLines: AisChartLines,
  aisChartPie: AisChartPie,
  aisChat: AisChat,
  aisClose: AisClose,
  aisDashboardGrey: AisDashboardGrey,
  aisDashboard: AisDashboard,
  aisFilterDate: AisFilterDate,
  aisFilterOnline: AisFilterOnline,
  aisFilterVehicle: AisFilterVehicle,
  aisFilter: AisFilter,
  aisGermanFlag: AisGermanFlag,
  aisGrid: AisGrid,
  aisJobClipboard: AisJobClipboard,
  aisJobSuccess: AisJobSuccess,
  aisJob: AisJob,
  aisLogo: AisLogo,
  aisMagnifier: AisMagnifier,
  aisMapCarrierFlat: AisMapCarrierFlat,
  aisMapCarrierHeavy: AisMapCarrierHeavy,
  aisMapCarrierTruckSmall: AisMapCarrierTruckSmall,
  aisMapCarrierTruck: AisMapCarrierTruck,
  aisMapCarrierVan: AisMapCarrierVan,
  aisMapMarker: AisMapMarker,
  aisMasterData: AisMasterData,
  aisMessageReceived: AisMessageReceived,
  aisMessageSent: AisMessageSent,
  aisModules: AisModules,
  aisMore: AisMore,
  aisProducts: AisProducts,
  aisReset: AisReset,
  aisSecurity: AisSecurity,
  aisSettings: AisSettings,
  aisSpaceman: AisSpaceman,
  aisTrendDown: AisTrendDown,
  aisTrendUp: AisTrendUp,
  aisTruck: AisTruck,
  aisViewSingle: AisViewSingle,
  de: Flags.AisFlags.de,
  gb: Flags.AisFlags.gb,
  in: Flags.AisFlags.in,
};
