import React, { SyntheticEvent } from "react";
import { Button, Classes, Dialog, IconName, MaybeElement } from "@blueprintjs/core";

export type ClosingTrigger = "cancelButton" | "escapeKey" | "outside";

export interface DialogViewProps {
  onClose: (reason: DialogCloseReason) => void;
  onSubmit: () => void;
  closeButtonText?: string;
  okButtonText?: string;
  title: React.ReactNode;
  isOpen: boolean;
  okIcon?: IconName | MaybeElement;
  closeIcon?: IconName | MaybeElement;
}

export enum DialogCloseReason {
  Outside,
  EscapeKey,
  Cancel,
  Ok,
  Unkown
}

export const DialogView = (props: React.PropsWithChildren<DialogViewProps>) => {
  const { onClose, closeButtonText, title, isOpen, okButtonText, children, okIcon, closeIcon, onSubmit } = props;

  const handleClose = (event: SyntheticEvent<HTMLElement>) => {
    switch (event.type) {
      case "mousedown":
        onClose(DialogCloseReason.Outside);
        break;
      case "keydown":
        onClose(DialogCloseReason.EscapeKey);
        break;
      case "click":
        onClose(DialogCloseReason.Cancel);
        break;
      default:
        onClose(DialogCloseReason.Unkown);
        break;
    }
  };

  return (
    <Dialog
      canEscapeKeyClose
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
    >
      <div className={Classes.DIALOG_BODY}>
        {children}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon={!okButtonText ? okIcon ?? "tick" : null} onClick={onSubmit}>{okButtonText}</Button>
          <Button icon={!closeButtonText ? closeIcon ?? "cross" : null} onClick={handleClose}>{closeButtonText}</Button>
        </div>
      </div>
    </Dialog>
  );
};
