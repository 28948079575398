import styled from "styled-components";
import { Dialog as BPDialog, Icon as BPIcon, Switch as BPSwitch, Button, InputGroup } from "@ais/ui";

export const Dialog = styled(BPDialog)`
  position: relative;
  border-radius: 20px;
  padding: 36px 40px 0px;
  background: ${props => props.theme.background};
  .in-active {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const CloseIcon = styled(BPIcon)`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  background: ${props => props.theme.primary};
  color: ${props => props.theme.icon.default};
  border-radius: 100%;
  padding: 4px;
`;

export const Input = styled(InputGroup)`
  width: 280px;
  margin-bottom: 20px;
`;

export const FieldLabel = styled.div`
  color: ${props => props.theme.textSecondary};
  padding-left: 5px;
`;

export const Container = styled.div`
  width: 100%;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  width: 60%;
  justify-content: center;
  margin: 15px auto;
`;

export const Icon = styled(BPIcon)`
  color: ${props => props.theme.primary};
  cursor: pointer;

  &.disabled {
    background-color: ${props => props.theme.neutrals.lightSilver};
    color: ${props => props.theme.neutrals.lightGray};
    cursor: not-allowed;
  }
`;

export const SelectButton = styled(Button)`
  width: 280px;

  & .bp3-icon {
    margin-left: auto !important;
  }
  &:focus {
    outline: none;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  .select {
    margin-bottom: 20px;
  }
`;

export const Switch = styled(BPSwitch)`
  margin: 15px 0px;
  color: ${props => props.theme.primary} !important;

  & .bp3-control-indicator {
    outline: none !important;
  }
  & input:checked ~ .bp3-control-indicator {
    background: ${props => props.theme.primary} !important;
  }
`;
