import React from "react";
// TODO import { ThemeProvider } from "styled-components";
import { BrowserRouter, RouteComponentProps } from "react-router-dom";
import { User } from "oidc-client";
import {
  AisIntent,
  NonIdealState,
  Spinner,
  SpinnerSize,
  StorageContext,
  StoragePartition
} from "@ais/ui";
import {
  AISTranslationService,
  AccessToken,
  AccessTokenContext,
  EventStore,
  EventStoreContext,
  NavigatorSelected,
  NavigatorSelectionContext,
  TranslationContext,
  TroponicContext,
  UserContext,
  UserProfile,
  troponicClients
} from "@ais/core";
// TODO import GlobalFontStyles from "../../assets/fonts/fonts";
import { TranslationResource, de, en, hi } from "locales";
import { FullCenterScreen } from "../../styles";
import { WebAppText } from "../../text";
import { AuthService } from "../../auth";
import { AppConfig } from "../../common";
import { Routes } from "./routes";
import { SignIn } from "./signIn";
import "./../../ais.scss";
import { AppNav } from "./appNav";
import { SignOut } from "./signOut";
import { AppSection, Main } from "./app.styles";

type SessionContext = AccessTokenContext
  & EventStoreContext
  & StorageContext
  & TranslationContext<TranslationResource>
  & TroponicContext
  & UserContext;

export interface AppProps extends RouteComponentProps { }

export interface AppState {
  sessionContext: SessionContext | null;
  selectedNavigator: NavigatorSelected | null;
}

export class App extends React.Component<AppProps, AppState> {
  private readonly tokenService: AuthService;
  private readonly eventStore: EventStore;

  constructor(props: AppProps) {
    super(props);

    this.tokenService = new AuthService({
      ...AppConfig.accessTokenServiceSettings,
      onIdentityChanged: this.handleIdentityChanged,
    });
    this.eventStore = new EventStore({
      ...AppConfig.EventServer,
      onConnected: this.handleConnected,
    });
    this.state = {
      sessionContext: null,
      selectedNavigator: null,
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.ensureContextsLoaded();
  }

  public async componentDidUpdate() {
    await this.ensureContextsLoaded();
  }

  public render() {
    return (
      // <ThemeProvider theme={null}>
      // <GlobalFontStyles />
      this.renderInner()
      // }</ThemeProvider>
    );
  }
  private handleCarrierSelect = (selection: NavigatorSelected) => {
    this.setState({
      selectedNavigator: selection,
    });
  };

  private renderInner() {
    const { selectedNavigator, sessionContext } = this.state;

    const navigatorSelectionContext: NavigatorSelectionContext = {
      navigatorSelection: selectedNavigator,
      onNavigatorSelection: this.handleCarrierSelect,
    };

    if (this.isSigningIn) {
      return <SignIn tokenContext={sessionContext} tokenService={this.tokenService} />;
    }

    if (this.isSigningOut) {
      return <SignOut tokenService={this.tokenService} />;
    }

    // Move the sessionContext when we have a tenant Selection page
    if (!sessionContext) {
      return (
        <FullCenterScreen>
          <NonIdealState
            icon={<Spinner colour={AisIntent.Primary} size={SpinnerSize.Standard} />}
            title={WebAppText.Authenticate}
          />
        </FullCenterScreen>
      );
    }

    return (
      <TroponicContext.Provider value={sessionContext}>
        <AccessTokenContext.Provider value={sessionContext}>
          <TranslationContext.Provider value={sessionContext}>
            <StorageContext.Provider value={sessionContext}>
              <NavigatorSelectionContext.Provider value={navigatorSelectionContext}>
                <UserContext.Provider value={sessionContext}>
                  <EventStoreContext.Provider value={sessionContext}>
                    <BrowserRouter basename="/">
                      <Main>
                        <AppNav />
                        <AppSection>
                          {Routes}
                        </AppSection>
                      </Main>
                    </BrowserRouter>
                  </EventStoreContext.Provider>
                </UserContext.Provider>
              </NavigatorSelectionContext.Provider>
            </StorageContext.Provider>
          </TranslationContext.Provider>
        </AccessTokenContext.Provider>
      </TroponicContext.Provider>
    );
  }

  private handleConnected = async () => {
    await this.listenOnAsync();
  };

  private handleIdentityChanged = async (identity: User | null) => {
    const { sessionContext } = this.state;
    if (!identity) {
      this.setState({ sessionContext: null });
    } else if (sessionContext) {
      sessionContext.token.refresh(identity);
    } else {
      const token = new AccessToken(identity);
      const profile = this.parseToken(token);
      const storagePartitionKey = `${profile.tenantId}-${profile.id}`;
      const storageContext: StorageContext = {
        storage: {
          local: new StoragePartition(localStorage, storagePartitionKey),
          session: new StoragePartition(sessionStorage, storagePartitionKey),
        },
      };

      const translator = new AISTranslationService<TranslationResource>({
        languages: { de, en, hi },
        showTranslations: AppConfig.TroponicConfig.showTranslations,
      });

      const troponic = troponicClients(AppConfig.TroponicConfig.baseUrl, token);

      this.setState(prevState => ({
        ...prevState,
        sessionContext: {
          ...prevState.sessionContext,
          troponic,
          token,
          user: profile,
          eventStore: this.eventStore,
          storage: storageContext.storage,
          translator,
          language: translator.language,
          changeLanguage: lng => this.handleLanguageChange(lng),
        },
      }));
    }
  };

  private async listenOnAsync() {
    const { sessionContext } = this.state;
    if (sessionContext) {
      // Listen to Chat Event
      sessionContext.eventStore.subscribe([{
        Eventype: "ChatEvent",
        Tenants: [sessionContext.user.tenantId!],
      },
      {
        Eventype: "OrderEvent",
        Tenants: [sessionContext.user.tenantId!],
      }]);
    }
  }

  private parseToken(token: AccessToken): UserProfile {
    return {
      name: token.getClaim("email")!,
      tenantId: token.getClaim("ais.tenant")!,
      email: token.getClaim("name")!,
      id: token.getClaim("sub")!,
    };
  }

  private async ensureContextsLoaded(): Promise<void> {
    if (!this.isSigningIn) {
      if (!this.state.sessionContext) {
        const identity = await this.tokenService.loadIdentity();
        if (!identity) {
          await this.tokenService.signInRedirect();
        }
      }
    }
  }

  private get isSigningIn() {
    return this.props.location.pathname.includes("/signin-oidc-callback");
  }

  private get isSigningOut() {
    return this.props.location.pathname.includes("/signout-oidc-callback");
  }

  private async handleLanguageChange(lng: string) {
    if (this.state.sessionContext) {
      await this.state.sessionContext.translator.changeLanguage(lng);
      this.setState(prevState => ({
        ...prevState,
        sessionContext: {
          ...prevState.sessionContext!,
          language: lng,
        },
      }));
    }
  }
}
