export const en = {
  grid: {
    defaultView: "Set to default",
    cogMenu: {
      showColumn: "Show Column",
      selectView: "Select View",
      saveView: "Save View",
      editView: "Edit View",
      showFilter: "Show Filter Row",
      columnsMoveable: "Columns Moveable",
      lastColumnErrorMessage: "Oops! Grid must have at least one column",
      noSearchResult: "No column found",
    },
    contextMenu: {
      sortAscending: "Sort in ascending order A-Z",
      sortDescending: "Sort in descending order Z-A",
      resetWidth: "Reset to default width",
      removeColumn: "Remove column from the list",
      groupByColumn: "Group by column",
      openFilterWindow: "Open filter window",
      sum: "Sum",
      minimum: "Minimum",
      maximum: "Maximum",
      countAll: "Count (All)",
      countNotEmpty: "Count (not empty)",
      mean: "Mean value",
    },
    filterOption: {
      startsWith: "Starts with",
      endsWith: "Ends with",
      includes: "Includes",
      equalTo: "Equal to",
      larger: "Larger",
      smaller: "Smaller",
      and: "And",
      or: "Or",
      laterOn: "From",
      before: "Till",
      include: "Equals",
      ignoreCase: "Filtering Case Sensitive",
      errorMessage: "Please enter the correct value for the filter",
      noFilterErrorMessage: "Please select a filter option to continue.",
    },
    group: {
      title: "Group",
      closeAll: "CLOSE ALL",
      openAll: "OPEN ALL",
    },
    saveView: {
      name: "Name of the View",
      visibility: "Sharing/Visibility",
      filterSettings: "Filter Settings",
      tableStructure: "Table structure",
      grouping: "Grouping",
      favorite: "Add Filter as favorit",
      all: "All",
      onlyMe: "Only for me",
      true: "true",
      false: "false",
    },
    selectView: {
      name: "Name",
      visibility: "Visibility",
      filter: "Filter",
      grouping: "Grouping",
      column: "Column",
      favourite: "Favourite",
      noData: "No View Available",
      selectView: "Select View",
      shareView: "Share View",
      deleteView: "Delete View",
      editView: "Edit View",
      deleteViewConfirmation: "Are you sure you want to delete this view?",
    },
    shareView: {
      select: "Select",
      remove: "Remove",
      selectAll: "Select All",
      removeAll: "Remove All",
      cancel: "Cancel",
      share: "Share",
      name: "Name",
      noData: "No Users Available",
    },
    removeColumnConfirmation: "Hiding the column will also remove all the associated filters / grouping with it. Would you like to continue?",
    hideLineFilterConfirmation: "Hiding the line filter will also clear the line filters added for any column. Would you like to continue?",
    selectionMode: "SELECTION MODE",
    gridContextMenu: {
      delete: "Delete",
    },
    tooltip: {
      noFavView: "No favorite views",
      expandRow: "Expand row",
      collapseRow: "Collapse row",
    },
  },
  positionGrid: {
    title: "Positions",
    columns: {
      amountSatellites: "Sat-Count",
      odometer: "Odometer",
      odometerDiff: "Odometer-Difference",
      speed: "Speed",
      startEnd: "State-End",
      startStart: "State-Start",
      state: "State",
      timespan: "Timespan",
      timeStamp: "TimeStamp",
      street: "Street",
      city: "City",
      gasLevel: "Gas-Level",
      latitude: "Latitude",
      longitude : "Longitude",
    },
  },
  carrierGrid: {
    title: "Carriers",
    columns: {
      name: "Name",
      license: "License",
      licenseExpiryDate: "License-Expiry-Date",
    },
  },
  driverGrid: {
    title: "Drivers",
    columns: {
      driverGuid: "DriverGuid",
      name: "Name",
      surName: "SurName",
      personalId: "PersonalId",
      driverCardId: "DriverCardId",
      active: "Active",
    },
  },
  orderGrid: {
    title: "Orders",
    columns: {
      name: "Name",
      pickupdate: "Pick Up Date",
      pickupcity: "Pick Up City",
      expectedDeliverDate: "Expected Delivery Date",
      status: "Status",
      truckNumber: "Truck Number",
      numberOfShipment: "Number of Shipments",
      perishable: "Perishable",
      receiver: "Receiver",
      receptionCity: "Reception City",
    },
  },
  messageGrid: {
    title: "Messages",
    columns: {
      sender: "Sender",
      recipient: "Recipient",
      driverSender: "Driver Sender",
      dateSent: "Date Sent",
      dateReceived: "Date Received",
      dateRead: "Date Read",
      editor: "Editor",
      messageText: "Message",
      timeStamp: "Time Stamp",
      driverRecipient: "Driver Recipient",
    },
  },
};
