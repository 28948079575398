import React from "react";
import { Route, Switch } from "react-router";
import { PositionsView } from "../pages/positions";
import { ChartPage } from "../pages/chart";
import { SecuritySideLogo } from "../pages/security";
import { AisSideLogo } from "../pages/ais";
import { JobGSideLogo } from "../pages/jobsGrey";
import { ModuleSideLogo } from "../pages/modules";
import { ChatSideLogo } from "../pages/chat";
import { ShowCase } from "../pages/showCase";
import { MapFunc } from "../pages/mapFunc";
import { Orders } from "../pages/orders";
import { MessageGridView } from "../pages/messageGrid";
import { UserLayout } from "./layouts/authorizedUsersLayout";

export const Routes = (
  <Switch>
    <UserLayout>
      {/* <Route exact component={Filter} path="/dashboard" /> */}
      <Route component={ChartPage} path="/dashboard" />
      <Route component={Orders} path="/orders" />
      <Route component={SecuritySideLogo} path="/tenant-admin" />
      <Route component={MessageGridView} path="/messages" />
      <Route component={PositionsView} path="/map" />
      <Route component={JobGSideLogo} path="/basedata" />
      <Route component={AisSideLogo} path="/ais" />
      <Route component={ModuleSideLogo} path="/driving times" />
      <Route component={ShowCase} path="/show_case" />
      <Route component={MapFunc} path="/map_func" />
      {/* To-Do: Confirm the messageGrid route */}
      <Route component={ChatSideLogo} path="/messageGrid" />
    </UserLayout>
  </Switch>
);
