import React, { useState } from "react";
import { Tab, Tabs } from "@blueprintjs/core";

export interface TabsViewProps {
  tabs: TabViewProps[];
}

export interface TabViewProps {
  id: string;
  content: JSX.Element;
  title?: string;
}
export const TabsView = (props: TabsViewProps) => {
  const { tabs } = props;
  const [activeTab, setActiveTab] = useState<string>();
  const handleTabChange = (id: string) => {
    setActiveTab(id);
  };

  return (
    <Tabs id="TabsExample" selectedTabId={activeTab} onChange={handleTabChange}>
      {tabs.map(tab => {
        return (
          <div key={tab.id} aria-hidden={false}>
            <Tab id={tab.id} panel={tab.content} title={tab.title} />
          </div>
        );
      })}
    </Tabs>
  );
};
