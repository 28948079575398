import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, withRouter } from "react-router-dom";
import { App } from "./scenes/app";
import { AuthService } from "./auth";
import { AppConfig } from "./common";
import { ErrorBoundary } from "./error-handling/errorBoundary";

const AppWithRouter = withRouter(App);
if (window.location.pathname === "silent-oidc-callback") {
  new AuthService(AppConfig.accessTokenServiceSettings).silentCallBack();
} else {
  ReactDOM.render(
    <ErrorBoundary>
      <BrowserRouter>
        <AppWithRouter />
      </BrowserRouter>
      ,
    </ErrorBoundary>,
    document.querySelector("#root")
  );
}
