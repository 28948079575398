import React, { useContext } from "react";
import { CarrierView } from "@aisalfaplan/troponic";
import { AccessToken } from "./accessToken";
import { UserProfile } from "./userProfile";

export interface AccessTokenContext {
  readonly token: Readonly<AccessToken>;
}

export const AccessTokenContext = React.createContext<AccessTokenContext>({
  get token(): AccessToken {
    throw new Error("AccessTokenContext is not initialized");
  },
});

export const useAccessToken = () => useContext(AccessTokenContext).token;

export type NavigatorSelected = {
  filter: {
    daterange: Date | null;
    vehicleGroup: number | null;
  } | null;
  carrier: CarrierView | null;
};

export interface NavigatorSelectionContext {
  navigatorSelection: NavigatorSelected | null;
  onNavigatorSelection(selection: NavigatorSelected): void;
}

export const NavigatorSelectionContext = React.createContext<NavigatorSelectionContext>({
  navigatorSelection: {
    carrier: null,
    filter: {
      daterange: null,
      vehicleGroup: null,
    },
  },
  onNavigatorSelection(): void {
    throw new Error("NavigatorSelectionContext is not initialized.");
  },
});

export const useNavSelection = () => useContext(NavigatorSelectionContext);

export interface UserContext {
  user: Readonly<UserProfile>;
}

export const UserContext = React.createContext<UserContext>({
  get user(): UserProfile {
    throw new Error("UserContext is not initialized");
  },
});

export const useUserProfile = () => useContext(UserContext).user;
