import { AccessTokenServiceSettings } from "../auth/authService";
import { EventServerSettings } from "../../../core/src/event";

class Config {
  public readonly accessTokenServiceSettings: AccessTokenServiceSettings;
  public readonly EventServer: EventServerSettings;
  public readonly TroponicConfig: {
    baseUrl: string;
    requestTimeOut: number;
    showTranslations: boolean;
  };

  constructor() {
    this.accessTokenServiceSettings = {
      authority: process.env.REACT_APP_AUTH_URL,
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
      clientSecret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
      scope: process.env.REACT_APP_AUTH_SCOPE,
      responseType: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
      authRedirectUrl: process.env.REACT_APP_AUTH_REDIRECT_URI,
      silentRedirectUrl: process.env.REACT_APP_AUTH_SILENT_REDIRECT_URI,
      postLogoutRedirectUrl: process.env.REACT_APP_AUTH_POSTLOGOUT_REDIRECT_URI,
      changePasswordRoute: process.env.REACT_APP_AUTH_CHANGE_PW_PATH,
    };
    this.TroponicConfig = {
      baseUrl: process.env.REACT_APP_TROPONIC_URL,
      requestTimeOut: process.env.REACT_APP_TROPONIC_REQUEST_TIMEOUT,
      showTranslations: "true" === process.env.REACT_APP_SHOW_TRANSLATION,
    };
    this.EventServer = {
      EventHub: process.env.REACT_APP_EVENT_HUB,
    };
  }
}

export { Config };
