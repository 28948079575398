import styled from "styled-components";
import { Icon } from "@ais/ui";

export const ImageContainer = styled.div`
  float:left;
  display: block;
  width: 40%;
  height: 55px;
`;

export const RoundedImage = styled.img`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const HeaderViewStyle = styled.div`
display: flex;
flex-direction: row;
height: 60px;
color: white;
  padding: 10px 15px 10px 20px;
`;

export const Headline = styled.span`
  font-size: 30px;
  padding: 0px 25px 0px 0px;
`;

export const HeadFilter = styled.span`
  margin-left: auto;
  align-self: center;
`;

export const IconStyle = styled(Icon)`
  padding: 0px 5px 0px 5px;
  align-self: center;
`;
