import styled from "styled-components";
import { Card } from "@ais/ui";

export const CardBody = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const CardWrapper = styled(Card)`
  border-radius: 4px;
  margin: 2px;
  padding: 15px 5px 5px 5px;
  cursor: pointer;
`;

export const CardIcon = styled.div`
  float:left;
  height: 45px;
  width: 50px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  padding: 0em;
  margin: 0em;
`;

export const RowContent = styled.span`
  margin-left: 5px;
  margin-right: 5px;
  font-size: 13px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &.main{
    width: 31%;
  }
  &.minor{
    width: 23%;
  }
  &.mini{
    width: 13%;
  }
`;
export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  &.header {
    font-weight: bold;
  }
`;
