import styled from "styled-components";
import { Alert } from "@ais/ui";

export const StyledAlert = styled(Alert)`
  .bp3-intent-primary {
    background-color: ${props => props.theme.primary};
    :hover {
      background-color: ${props => props.theme.primary};
    }
  }

  .bp3-button {
    :focus {
      outline: none;
    }
  }

  z-index: 999;
`;
