import React, { useRef, useState } from "react";
import { IDateInputProps, IItemRendererProps, MenuItem, Select } from "@ais/ui";
import { BaseRow, EditableColumnWithStrictAccessor } from "types";

import {
  CheckboxField,
  DateInputField,
  InputField,
  NumericInputField,
  StatusLabel
} from "./columnConfig.styles";

const maxDate = new Date(2120, 1, 1);
const minDate = new Date(1920, 1, 1);

export interface OrderData extends BaseRow {
  name: string;
  pickupdate: Date;
  pickupcity: string;
  expectedDeliverDate: Date;
  status: string;
  truckNumber: number;
  numberOfShipment: number | null;
  perishable: string;
  receiver: string;
  receptionCity: string;
}

export type OrderColumnNames = Omit<OrderData, keyof BaseRow>;

export const columnConfig: EditableColumnWithStrictAccessor<OrderData>[] = [
  {
    Header: "Name",
    accessor: "name",
    editableCell: ({ cell, value, onCellDataChange }) => (
      <InputField defaultValue={value} onChange={(value:string) => onCellDataChange(cell, value)} />
    ),
  },
  {
    Header: "Pick Up Date",
    accessor: "pickupdate",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Cell: ({ value }) => value.toLocaleString("de-DE"),
    editableCell: ({ cell, value, onCellDataChange }) => (
      <DataInputField
        reverseMonthAndYearMenus
        defaultValue={value ?? undefined}
        formatDate={date => date.toLocaleString("de-DE")}
        maxDate={maxDate}
        minDate={minDate}
        parseDate={str => new Date(str)}
        timePrecision="second"
        onChange={(selectedDate: Date) => onCellDataChange(cell, selectedDate)}
      />
    ),
  },
  {
    Header: "Pick Up City",
    accessor: "pickupcity",
    editableCell: ({ cell, value, onCellDataChange }) => (
      <InputField defaultValue={value} onChange={(value:string) => onCellDataChange(cell, value)} />
    ),
  },
  {
    Header: "Expected Delivery Date",
    accessor: "expectedDeliverDate",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Cell: ({ value }) => value.toLocaleDateString("de-DE"),
    editableCell: ({ cell, value, onCellDataChange }) => (
      <DataInputField
        reverseMonthAndYearMenus
        defaultValue={value ?? undefined}
        formatDate={date => date.toLocaleDateString("de-DE")}
        maxDate={maxDate}
        minDate={minDate}
        parseDate={str => new Date(str)}
        onChange={(selectedDate: Date) => onCellDataChange(cell, selectedDate)}
      />
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    editableCell: ({ cell, value, onCellDataChange }) => (
      <StatusSelect defaultValue={value} onItemSelect={status => onCellDataChange(cell, status)} />
    ),
  },
  {
    Header: "Truck Number",
    accessor: "truckNumber",
    editableCell: ({ cell, value, onCellDataChange }) => (
      <NumericInputField
        defaultValue={value ?? undefined}
        onValueChange={(valueAsNumber: number) => onCellDataChange(cell, valueAsNumber)}
      />
    ),
  },
  {
    Header: "Number of Shipments",
    accessor: "numberOfShipment",
    editableCell: ({ cell, value, onCellDataChange }) => (
      <NumericInputField
        defaultValue={value ?? undefined}
        onValueChange={(valueAsNumber: number) => onCellDataChange(cell, valueAsNumber)}
      />
    ),
  },
  {
    Header: "Perishable",
    accessor: "perishable",
    editableCell: ({ cell, value, onCellDataChange }) => (
      <CheckboxField
        defaultChecked={value === "Y"}
        onChange={(checked: boolean) =>
          onCellDataChange(cell, checked ? "Y" : "N")}
      />
    ),
  },
  {
    Header: "Receiver",
    accessor: "receiver",
    editableCell: ({ cell, value, onCellDataChange }) => (
      <InputField defaultValue={value} onChange={(value:string) => onCellDataChange(cell, value)} />
    ),
  },
  {
    Header: "Reception City",
    accessor: "receptionCity",
    editableCell: ({ cell, value, onCellDataChange }) => (
      <InputField defaultValue={value} onChange={(value:string) => onCellDataChange(cell, value)} />
    ),
  },
];

function statusRenderer(status: string, itemProps: IItemRendererProps) {
  if (!itemProps.modifiers.matchesPredicate) {
    return null;
  }

  return <MenuItem key={status} active={itemProps.modifiers.active} text={status} onClick={itemProps.handleClick} />;
}

function filterStatus(query: string, status: string) {
  return status.toLowerCase().includes(query.toLowerCase());
}

export interface StatusSelectProps {
  defaultValue: string;
  onItemSelect: (item: string) => void;
}

function StatusSelect(props: StatusSelectProps) {
  const Statuses: string[] = ["En route", "Received"];
  const StatusDropDown = Select.ofType<string>();
  const { defaultValue, onItemSelect } = props;
  const [text, setText] = useState<string>(defaultValue);

  return (
    <StatusDropDown
      itemPredicate={filterStatus}
      itemRenderer={statusRenderer}
      items={Statuses}
      popoverProps={{ minimal: false }}
      onItemSelect={handleItemSelect}
    >
      <StatusLabel>{text}</StatusLabel>
    </StatusDropDown>
  );

  function handleItemSelect(status: string) {
    setText(status);
    onItemSelect(status);
  }
}

function DataInputField(props: IDateInputProps) {
  const [value, setValue] = useState<Date | undefined>(props.defaultValue);
  const lastValidDate = useRef<Date | undefined>(props.defaultValue);

  return <DateInputField {...props} value={value} onChange={handleDateChange} onError={handleError} />;

  function handleError() {
    setValue(lastValidDate.current);
  }

  function handleDateChange(selectedDate: Date, isUserChange: boolean) {
    lastValidDate.current = selectedDate;
    setValue(selectedDate);
    props.onChange?.(selectedDate, isUserChange);
  }
}
