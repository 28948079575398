import React from "react";
import { CSSProperties } from "styled-components";
import { Cell, CellValue, IdType, Row } from "react-table";
import { Icon } from "@ais/ui";
import { AggregateActions, BaseRow } from "types";
import { AggregationText, aggregateColumn } from "../../../components";

import { AggregatedWrapper, GroupIndent, GroupLabelWrapper, GroupVal, RowWrapper } from "./rowItem.styles";

export interface RowIElementProps<D extends BaseRow> {
  aggregateColumns: { id: IdType<D>; operation: string }[];
  index: number;
  isEditing: boolean;
  isResizing: boolean;
  row: Row<D>;
  onCellDataChange: (cell: Cell<D, any>, value: CellValue<any>) => void;
  prepareRow: (row: Row<D>) => void;
  toggleRowExpanded: () => void;
  style?: CSSProperties;
}

function RowIElement<D extends BaseRow>(props: RowIElementProps<D>) {
  const { index, row, style, prepareRow, aggregateColumns, onCellDataChange, isEditing } = props;

  const className = index % 2 === 0 ? "table-cell-ledger-even" : "table-cell-ledger-odd";
  prepareRow(row);

  if (row.isGrouped) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id, groupByID, groupByVal, isExpanded, leafRows, cells } = row;
    const level = id.split(">").length - 1;
    // TODO: take the grid name as props
    const label = groupByID; // TODO: visit this later when we work on groups
    const isEmpty = groupByVal === "null";
    const val = isEmpty ? "EMPTY" : groupByVal;
    return (
      <RowWrapper {...row.getRowProps({ style })} $isExpanded={isExpanded}>
        <GroupLabelWrapper $isExpanded={isExpanded}>
          <GroupIndent $level={level} />
          <span {...row.getToggleRowExpandedProps()} onClick={handleRowExpanedClick}>
            {isExpanded ? <Icon icon="chevron-down" iconSize={14} /> : <Icon icon="chevron-right" iconSize={14} />}
          </span>
          &nbsp;{label}: <GroupVal $isEmpty={isEmpty}>{val}</GroupVal> ({leafRows.length})
        </GroupLabelWrapper>
        {cells.map(cell => {
          const { style } = cell.getCellProps();
          let value: number | null = null;
          const aggreate = aggregateColumns.find(a => a.id === cell.column.id);
          if (aggreate) {
            value = aggregateColumn(cell.column.id, leafRows, aggreate.operation as AggregateActions);
          }
          return (
            // eslint-disable-next-line react/jsx-key
            <AggregatedWrapper {...cell.getCellProps({ style: { ...style, top: 22 } })}>
              {aggreate ? <AggregationText action={aggreate.operation as AggregateActions} value={value} /> : null}
            </AggregatedWrapper>
          );
        })}
      </RowWrapper>
    );
  }
  return (
    <div {...row.getRowProps({ style })} className={`${className} table-row`}>
      {row.cells.map(cell => (
        // eslint-disable-next-line react/jsx-key
        <div {...cell.getCellProps()} className="table-cell">
          <div className="table-no-wrap-text table-cell-text">
            {isEditing && cell.column.id !== "selection"
              ? cell.render("editableCell", { onCellDataChange })
              : cell.render("Cell")}
          </div>
        </div>
      ))}
    </div>
  );

  function handleRowExpanedClick() {
    props.toggleRowExpanded();
    row.toggleRowExpanded();
  }
}

function propsAreEqual<D extends BaseRow>(prevProps: RowIElementProps<D>, nextProps: RowIElementProps<D>) {
  if (nextProps.isResizing || nextProps.row.isGrouped || prevProps.isEditing !== nextProps.isEditing) {
    return false;
  }

  return (
    prevProps.row.original.rowId === nextProps.row.original.rowId
    && prevProps.row.original.lastUpdateDate === nextProps.row.original.lastUpdateDate
  );
}

export const RowItem = React.memo(RowIElement, propsAreEqual) as typeof RowIElement;
