import styled from "styled-components";
import { Card } from "@ais/ui";

export const RightContainer = styled.div`
 background: white;
 align-self: center;
 flex: 1;
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100%;
 overflow-y: auto;
 overflow-x: hidden;
 border-radius: 20px 0px 0px 0px;
 padding: 10px 10px 10px 10px;
`;

export const WidgetCard = styled(Card)`
  flex: 1;
  border-radius: 5px;
  align-items: center;
  overflow: auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: -5px 0px 10px #00000029;
  opacity: 1;
  height: 100%;
  width: 100%;
  right: 0;
  margin:0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const WidgetHeader = styled.div`
display: flex;
padding: 15px;
`;
