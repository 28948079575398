import { createContext, useContext } from "react";
import { TranslationService } from "./translationService";

export interface TranslationContext<T> {
  readonly language: string;
  readonly translator: Readonly<TranslationService<T>>;
  changeLanguage: (lng: string) => Promise<void>;
}

export const TranslationContext = createContext<TranslationContext<any>>({
  get language(): string {
    return "en";
  },
  get translator(): TranslationService<any> {
    throw new Error("TranslationContext is not initialized");
  },
  changeLanguage: () => {
    throw new Error("TranslationContext is not initialized");
  },
});

export const useTranslation = <T>() => useContext<TranslationContext<T>>(TranslationContext);
export const useTranslator = <T>() => useContext<TranslationContext<T>>(TranslationContext).translator;
