import styled from "styled-components";

export const MenuItem = styled.div`
  margin-top: 20px;
  margin-right:200px;
  color: #000000;
  font-family: 'Calibri Light';
  font-weight: bold;
  font-size: 12px;
  text-decoration: none;
`;
