import React from "react";
import * as Core from "@blueprintjs/core";

export interface SwitchProps {
  checked?: boolean;
  disabled?: boolean;
  innerLabelChecked?: string;
  innerLabel?: string;
  label?: string;
  onChange?: ((checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void) | (() => void) ;
}

export const Switch = (props: SwitchProps) => {
  const {
    onChange,
    ...switchProps
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.currentTarget.checked, event);
  };

  return (
    <Core.Switch
      {...switchProps}
      onChange={handleChange}
    />
  );
};
