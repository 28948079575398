import React from "react";
import {
  Checkbox,
  Container,
  Icon,
  IconContainer,
  LeftIconContainer,
  RightIconContainer,
  Slider
} from "./editModeToggle.styles";

export interface EditModeToggleProps {
  isEdit: boolean;
  onChange: () => void;
}

export function EditModeToggle(props: EditModeToggleProps) {
  const { isEdit, onChange } = props;

  return (
    <Container>
      <Checkbox onChange={onChange} />
      <Slider>
        <IconContainer>
          <LeftIconContainer>
            <Icon icon="eye-open" iconSize={14} open={!isEdit} />
          </LeftIconContainer>
          <RightIconContainer>
            <Icon icon="annotation" iconSize={10} open={isEdit} />
          </RightIconContainer>
        </IconContainer>
      </Slider>
    </Container>
  );
}
