import React, { ReactNode } from "react";

interface ErrorBoundryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  errorExists: boolean;
}

export class ErrorBoundary extends React.Component<ErrorBoundryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundryProps) {
    super(props);
    this.state = { errorExists: false };
  }

  public render() {
    return this.state.errorExists ? (<h3>Sorry..there seems to be an issue. Please come back later</h3>) : this.props.children;
  }
}
