import styled from "styled-components";
import { Button as BPButton, Card, Switch } from "@ais/ui";

export interface GridWrapperProps {
  maxWidth: number;
  width?: number;
}

export const GridWrapper = styled.div<GridWrapperProps>`
  height: 100%;
  .table-container {
    width: ${props => props.width ? `${props.width}px` : "100px"};
    max-width: ${props => props.maxWidth}px;
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  .table-top-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .table-column-headers {
    user-select: none;
    height: 60px;
  }
  .table-row {
    border-left: 1px solid #f1f1f3;
  }
  .table-cell-ledger-even {
    background-color: #f5f6fa;
    user-select: none;
  }
  .table-cell-ledger-odd {
    background-color: #fff;
    user-select: none;
  }
  .table-cell {
    color: #4d4f5c;
    box-shadow: inset 0 -1px 0 #f1f1f3, inset -1px 0 0 #f1f1f3;
    padding: 0 10px 0 10px;
    height: 30px;
    display: flex;
    align-items: center;
  }
  .table-cell-text {
    font-size: 12px;
  }
  .table-no-wrap-text {
    line-height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
  .table-resize-handle {
    background-color: ${props => props.theme.primary};
    opacity: 0.5;
  }
  :focus {
    outline: none;
  }

  .table-row-name-text {
    pointer-events: all;
  }

  .table-column-header-cell {
    position: relative;
  }

  .resizer {
    display: inline-block;
    width: 3px;
    background: ${props => props.theme.primary};
    opacity: 0;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    /* prevents from scrolling while dragging on touch devices */
    touch-action:none;

    &.isResizing {
      opacity: 0.7;
    }

    :hover {
      opacity: 0.5;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin: 0px 10px;
  white-space: nowrap;
  display: flex;
  flex: 1;
`;

export const Button = styled(BPButton)`
  color: ${props => props.theme.primary} !important;
  :focus {
    outline: none;
    background-image: none;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${p => p.theme.text};
  margin: 0px 0px 10px 0px;
  flex: none;
  min-width: 200px;
`;

export const SelectedViewName = styled.div`
  padding: 4px;
  border: ${props => `2px solid ${props.theme.primary}`};
  border-radius: 13px;
  max-width: 100px;
  height: 26px;
  margin: 5px;
  background: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
  user-select: none;
  color: ${props => props.theme.primary};
  font-weight: bold;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WrapperCard = styled(Card)`
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  padding-left: 4px;
  padding-right: 4px;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;
  flex-grow: 1;
`;

export interface HeaderGroupContainerProps {
  $height: number;
}

export const HeaderGroupContainer = styled.div<HeaderGroupContainerProps>`
  user-select: none;
  height: ${props => props.$height}px;
  background-color: #efecf4;
`;

export const CogMenuWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 6px;
  z-index: 1;
`;

export const CogIconWrapper = styled.div`
  box-shadow: 0 4px 8px 0 #857e92, 0 6px 20px 0 #857e92;
  background: #efecf4;
  padding: 3px;
  border-radius: 8px;
`;

export const GridAreaWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const GroupAreaWrapper = styled.div`
  height: 40px;
  display: flex;
  overflow: auto;
  background: rgb(238, 236, 243);
  align-items: center;
  min-width: 430px;
  border-radius: 4px;
  box-shadow: rgb(206, 202, 216) 0px 0px 10px inset;
  scrollbar-width: 0px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const GroupAreaLabel = styled.div`
  color: ${props => props.theme.primary};
  font-weight: bold;
  font-size: 12px;
  margin-right: 10px;
  flex: none;
`;

export const GroupItem = styled.div`
  padding: 0px 4px;
  border: ${props => `2px solid ${props.theme.primary}`};
  border-radius: 16px;
  height: 26px;
  margin-left: 8px;
  background: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  user-select: none;
  flex: none;
  color: ${props => props.theme.primary};
  font-weight: bold;
  font-size: 11px;
  :last-child {
    margin-right: 8px;
  }
`;

export const GroupItemDeleteIcon = styled.div`
  margin-left: 4px;
  padding: 2px;
  border-radius: 10px;
  background-color: ${props => props.theme.primary};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const GroupExpandLink = styled.div`
  padding-left: 60px;
  padding-right: 8px;
  margin-left: auto;
  display: flex;
  align-items: center;
  color: ${props => props.theme.primary};
  cursor: pointer;
  user-select: none;
  flex: none;
`;

export const GroupExpandLinkText = styled.div`
  font-size: 11px;
  font-weight: bold;
`;

export const RightContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const SelectionModeSwitch = styled(Switch)`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0px;
  color: ${props => props.theme.icon.muted};
  white-space: nowrap;
`;

export const RightItemSeparator = styled.div`
  width: 2px;
  height: 30px;
  background: ${props => props.theme.primaryBackGround};
  margin-left: 8px;
  margin-right: 8px;
`;

export const ColumnHeader = styled.div`
  box-shadow: white -1px 0px inset;
  background-color: #efecf4;
  color: #43425d;
  font-weight: bold;
  font-size: 11px;
  padding: 0 10px;
  line-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  height: 30px;
`;

export const GridArea = styled.div`
  width: 100%;
  height: 100%;
`;
