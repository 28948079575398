import React, { useState } from "react";
import { Classes, TextArea } from "@blueprintjs/core";
import classNames from "classnames";
import { Button } from "../button/button";
import { Icon } from "../icon/icon";
import {
  ChatFrom,
  ChatInfo,
  ChatReplyButton,
  ChatTimeStamp,
  ChatTitle,
  ChatWindowBody,
  ChatWindowFooter,
  ChatWindowView
} from "./chatWindowView.styles";

interface ChatWindowViewProps {
  open?: boolean;
  onClose: () => void;
  title: string;
  messages: ChatMessageProps[];
}

export interface ChatMessageProps {
  from: string;
  sent: Date;
  message: string;
}

export const ChatWindow = (props: ChatWindowViewProps) => {
  const { onClose, open, title, messages } = props;
  // Sorted in descending order 
  const sortedWithLatestMessage = messages.sort((a: ChatMessageProps, b: ChatMessageProps) => b.sent.getTime() - a.sent.getTime());
  const [currentMsgIndex, setCurrentMsgIndex] = useState(0);
  const messagesCount = sortedWithLatestMessage.length - currentMsgIndex;
  const classes = classNames(
    Classes.CARD,
    Classes.ELEVATION_4
  );
  const handleChatClose = () => {
    onClose();
  };
  if (!open) {
    return null;
  }

  const handleOkClick = () => {
    if (currentMsgIndex < messages.length) {
      setCurrentMsgIndex(currentMsgIndex + 1);
    }
  };

  return (
    currentMsgIndex >= messages.length ? null : (
      <ChatWindowView>
        <ChatWindowBody className={classes}>
          <ChatTitle>
            <h3>{title} {messagesCount}</h3>
            <Icon icon="aisClose" iconSize={12} onClick={handleChatClose} />
          </ChatTitle>
          <div>
            <ChatFrom>
              <TextArea disabled value={`From: ${sortedWithLatestMessage[currentMsgIndex].from}`} />
            </ChatFrom>
            <ChatInfo>
              <TextArea disabled fill growVertically={false} value={sortedWithLatestMessage[currentMsgIndex].message} />
              <ChatTimeStamp>{sortedWithLatestMessage[currentMsgIndex].sent.toLocaleString()}</ChatTimeStamp>
            </ChatInfo>
          </div>
          <ChatWindowFooter>
            <ChatReplyButton text="Reply" />
            <Button text="Ok" onClick={handleOkClick} />
          </ChatWindowFooter>
        </ChatWindowBody>
      </ChatWindowView>
    )
  );
};
