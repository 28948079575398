import _ from "lodash";
import moment, { Moment } from "moment";

export interface BaseStringFilter {
  ignoreCase?: boolean;
  text: string;
}
export interface StringFilter {
  startsWith?: BaseStringFilter;
  endsWith?: BaseStringFilter;
  includes?: BaseStringFilter;
}

export interface DateRangeFilter {
  start: Moment;
  end: Moment;
}

export interface DateFilter {
  laterOn?: Moment;
  before?: Moment;
  include?: Moment;
}

export interface FilterConfigurations {
  filterSet: ResourceFilterSet[];
  lineFilter?: string;
}

export interface ConfiguredFilters {
  filters: (StringResolver | NumberResolver | DateResolver)[][];
  lineFilter: (v: string) => boolean;
}

export interface NumberFilter {
  larger?: number;
  smaller?: number;
  equalTo?: number;
}
export interface ResourceFilterSet extends StringFilter, NumberFilter, DateFilter {}

export type StringResolver = (v: string) => boolean;
export type NumberResolver = (v: number) => boolean;
export type DateResolver = (v: Moment) => boolean;

function stringComparator(op1: string, op2: string) {
  return op1.toLocaleLowerCase().includes(op2.toLocaleLowerCase());
}

function withNormalization(fx: (op1: string, op2: string) => boolean, value: string, operand: BaseStringFilter) {
  if (operand.ignoreCase) {
    return fx(value?.toLocaleLowerCase(), operand.text.toLocaleLowerCase());
  }

  return fx(value, operand.text);
}

function convertToNumber(operand: BaseStringFilter | number) {
  return !operand ? Number.NaN : Number(operand);
}

function resolveComparator(
  key: keyof ResourceFilterSet,
  operand: BaseStringFilter | number | Moment | DateRangeFilter
): StringResolver | NumberResolver | DateResolver {
  switch (key) {
    case "startsWith":
      return (value: string) =>
        withNormalization((op1, op2) => op1.startsWith(op2), value, operand as BaseStringFilter);
    case "endsWith":
      return (value: string) => withNormalization((op1, op2) => op1.endsWith(op2), value, operand as BaseStringFilter);
    case "includes":
      return (value: string) => withNormalization((op1, op2) => op1.includes(op2), value, operand as BaseStringFilter);
    case "larger":
      return (value: number) => value > convertToNumber(operand as number);
    case "smaller":
      return (value: number) => value < convertToNumber(operand as number);
    case "equalTo":
      return equalChecker(operand);
    case "laterOn":
      return (value: Moment) => moment(value).isAfter(operand as Moment, "day");
    case "before":
      return (value: Moment) => moment(value).isBefore(operand as Moment, "day");
    case "include":
      return (value: Moment) => moment(value).isSame(operand as Moment, "day");
    default:
      return () => true;
  }
}

function equalChecker(operand: BaseStringFilter | number | Moment | DateRangeFilter): StringResolver | NumberResolver {
  if (_.isEmpty(operand)) {
    // We should return empty row checker
    return (value: string) => value === "" || value === undefined || value === null;
  }
  // We should return equal check function
  return (value: number) => value === convertToNumber(operand as number);
}

function resolvePredicate(filter: ResourceFilterSet) {
  const keys = Object.keys(filter) as (keyof ResourceFilterSet)[];

  return keys.map(key => resolveComparator(key, filter[key]!));
}

export function resolveFilters(configurations: FilterConfigurations): ConfiguredFilters {
  return {
    filters: configurations.filterSet.map(filter => resolvePredicate(filter)),
    lineFilter: (value: string) => stringComparator(value, configurations.lineFilter ?? ""),
  };
}

export function applyFilter(configuredFilters: ConfiguredFilters, queryText: string | number | Moment) {
  return (
    configuredFilters.lineFilter(String(queryText))
    && (configuredFilters.filters.length === 0
      || configuredFilters.filters.some(filter =>
        filter.every(fx => {
          if (typeof queryText === "string") {
            return (fx as StringResolver)(queryText as string);
          }

          if (queryText instanceof moment) {
            return (fx as DateResolver)(queryText as Moment);
          }

          if (typeof queryText === "number" || typeof queryText === "object" || typeof queryText === "undefined") {
            return (fx as NumberResolver)(queryText as number);
          }

          return false;
        })))
  );
}
