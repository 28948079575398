import React from "react";
import * as Core from "@blueprintjs/core";

export interface CheckboxProps {
  defaultChecked?: boolean;
  disabled?: boolean;
  checked?: boolean;
  label?: string;
  onChange?: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = (props: CheckboxProps) => {
  const {
    onChange,
    ...checkboxProps
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.currentTarget.checked, event);
  };

  return (
    <Core.Checkbox
      {...checkboxProps}
      onChange={handleChange}
    />
  );
};
