import { createContext, useContext } from "react";
import { EventStore } from "./eventStore";

export interface EventStoreContext {
  readonly eventStore: Readonly<EventStore>;
}
export const EventStoreContext = createContext<EventStoreContext>({
  get eventStore(): EventStore {
    throw new Error("EventStore is not initialized");
  },
});
export const useEventStoreContext = () => useContext(EventStoreContext).eventStore;
