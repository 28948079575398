import React from "react";
import * as Core from "@blueprintjs/datetime";
import { Classes } from "@blueprintjs/core";
import { maxDate, minDate } from "./constants";
import { DateViewsWrapper } from "./dateViews.styles";

export interface DateTimeProps {
  DateTimeProps?: boolean;
  onChange?: (selectedDate: Date) => void;
  showTime?: boolean;
}

export const DateTime = (props: DateTimeProps) => {
  const handleChange = (selectedDate: Date) => {
    const { onChange } = props;
    onChange?.(selectedDate);
  };
  return (
    <DateViewsWrapper>
      <Core.DatePicker
        highlightCurrentDay
        className={Classes.ELEVATION_1}
        maxDate={maxDate}
        minDate={minDate}
        showActionsBar={props.DateTimeProps}
        timePrecision={props.showTime ? Core.TimePrecision.MINUTE : undefined}
        onChange={handleChange}
      />
    </DateViewsWrapper>
  );
};
