import React from "react";
import { Message } from "@aisalfaplan/troponic";
import { getDate, useTranslator } from "@ais/core";
import { TranslationResource } from "locales";
import { EditableColumnWithStrictAccessor } from "types";
import { Grid } from "../components";

import { MessageColumnNames, MessageData, columnConfig } from "./columnConfig";

export interface MessageGridProps {
  data: Message[];
}
export const MessageGrid = (props: MessageGridProps) => {
  const { data } = props;
  const { t, translationKeys } = useTranslator<TranslationResource>();

  const messageData: MessageData[] = data.map(m => ({
    rowId: Number(m.resourceId),
    lastUpdateDate: Date.now(),
    sender: m.sender,
    recipient: m.recipient,
    driverSender: m.driverSender,
    dateSent: getDate(m.dateSent),
    dateReceived: getDate(m.dateReceived ?? "2020-12-26T18:24:11.225Z"), // To-Do
    dateRead: getDate(m.dateRead ?? "2020-12-26T18:24:11.225Z"), // To-Do: Update the Date field to accept undefined
    editor: m.editor,
    messageText: m.messageText,
    timeStamp: getDate(m.timeStamp),
    driverRecipient: m.driverRecipient,
  }));

  return (
    <Grid<MessageData>
      columnConfig={getColumnConfig()}
      gridKey="messageGrid"
      initialData={messageData}
    />
  );

  function getColumnConfig(): EditableColumnWithStrictAccessor<MessageData>[] {
    return columnConfig.map(c => ({
      ...c,
      Header: t(translationKeys.messageGrid.columns[c.accessor as keyof MessageColumnNames]),
    }));
  }
};
