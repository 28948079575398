import styled, { css } from "styled-components";
import {
  Button as BPButton,
  Dialog as BPDialog,
  Icon as BPIcon,
  HTMLTable as HtmlTableComponent
  , InputGroup } from "@ais/ui";

export const Dialog = styled(BPDialog)`
  position: relative;
  border-radius: 20px;
  padding: 36px 40px 20px;
  background: ${props => props.theme.background};
  width: max-content;
  .in-active {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const CloseIcon = styled(BPIcon)`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  background: ${props => props.theme.primary};
  color: ${props => props.theme.icon.default};
  border-radius: 100%;
  padding: 4px;
`;

export interface HTMLTableProps {
  fixed?: boolean;
}

export const HTMLTable = styled(HtmlTableComponent)<HTMLTableProps>`
  ${props =>
    props.fixed
    && css`
      table-layout: fixed;
    `};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  height: 50vh;
  width: 30vw;
  padding-bottom: 20px;
`;

export const ContainerBody = styled.div`
  overflow-y: auto;
  max-height: 40vh;
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-content: stretch;

  > table.bp3-html-table {
    width: 100%;
  }

  display: flex;

  thead th {
    position: sticky;
    top: 0;
    background: ${props => props.theme.background};
    z-index: 1;
  }
`;

export const RowItem = styled.tr`
  cursor: pointer;
  .select {
    visibility: hidden;
  }
  &:hover {
    background-color: ${props => props.theme.hover};
    .select {
      visibility: visible;
    }
  }
`;

export const NameCell = styled.div`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Button = styled(BPButton)`
  color: ${props => props.theme.primary} !important;
  :focus {
    outline: none;
    background-image: none;
  }
`;

export const SelectAll = styled(BPButton)`
  color: ${props => props.theme.primary} !important;
  :focus {
    outline: none;
    background-image: none;
    box-shadow: none !important;
    background: none !important;
  }
`;

export const ActionHeader = styled.th`
  width: 180px;
  text-align: center !important;
`;

export const ActionCell = styled.td`
  text-align: center !important;
`;

export const Input = styled(InputGroup)`
  width: 100%;
  margin: 20px 0px;
`;
