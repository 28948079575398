import React from "react";
import { noop } from "lodash";
import { ActionLeftIcon, ActionLeftView, ActionName, ActionRightIcon, Card } from "./actionCard.styles";

export interface ActionCardProps {
  name: string;
  disabled: boolean;
  onClick: () => void;
}

export const ActionCard = (props: ActionCardProps) => {
  const { disabled, name, onClick } = props;
  return (
    <Card className={disabled ? "disabled" : undefined} onClick={!disabled ? onClick : noop}>
      <ActionLeftView>
        <ActionLeftIcon className={disabled ? "disabled" : undefined} icon="annotation" iconSize={20} />
        <ActionName>{name}</ActionName>
      </ActionLeftView>
      <ActionRightIcon icon="chevron-right" iconSize={10} />
    </Card>
  );
};
