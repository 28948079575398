import React from "react";
import * as Core from "@blueprintjs/core";

// TODO: This needs to be separarted from blueprintInterface
export interface DialogProps extends Core.DialogProps { }

export const Dialog = (props: React.PropsWithChildren<DialogProps>) => {
  return (
    <Core.Dialog {...props}>
      {props.children}
    </Core.Dialog>
  );
};
