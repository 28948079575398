import { createContext, useContext } from "react";

import { Troponic as TroponicClients } from "@aisalfaplan/troponic";

export interface TroponicContext {
  readonly troponic: Readonly<TroponicClients>;
}

export const TroponicContext = createContext<TroponicContext>({
  get troponic(): TroponicClients {
    throw new Error("TroponicContext is not initialized");
  },
});

export const useTroponic = () => useContext(TroponicContext).troponic;
