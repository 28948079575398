import React from "react";
import { Order } from "@aisalfaplan/troponic";
import { getDate, useTranslator } from "@ais/core";
import { Grid } from "../components";
import { EditableColumnWithStrictAccessor } from "../types";
import { TranslationResource } from "../locales";
import { OrderColumnNames, OrderData, columnConfig } from "./columnConfig";

export interface OrderGridProps {
  data: Order[];
}

export const OrderGrid = (props: OrderGridProps) => {
  const { data } = props;
  const { t, translationKeys } = useTranslator<TranslationResource>();

  const orders: OrderData[] = data.map(d => ({
    name: d.user!,
    pickupcity: d.sender.city!,
    expectedDeliverDate: getDate(d.deliveryDateTill ?? "2020-12-26T18:24:11.225Z"), // We need better
    status: "Active",
    truckNumber: d.mobileDeviceId!,
    numberOfShipment: 1,
    perishable: "No",
    receiver: d.recipient.name!,
    receptionCity: d.recipient.city!,
    rowId: Number(d.resourceId),
    lastUpdateDate: 121212,
    selection: false,
    pickupdate: getDate(d.pickupDateFrom ?? "2020-12-26T18:24:11.225Z"), // We need better
  }));

  return (
    <Grid<OrderData> filterable groupable columnConfig={getColumnConfig()} gridKey="orderGrid" initialData={orders} />
  );

  function getColumnConfig(): EditableColumnWithStrictAccessor<OrderData>[] {
    return columnConfig.map(c => ({
      ...c,
      Header: t(translationKeys.orderGrid.columns[c.accessor as keyof OrderColumnNames]),
    }));
  }
};
