import { TranslationResource } from "./types";

export const de: TranslationResource = {
  grid: {
    defaultView: "Auf Standard setzen",
    cogMenu: {
      showColumn: "Spalte anzeigen",
      selectView: "Ansicht auswählen",
      saveView: "Ansicht speichern",
      editView: "Ansichten bearbeiten",
      showFilter: "Filterzeile einblenden",
      columnsMoveable: "Spalten verschiebbar",
      lastColumnErrorMessage: "Hoppla! Das Raster muss mindestens eine Spalte haben.",
      noSearchResult: "Keine Spalte gefunden",
    },
    contextMenu: {
      sortAscending: "Aufsteigend sortieren",
      sortDescending: "Absteigend sortieren",
      resetWidth: "Auf Standardbreite zurücksetzen",
      removeColumn: "Spalte aus Liste entfernen",
      groupByColumn: "Nach Spalte gruppieren",
      openFilterWindow: "Benutzerdefinierter Filter",
      sum: "Summe",
      minimum: "Minimum",
      maximum: "Maximum",
      countAll: "Anzahl (alle)",
      countNotEmpty: "Anzahl (nicht leere)",
      mean: "Mittelwert",
    },
    filterOption: {
      startsWith: "Beginnt mit",
      endsWith: "Endet mit",
      includes: "Enthält",
      equalTo: "Gleich",
      larger: "Größer",
      smaller: "Kleiner",
      and: "Und",
      or: "Oder",
      laterOn: "Von",
      before: "Bis",
      include: "Gleich",
      ignoreCase: "Filterung Groß- und Kleinschreibung beachten",
      errorMessage: "Bitte geben Sie den richtigen Wert für den Filter ein",
      noFilterErrorMessage: "Bitte wählen Sie eine Filteroption, um fortzufahren.",
    },
    group: {
      title: "Gruppieren",
      closeAll: "ALLE ZUKLAPPEN",
      openAll: "ALLE AUFKLAPPEN",
    },
    saveView: {
      name: "Name der Ansicht",
      visibility: "Sichtbarkeit",
      filterSettings: "Filtereinstellungen",
      tableStructure: "Tabellenaufbau",
      grouping: "Gruppierung",
      favorite: "Filterung als Favo- rit hinzufügen",
      all: "Alle",
      onlyMe: "nur für mich",
      true: "wahr",
      false: "falsch",
    },
    selectView: {
      name: "Name",
      visibility: "Sichtweite",
      filter: "Filter",
      grouping: "Gruppierung",
      column: "Säule",
      favourite: "Favorit",
      noData: "Keine Ansicht verfügbar",
      selectView: "Ansicht auswählen",
      shareView: "Ansicht teilen",
      deleteView: "Ansicht löschen",
      editView: "Ansicht ändern",
      deleteViewConfirmation: "Möchten Sie diese Ansicht wirklich löschen?",
    },
    shareView: {
      select: "Wählen",
      remove: "Entfernen",
      selectAll: "Wählen Sie Alle",
      removeAll: "Alles Entfernen",
      cancel: "Stornieren",
      share: "Teilen",
      name: "Name",
      noData: "Keine Benutzer verfügbar",
    },
    removeColumnConfirmation: "Durch Ausblenden der Spalte werden auch alle zugehörigen Filter / Gruppierungen entfernt. Möchten Sie fortfahren?",
    hideLineFilterConfirmation: "Durch Ausblenden des Zeilenfilters werden auch die für eine Spalte hinzugefügten Zeilenfilter gelöscht. Möchten Sie fortfahren?",
    selectionMode: "AUSWAHLMODUS",
    gridContextMenu: {
      delete: "Löschen",
    },
    tooltip: {
      noFavView: "Keine Lieblingsansichten",
      expandRow: "Zeile erweitern",
      collapseRow: "Zeile reduzieren",
    },
  },
  positionGrid: {
    title: "Positionen",
    columns: {
      amountSatellites: "Sat-Anzahl",
      odometer: "Kilometerzähler",
      odometerDiff: "Kilometerzähler-Differenz",
      speed: "Geschwindigkeit",
      startEnd: "Zustand-Ende",
      startStart: "Zustand-Start",
      state: "Zustand",
      timespan: "Zeitspanne",
      timeStamp: "Zeitstempel",
      street: "Straße",
      city: "Stadt",
      gasLevel: "Gas-Füllstand",
      latitude: "Breitengrad",
      longitude : "Längengrad",
    },
  },
  carrierGrid: {
    title: "Träger",
    columns: {
      name: "Name",
      license: "Lizenz",
      licenseExpiryDate: "Ablaufdatum-der-Lizenz",
    },
  },
  driverGrid: {
    title: "Treiber",
    columns: {
      driverGuid: "DriverGuid",
      name: "Name",
      surName: "Familien-oder Nachname",
      personalId: "Personalausweis",
      driverCardId: "DriverCardId",
      active: "Aktiv",
    },
  },
  orderGrid: {
    title: "Aufträge",
    columns: {
      name: "Name",
      pickupdate: "Abholdatum",
      pickupcity: "Stadt abholen",
      expectedDeliverDate: "Voraussichtliches Lieferdatum",
      status: "Status",
      truckNumber: "LKW Nummer",
      numberOfShipment: "Anzahl der Sendungen",
      perishable: "Verderblich",
      receiver: "Empfänger",
      receptionCity: "Empfangsstadt",
    },
  },
  // To-Do: Confirm the columns and update the verbiage.
  messageGrid: {
    title: "Messages",
    columns: {
      sender: "Absender",
      recipient: "Empfänger",
      driverSender: "Treiber Sender",
      dateSent: "Datum gesendet",
      dateReceived: "Empfangsdatum",
      dateRead: "Datum gelesen",
      editor: "Editor",
      messageText: "Nachricht",
      timeStamp: "Zeitstempel",
      driverRecipient: "Empfänger des Fahrers",
    },
  },
};
