import styled from "styled-components";

export const ColumnHeader = styled.div`
  box-shadow: white -1px 0px inset;
  background-color: #efecf4;
  color: #43425d;
  font-weight: bold;
  font-size: 11px;
  padding: 0 10px;
  line-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  height: 30px;
`;

export const Input = styled.input`
  border-width: 0px;
  border-bottom: 2px solid #52378a94;
  margin-right: 1px;
  color: ${p => p.theme.text};
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: ${p => p.theme.text};
  background-color: ${p => p.theme.background};
  outline: none;
  height: 30px;
  padding: 0 10px;

  &::placeholder {
    font-weight: 300;
    color: ${p => p.theme.textSecondary};
  }

  &:focus {
    border-color: ${p => p.theme.primary};
  }
`;

export const ColumnHeaderContainer = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
`;

export const ColumnHeaderCellContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnLabel = styled.div`
  color: #43425d;
  font-weight: bold;
  font-size: 11px;
  line-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

export const DragIconContainer = styled.div`
  position: absolute;
  bottom: -9px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const ColumnActionArea = styled.div`
  height: 100%;
  display: inline-flex;
  align-items: center;
`;

export const AggregatedWrapper = styled.div`
  padding-left: 10px;
  color: #43425d;
  font-size: 12px;
  box-shadow: white -1px 0px inset;
  height: 40px;
`;
