import React, { useState } from "react";
import { TextArea } from "@blueprintjs/core";
import { DialogCloseReason, DialogView, DialogViewProps } from "./dialogView";

interface DialogTextAreaViewProps extends Omit<DialogViewProps, "onSubmit"> {
  onSubmit: (data?: string) => void;
  onClose: (reason: DialogCloseReason, data?: string) => void;
}

export const DialogTextAreaView = (props: DialogTextAreaViewProps) => {
  const { onSubmit, onClose, ...restProps } = props;
  const [data, setData] = useState<string>();

  const handleOnSubmit = () => {
    onSubmit(data);
    setData(undefined);
  };

  const handleOnClose = (reason: DialogCloseReason) => {
    onClose(reason, data);
  };

  return (
    <DialogView onClose={handleOnClose} onSubmit={handleOnSubmit} {...restProps}>
      <TextArea fill value={data} onChange={e => setData(e.target.value)} />
    </DialogView>
  );
};

// What if text area is empty and click on ok ?
