import React, { useCallback, useRef } from "react";
import { ConfirmationDialog, ConfirmationOptions } from "./confirmationDialog";

type ConfirmationServiceContextType = (options: ConfirmationOptions) => Promise<boolean>;

const ConfirmationServiceContext = React.createContext<ConfirmationServiceContextType>(Promise.reject);

export const useConfirmation = () => React.useContext(ConfirmationServiceContext);

export interface ConfirmationServiceProviderProps {
  children: React.ReactNode;
}

export const ConfirmationServiceProvider = ({ children }: ConfirmationServiceProviderProps) => {
  const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = useRef<{
    resolve: (state: boolean) => void;
    reject: () => void;
  }>();

  const openConfirmation = useCallback((options: ConfirmationOptions) => {
    setConfirmationState(options);
    return new Promise<boolean>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>
      {Boolean(confirmationState) && (
        <ConfirmationDialog open onClose={handleClose} onSubmit={handleSubmit} {...confirmationState} />
      )}
    </>
  );

  function handleClose() {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  }

  function handleSubmit() {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(true);
    }
    setConfirmationState(null);
  }
};
