import React, { useEffect, useState } from "react";
import {
  Position,
  PositionQueryOptions
} from "@aisalfaplan/troponic";
import {
  convertGeoMinSecToGeoDecimal,
  useNavSelection,
  useTranslator,
  useTroponic
} from "@ais/core";
import {
  AisIntent,
  LayerUsage,
  MapPosition as MapPositions,
  MarkerPopUp,
  MarkerToolTip,
  Spinner,
  SpinnerSize,
  WebMapContainer,
  WebMarker,
  WebPolyLine
} from "@ais/ui";
import { PositionGrid } from "@ais/grid";

import { TranslationResource } from "locales";
import { PageHeader } from "../../components/page-header/pageHeader";
import { PageContainer, WidgetCard } from "./common.styles";

export const PositionsView = () => {
  const troponic = useTroponic();
  const { t, translationKeys } = useTranslator<TranslationResource>();
  const { navigatorSelection } = useNavSelection();

  const [mapPositions, setMapPositions] = useState<MapPositions[]>([]);
  const [positions, setPositions] = useState<Position[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const carrierView = navigatorSelection?.carrier;
  const bounds: [number, number][] = [
    [44.5726, 8.4796],
    [60.5726, 17.4796],
  ];
  const props = {
    scrollWheelZoom: true,
    bounds,
  };

  // TODO handling null or no date should be tackled.

  const getPositions = async () => {
    setMapPositions([]);

    const carrierId = Number(navigatorSelection?.carrier?.resourceId ?? 0);
    const dt = navigatorSelection?.filter?.daterange ?? new Date();
    const queryOptions: PositionQueryOptions = {
      carrierId,
      startDate: new Date(dt.setUTCHours(0, 0, 0, 0)).toISOString(),
      endDate: new Date(dt.setUTCHours(23, 59, 59, 999)).toISOString(),
    };

    const feedResult = await troponic.Position.query({ queryOptions });
    setMapPositions(feedResult.results.map(pos => {
      const { gasLevel, height, latitude, longitude, resourceId, speed } = pos;
      return {
        lat: convertGeoMinSecToGeoDecimal(latitude),
        lng: convertGeoMinSecToGeoDecimal(longitude),
        alt: height,
        speed,
        gasLevel,
        resourceId,
      };
    }));
    setPositions(feedResult.results.map((p, index) => ({ ...p, rowId: index, lastUpdateDate: Date.now() })));
    setIsLoaded(true);
  };

  const toolTip = 0; // Would control display of tooltip or popup per client's choice

  useEffect(() => {
    setIsLoaded(false);
    getPositions();
  }, [navigatorSelection?.filter?.daterange, navigatorSelection?.carrier?.resourceId]);

  const carrierInfo = () => {
    const carrier = carrierView?.carrier;
    const device = carrierView?.devices[0];
    return carrier && (
      <>
        <div>Numberplate:{carrier.licencePlate} </div>
        <div>Version:{device?.deviceVersion}</div>
      </>
    );
  };

  const lastPosition = mapPositions[mapPositions.length - 1];

  return (
    <>
      <PageHeader headLine={t(translationKeys.aisweb.mapComponent)}>Filter</PageHeader>
      <PageContainer>
        <WidgetCard>
          <WebMapContainer {...props}>
            <LayerUsage>
              {mapPositions?.length > 0 ? (
                <>
                  <WebPolyLine polyOptions={{ color: "blue" }} positions={mapPositions.filter(p => Math.abs(p.lat) > 0.1 && Math.abs(p.lng) > 0.1)} />
                  <WebMarker icon="aisMapMarker" position={lastPosition}>
                    <>
                      {toolTip ? <MarkerToolTip> {carrierInfo()} </MarkerToolTip>
                        : <MarkerPopUp>{carrierInfo()}</MarkerPopUp>}
                    </>
                  </WebMarker>
                </>
              ) : null}
            </LayerUsage>
          </WebMapContainer>
          SelectedId: {navigatorSelection?.carrier?.carrier?.licencePlate}
        </WidgetCard>
        <WidgetCard>
          {!isLoaded ? (
            <Spinner
              colour={AisIntent.Primary}
              size={SpinnerSize.Small}
            />
          ) : <PositionGrid data={positions} />}
        </WidgetCard>
      </PageContainer>
    </>
  );
};
