import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import { useEventStoreContext, useTranslator } from "@ais/core";
import { FilterDropDown } from "@ais/ui";

import { TranslationResource } from "locales";
import { MessagesProvider } from "../../messagesContext";
import { MenuItem } from "../../components/filter/message/messageStyles";
import { PageHeader } from "../../components/page-header/pageHeader";
import { PageContainer, WidgetCard } from "./common.styles";

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChatSideLogo = (): ReactElement => {
  // TODO - Uncomment context below after the actual data comes from backend, for now the mock data exists.
  // const { messages } = useContext(MessageContext)
  const [message, setMessage] = useState<string|null>(null);
  const ChatMessage = (data: any) => {
    setMessage(data.messageText);
  };
  const { t, translationKeys } = useTranslator<TranslationResource>();
  const { onEvent } = useEventStoreContext();
  onEvent("ChatEvent", ChatMessage);
  return (
    <>
      <PageHeader headLine={t(translationKeys.aisweb.chatPage)}>Filter</PageHeader>
      <PageContainer>
        <WidgetCard>
          <MessagesProvider>
            <HeaderRow>
              {t(translationKeys.aisweb.chatComponent.total)}
              <MenuItem>
                <div>
                  <HeaderRow>
                    {t(translationKeys.aisweb.chatComponent.show)}:
                    <FilterDropDown />
                  </HeaderRow>
                </div>
              </MenuItem>
            </HeaderRow>
            <div>
              The new Message{message}
            </div>
          </MessagesProvider>
        </WidgetCard>
      </PageContainer>
    </>
  );
};
