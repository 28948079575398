import * as signalR from "@microsoft/signalr";

declare const events: {
  ChatEvent: "ChatEvent";
  OrderEvent: "OrderEvent";
};
export declare type EventType = typeof events[keyof typeof events];

export interface Subscription {
  Eventype: EventType;
  Tenants: string[];
}

export interface EventServerSettings {
  EventHub: string;
  onConnected?: () => Promise<void>;
  onConnectionLost?: () => Promise<void>;
  onReconnecting?: () => Promise<void>;
  onReconnected?: () => Promise<void>;
}

export class EventStore {
  // Index signature for handlers
  private handlers: { [eventType: string]: { instance?: any; callback: (data: any) => void }[] } = {};
  private readonly connection: signalR.HubConnection;
  private readonly settings: EventServerSettings;
  private readonly events:EventType[] = ["ChatEvent", "OrderEvent"];
  constructor(settings: EventServerSettings) {
    this.settings = settings;
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.settings.EventHub)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.startConnection();

    this.connection.onclose(async () => {
      // Connection Lost and would intimate the callee about this.
      settings.onConnectionLost?.();

      // Start reconnecting procedure
      await this.startConnection();
    });

    // Connection lost and retrying to establish connection
    this.connection.onreconnecting(async () => {
      settings.onReconnecting?.();
    });

    // Reconnected Successfully
    this.connection.onreconnected(async () => {
      settings.onReconnected?.();
    });

    this.events.forEach(event => {
      this.connection.on(event, this.onEventInternal.bind(this));
    });
  }

  public startConnection = async () => {
    try {
      await this.connection.start();

      // Callback to inform that signalR is connected
      this.settings.onConnected?.();
    } catch {
      // Swallow now
    }
  };
  // Internal dispatch methods
  public onEventInternal(data: any) {
    this.dispatch(data.eventType, data);
  }

  // Method used to attach an event to the callback function
  public onEvent = (eventName: EventType, callback: (data: any) => void, attachTo?: any) => {
    if (!this.handlers[eventName]) {
      this.handlers[eventName] = [];
    }
    // Register the event listener
    this.handlers[eventName].push({ instance: attachTo, callback });
  };

  // Used to dispatch event to all subscribers internally
  public dispatch = (eventName: EventType, data?: any): void => {
    // Push the callback if there is an event registered
    if (this.handlers[eventName]) {
      this.handlers[eventName].forEach(handler => handler.callback(data));
    }
  };

  // Subscribe to Event from the RealTime server
  public async subscribe(subscription: Subscription[]) {
    try {
      subscription.forEach(event => {
        this.connection.invoke("Subscribe", event);
      });
    } catch {
      // Silent
    }
  }
}
