import React, { memo } from "react";
import { CarrierView, CarrierViewDevice } from "@aisalfaplan/troponic";
import classNames from "classnames";
import { useTranslator } from "@ais/core";
import { TranslationResource } from "locales";
import { ImageAssets } from "../../assets/images/images";
import {
  CarrierContainer,
  CarrierInfo,
  ImageContainer,
  RoundedImage,
  Row,
  RowContent,
  TrafficBulp,
  TrafficLight
} from "./carrierItem.styles";

export interface CarrierItemProps {
  carrierView: CarrierView;
  onClick?: () => void;
  active?: boolean;
  skeleton?: string;
}

export const CarrierItem = memo(function CarrierItem(props: CarrierItemProps) {
  const { t, translationKeys } = useTranslator<TranslationResource>();
  // Test const hasDevice = (device: CarrierViewDevice): boolean => device?.deviceGuid ? true : false ?? false;
  const getDevice = (device: CarrierViewDevice): CarrierViewDevice | null => device?.deviceGuid ? device : null;
  const device = getDevice(props.carrierView?.devices[0]);
  const hasDriver = (props.carrierView.driver?.driverResourceId ?? 0) > 0;
  const driver = props.carrierView.driver;
  const onlineState: { class: string; trans: string } = device?.isOnline
    ? { class: "online", trans: translationKeys.common.online }
    : device != null
      ? { class: "offline", trans: translationKeys.common.offline }
      : { class: "unkown", trans: translationKeys.common.unbound };
  const icon = ImageAssets(props.carrierView.carrier?.typeId ?? 1);
  return (
    <CarrierContainer
      className={props.active ? "active" : ""}
      onClick={props.onClick}
    >
      <ImageContainer className={props.skeleton}>
        <RoundedImage src={icon} />
      </ImageContainer>
      <CarrierInfo>
        <Row>
          <RowContent className={classNames(props.skeleton, "status", onlineState.class ?? "unkown")}>
            {t(onlineState.trans)}
          </RowContent>
          <RowContent className={classNames(props.skeleton, "datetime")}>{device?.onlineSince}</RowContent>
        </Row>
        <Row>
          <RowContent className={classNames(props.skeleton, "carrierName")}>{props.carrierView.carrier?.licencePlate}</RowContent>
          <RowContent className={classNames(props.skeleton, "carrierName")}>({props.carrierView.carrier?.typeId})</RowContent>
        </Row>
        <Row>
          <RowContent className={classNames(props.skeleton, "licence")}>{props.carrierView.carrier?.mobileCarrierName}</RowContent>
        </Row>
        {hasDriver
          ? (
            <Row>
              <RowContent className={props.skeleton}>{t(translationKeys.common.driver, { driver })}</RowContent>
            </Row>
          ) : null}
      </CarrierInfo>
      <TrafficLight className={props.skeleton}>
        <TrafficBulp className={classNames("red")} />
        <TrafficBulp className={classNames("yellow")} />
        <TrafficBulp className={classNames("green")} />
      </TrafficLight>
    </CarrierContainer>
  );
});
