import styled from "styled-components";
import { Button, IButtonProps, InputGroup } from "@blueprintjs/core";

export const CustomInput = styled(InputGroup)`
  &.expandable {
    transition-property: width;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
    min-width: 30px;

    .bp3-input {
      transition-property: padding-left, padding-right;
      transition-duration: 200ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    .bp3-input + .bp3-input-action {
      transition-property: transform;
      transition-duration: 100ms;
      transition-delay: 20ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transform: scaleX(1);
      transform-origin: 100% 50%;
    }

    &:not(:focus-within) {
      width: 30px;

      .bp3-input {
        padding-right: 0px !important;
      }

      .bp3-input-action {
        transform: scaleX(0);
      }

      .bp3-icon {
        pointer-events: none;
      }
    }
  }
`;

export interface ClearButtonProps extends IButtonProps {
  $hidden: boolean;
}

export const ClearButton = styled(Button)<ClearButtonProps>`
  visibility: ${props => props.$hidden ? "hidden" : "visible"};
`;
