import React, { ComponentType } from "react";
import Scrollbars from "react-custom-scrollbars";
import { ListChildComponentProps, VariableSizeList, VariableSizeListProps } from "react-window";

export interface FixedListWrapperProps extends Omit<VariableSizeListProps, "children"> {
  ItemRenderer: ComponentType<ListChildComponentProps>;
}

export interface ScrollableProps extends FixedListWrapperProps {
  onListScroll: (target: HTMLElement) => void;
  onListScrollStart: () => void;
  onListScrollEnd: () => void;
  children?: React.ReactChild | React.ReactFragment | null;
  autoHeightMax?: number | string;
}

const VariableSizeListWrapper = (props: FixedListWrapperProps & { forwardedRef: React.Ref<any> }) => {
  const { ItemRenderer, style, forwardedRef, ...rest } = props;

  return (
    <VariableSizeList {...rest} ref={forwardedRef} style={{ overflow: "unset" }}>
      {ItemRenderer}
    </VariableSizeList>
  );
};

const ForwaredVariableSizeList = React.forwardRef((props: FixedListWrapperProps, ref) => (
  <VariableSizeListWrapper {...props} forwardedRef={ref} />
));

export const Scrollable = (
  props: ScrollableProps & { forwardedRef: React.MutableRefObject<VariableSizeList | undefined> }
) => {
  const { autoHeightMax, onListScroll, children, onListScrollStart, onListScrollEnd, forwardedRef, ...rest } = props;

  return (
    <Scrollbars
      autoHeight
      hideTracksWhenNotNeeded
      autoHeightMax={autoHeightMax}
      thumbMinSize={50}
      onScroll={handleScroll}
      onScrollStart={handleScrollStart}
      onScrollStop={handleScrollStop}
    >
      {children}
      <ForwaredVariableSizeList {...rest} ref={forwardedRef} />
    </Scrollbars>
  );

  function handleScroll(event: React.UIEvent) {
    const { target } = event;
    const { scrollTop } = target as HTMLElement;
    forwardedRef.current?.scrollTo(scrollTop);
    onListScroll(target as HTMLElement);
  }

  function handleScrollStart() {
    return onListScrollStart;
  }

  function handleScrollStop() {
    return onListScrollEnd;
  }
};
