import React, { useState } from "react";
import { Classes, TextArea } from "@blueprintjs/core";
import classNames from "classnames";
import { Button } from "../button/button";

import { Icon } from "../icon/icon";
import {
  ChatFrom,
  ChatInfo,
  ChatReplyButton,
  ChatTimeStamp,
  ChatTitle,
  ChatWindowBody,
  ChatWindowFooter,
  ChatWindowView
} from "./chatWindowView.styles";

export interface StackWindowItem {
  body: string;
  title: string;
  footer: string;
}
export interface StackWindowProps<T extends StackWindowItem> {
  title: string;
  open?: boolean;
  items: T[];
  onClose: (item: T) => void;
}

export const StackWindow = <T extends StackWindowItem>(props: StackWindowProps<T>) => {
  const { title, items, onClose } = props;
  const [entities, setEntities] = useState<T[]>(items);
  const classes = classNames(
    Classes.CARD,
    Classes.ELEVATION_4
  );
  if (entities.length < 1) {
    return null;
  }
  const current = entities[0];

  const handleAcknowledge = () => {
    onClose(current);
    setEntities(entities.slice(1));
  };

  return (
    <ChatWindowView>
      <ChatWindowBody className={classes}>
        <ChatTitle>
          <h3>{title} ({entities.length})</h3>
          <Icon icon="aisClose" iconSize={12} onClick={handleAcknowledge} />
        </ChatTitle>
        <div>
          <ChatFrom>
            <TextArea disabled value={`From: ${current.title}`} />
          </ChatFrom>
          <ChatInfo>
            <TextArea disabled fill growVertically={false} value={current.body} />
            <ChatTimeStamp>{current.footer}</ChatTimeStamp>
          </ChatInfo>
        </div>
        <ChatWindowFooter>
          <ChatReplyButton text="Reply" />
          <Button text="Ok" onClick={handleAcknowledge} />
        </ChatWindowFooter>
      </ChatWindowBody>
    </ChatWindowView>
  );
};
