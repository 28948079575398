import React, { useEffect, useMemo, useRef, useState } from "react";
import _, { isEmpty } from "lodash";
import { VariableSizeList } from "react-window";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import {
  ActionType,
  Cell,
  CellProps,
  CellValue,
  HeaderProps,
  Hooks,
  IdType,
  Column as RTColumn,
  Row,
  TableState,
  useAbsoluteLayout,
  useColumnOrder,
  useExpanded,
  useFilters,
  useGroupBy,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import { useTranslator, useUserProfile } from "@ais/core";
import { ContextMenu, MenuItem, Tooltip, useLocalStorage } from "@ais/ui";

import { TranslationResource } from "locales";
import {
  BaseRow,
  CellUpdatable,
  EditableColumnWithStrictAccessor,
  PersistedViewConfig,
  ViewConfigurations
} from "types";
import {
  FilterConfigurations,
  ResourceFilterSet,
  applyFilter,
  customVisibleColumnsHooks,
  getDefaultView,
  getToggleAllRowsSelectedProps,
  resolveColumnMovable,
  resolveCurrentView,
  resolveFilters,
  resolveViewConfig,
  storeView,
  storeViewConfig
} from "../../utils";

import {
  EditModeToggle,
  FavouriteView,
  HeaderColumn,
  HeaderItem,
  RowItem,
  Scrollable
} from "../../components";

import { GridIcon, GridMenu } from "../../styles/global";
import { CogMenu } from "./cogMenu";

import {
  Button,
  ButtonContainer,
  CogMenuWrapper,
  ColumnHeader,
  GridArea,
  GridAreaWrapper,
  GridWrapper,
  GroupAreaLabel,
  GroupAreaWrapper,
  GroupExpandLink,
  GroupExpandLinkText,
  GroupItem,
  GroupItemDeleteIcon,
  HeaderGroupContainer,
  HeaderWrapper,
  RightContainer,
  RightItemSeparator,
  SelectedViewName,
  SelectionModeSwitch,
  Title,
  Wrapper,
  WrapperCard
} from "./grid.styles";

export interface FilterProps {
  id: string;
  value: FilterConfigurations;
}
export interface GridProps<D extends BaseRow> {
  columnResizable?: boolean;
  editable?: boolean;
  enableViews?: boolean;
  filterable?: boolean;
  /**
   * This key should same as the key used in translation files
   */
  gridKey: keyof TranslationResource;
  groupable?: boolean;
  initialData: D[];
  moveableColumns?: boolean;
  selectionMode?: boolean;
  title?: string;
  updates?: D[];
  columnConfig: EditableColumnWithStrictAccessor<D>[];
}

const FilterIcon = () => {
  return null;
};

/**
 * Function to filter data based on user defined configurations. The predicate function is resolve based on the
 * FilterConfigurations object and the resolved predicate is called with specific filter and query string.
 *
 * @param rows The data rows to run filter function.
 * @param columnIds As per react-table, the query string will be stored inside columnIds[0]'th location.
 * @param configurations The filter configurations to resolve filter predicate function.
 */
const filter = <D extends BaseRow>(rows: Row<D>[], columnIds: IdType<D>[], configurations: FilterConfigurations) => {
  const filters = resolveFilters(configurations); // TODO: This should be memoized as passed as parameter
  const filteredRows = rows.filter(row => {
    const text = row.values[columnIds[0]];
    return applyFilter(filters, text);
  });
  return filteredRows;
};

/**
 * Returns columns ids to hide.
 * @param columnConfig
 */
function getHiddenColumns<D extends BaseRow>(columnConfig: EditableColumnWithStrictAccessor<D>[], columnOrders: string[]) {
  let columnsToHide: string[] = [];
  if (columnOrders && columnOrders.length > 0) {
    columnsToHide = columnConfig
      .filter(c => !((columnOrders ?? []) as string[]).includes(c.accessor as string))
      .map(c => c.accessor as string);
  }

  return columnsToHide;
}

/**
 * The Editable Grid.
 * @param props The Grid Props
 */
export function Grid<D extends BaseRow>(props: GridProps<D>) {
  const {
    columnConfig,
    columnResizable = false,
    editable = false,
    enableViews = false,
    filterable = false,
    gridKey,
    groupable = false,
    initialData,
    moveableColumns = false,
    selectionMode = false,
    title,
    updates = [],
  } = props;

  const localStorage = useLocalStorage();
  const user = useUserProfile();
  const { t, translationKeys } = useTranslator<TranslationResource>();

  const [data, setData] = useState<D[]>([]);
  const [appliedView, setAppliedView] = useState<PersistedViewConfig<D>>(() => resolveCurrentView<D>(localStorage, gridKey));
  const [isSelectionMode, setIsSelectionMode] = useState(selectionMode);
  const [showFilter, setShowFilter] = useState(filterable);
  const [columnsMovable, setColumnsMovable] = useState(() => moveableColumns ? resolveColumnMovable<D>(localStorage, gridKey) : false);
  const [lastViewUpdates, setLastViewUpdates] = useState(Date.now());

  const headerRef = useRef<HTMLDivElement>(null);
  const gridAreaRef = useRef<HTMLDivElement>(null);
  const localUpdates = useRef<D[]>([]);
  const editUpdates = useRef<Map<number, CellUpdatable<D>>>(new Map());
  const isTableStateChanging = useRef<boolean>(false);
  const isTableScrolling = useRef<boolean>(false);
  const isDragActive = useRef<boolean>(false);
  const stateRef = useRef<TableState<D>>();
  const rowHeights = useRef<Map<string, number>>(new Map());
  const variableListRef = useRef<VariableSizeList>();
  const isEditMode = useRef<boolean>(false);
  const isAllRowsExpandedRef = useRef<boolean>(false);
  const stateChangeTimer = useRef<number | null>(null);

  const initialHiddenColumns = getHiddenColumns(columnConfig, appliedView?.states.columnOrders ?? []);
  const columnLength = 154;
  const defaultColumn = useMemo(() => ({ minWidth: 100, width: columnLength }), [columnLength]);

  const columns = useMemo<RTColumn<D>[]>(() => {
    const configs: RTColumn<D>[] = columnConfig.map(config => ({
      ...config,
      Filter: FilterIcon,
      defaultCanFilter: true,
      filter,
    }));
    if (isSelectionMode) {
      configs.unshift({
        id: "selection",
        accessor: "selection",
        Filter: FilterIcon,
        defaultCanFilter: false,
        filter,
        // eslint-disable-next-line @typescript-eslint/naming-convention, react/no-unstable-nested-components
        Header: ({ getToggleAllPageRowsSelectedProps }: HeaderProps<D>) => {
          const { checked, onChange } = getToggleAllPageRowsSelectedProps();
          return (
            <ColumnHeader>
              <SelectionModeSwitch checked={checked} onChange={onChange} />
            </ColumnHeader>
          );
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention, react/no-unstable-nested-components
        Cell: ({ row }: CellProps<D>) => {
          const { checked, onChange } = row.getToggleRowSelectedProps();
          return <SelectionModeSwitch checked={checked} onChange={onChange} />;
        },
      });
    }
    return configs;
  }, [JSON.stringify(columnConfig), isSelectionMode]);

  // TODO: create a separate reducer file.
  function stateReducer<D extends BaseRow>(newState: TableState<D>, action: ActionType, previousState: TableState<D>) {
    if (action.type === "resetWidth") {
      return {
        ...previousState,
        columnResizing: {
          ...previousState.columnResizing,
          columnWidths: {
            ...previousState.columnResizing.columnWidths,
            [action.columnId]: previousState.columnResizing.columnWidth,
          },
        },
      } as TableState<D>;
    } else if (action.type === "columnAggregate") {
      const aggregateColumns = previousState?.aggregateColumns?.filter(c => c.id !== action.columnId) ?? [];
      if (action.apply) {
        aggregateColumns.push({ id: action.columnId, operation: action.operation });
      }
      return {
        ...previousState,
        aggregateColumns: [...aggregateColumns],
      } as TableState<D>;
    }
    return newState;
  }

  const table = useTable<D>(
    {
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetGroupBy: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      columns,
      data,
      disableGroupBy: !groupable,
      disableFilters: !filterable,
      disableResizing: !columnResizable,
      defaultColumn,
      initialState: { // TODO: Expose this as a prop if we need to have a initial state of the grid
        sortBy: [],
        filters: appliedView?.states.filters ?? [],
        groupBy: appliedView?.states.groups ?? [],
        hiddenColumns: ["selection", ...initialHiddenColumns],
        selectedRowIds: {} as Record<IdType<D>, boolean>,
      },
      selectSubRows: true,
      stateReducer,
    },
    useColumnOrder,
    useAbsoluteLayout,
    useResizeColumns,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    // Custom hooks to maintain column ordering
    (hooks: Hooks<D>) => {
      hooks.visibleColumns.push(customVisibleColumnsHooks(columns.map(c => c.accessor) as string[]));
      hooks.getToggleAllPageRowsSelectedProps = [getToggleAllRowsSelectedProps];
    }
  );

  const {
    allColumns,
    dispatch,
    headerGroups,
    isAllRowsExpanded,
    rows,
    state,
    totalColumnsWidth,
    visibleColumns,
    onlyGroupedFlatRows,
    selectedFlatRows,
    filteredRows,
    toggleAllRowsExpanded,
    toggleAllRowsSelected,
    getTableProps,
    prepareRow,
    setColumnOrder,
    setFilter,
    setGroupBy,
    setAllFilters,
    setHiddenColumns,
    setSortBy,
  } = table;

  stateRef.current = state;

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  useEffect(() => {
    const currentView = resolveCurrentView(localStorage, gridKey);
    const hiddenColumns = getHiddenColumns(columnConfig, currentView?.states.columnOrders ?? []);
    const columnOrder = currentView?.states.columnOrders ?? [];
    setHiddenColumns(!isSelectionMode ? ["selection", ...hiddenColumns] : hiddenColumns);
    setColumnOrder(isSelectionMode ? ["selection", ...columnOrder] : columnOrder);
    setAllFilters(currentView?.states.filters ?? []);
    setSortBy(currentView?.states.sortBy ?? []);

    setAppliedView(currentView);
  }, [localStorage]);

  useEffect(() => {
    // Applied view name is blank, so lets use that name to decide whether currently view is user defined or not
    if (appliedView && isEmpty(appliedView?.name)) {
      appliedView.states = {
        filters: stateRef.current?.filters ?? [],
        groups: stateRef.current?.groupBy ?? [],
        columnOrders: getPersistableColumns(),
        sortBy: stateRef.current?.sortBy ?? [],
      };
      storeView<D>(localStorage, gridKey, appliedView);
    } else if (appliedView && appliedView.owner === user.id) {
      // We are changing a specific view, so lets update that only if current user is the owner...
      const savedView = resolveViewConfig<D>(localStorage, gridKey) ?? { configurations: [] };

      savedView.configurations = [
        ...savedView.configurations.filter(c => c.name.localeCompare(appliedView?.name) !== 0),
        {
          ...appliedView,
          states: {
            ...appliedView.states,
            filters: stateRef.current?.filters ?? [],
            groups: stateRef.current?.groupBy ?? [],
            columnOrders: getPersistableColumns(),
          },
        },
      ];
      storeViewConfig<D>(localStorage, gridKey, savedView);
    }
  }, [stateRef.current]);

  const restDefaultView = () => {
    const nextView = getDefaultView<D>();
    if (isSelectionMode) {
      setHiddenColumns([]);
    } else {
      setHiddenColumns(["selection"]);
    }
    setColumnOrder([]);
    setAllFilters([]);
    setGroupBy([]);
    storeView<D>(localStorage, gridKey, nextView);
    setAppliedView(nextView);
  };

  useEffect(() => {
    /**
     * Perform batch update of table data after each 5 seconds.
     */
    const t = setInterval(() => {
      if (!(isTableStateChanging.current || isTableScrolling.current || isDragActive.current || isEditMode.current) && (localUpdates.current.length > 0 || editUpdates.current.size > 0)) {
        setData(currentState => {
          localUpdates.current.forEach(d => {
            const index = currentState.findIndex(c => c.rowId === d.rowId);
            if (index > -1) {
              currentState[index] = d;
            } else {
              currentState.push(d);
            }
          });
          const newState: D[] = [];
          Object.assign(newState, currentState);
          localUpdates.current = [];
          return newState;
        });
      }
    }, 1000 * 5);

    return () => clearInterval(t);
  }, []);

  useEffect(() => {
    // Collects data for batch updates...
    localUpdates.current.push(...updates);
  }, [updates]);

  function handleViewSelect<D extends BaseRow>(view: PersistedViewConfig<D>) {
    setAllFilters([]);
    view.states.filters?.forEach(f => {
      setFilter(f.id, f.value);
    });
    if (view.states.groups) {
      setGroupBy(view.states.groups);
    }
    if (view.states.sortBy) {
      setSortBy(view.states.sortBy);
    }
    const hiddenColumns = getHiddenColumns(columnConfig, view.states.columnOrders ?? []);
    const columnOrder = view.states.columnOrders ?? [];

    setHiddenColumns(!isSelectionMode ? ["selection", ...hiddenColumns] : hiddenColumns);
    setColumnOrder(isSelectionMode ? ["selection", ...columnOrder] : columnOrder);
    setAppliedView(view);
  }

  // C function onTableStateChangeEnd() {
  //   isTableStateChanging.current = false;
  // }

  function handleLineFilterChange(id: string, query: string) {
    const existingFilterSet = stateRef.current?.filters?.find(f => f.id === id) ?? {
      id,
      value: { filterSet: [], lineFilter: query },
    };
    existingFilterSet.value.lineFilter = query;

    setFilter(id, existingFilterSet.value);
  }

  function handleFilterChange(id: string, filterSet: ResourceFilterSet[]) {
    const existingFilterSet = stateRef.current?.filters?.find(f => f.id === id) ?? {
      id,
      value: { filterSet },
    };
    existingFilterSet.value.filterSet = filterSet;

    setFilter(id, existingFilterSet.value);
  }

  function handleRemoveColumn(id: string) {
    setFilter(id, { filterSet: [], lineFilter: "" });
  }

  useEffect(() => {
    isTableStateChanging.current = true;
    if (stateChangeTimer.current) {
      clearTimeout(stateChangeTimer.current);
    }
    // StateChangeTimer.current = setTimeout(onTableStateChangeEnd, 1000);
  }, [state]);

  useEffect(() => {
    if (isAllRowsExpandedRef.current) {
      toggleAllRowsExpanded(isAllRowsExpandedRef.current);
    }
  }, [isAllRowsExpanded, toggleAllRowsExpanded]);

  useEffect(() => {
    if (onlyGroupedFlatRows.length > 0) {
      if (!isAllRowsExpanded && onlyGroupedFlatRows.every(g => g.isExpanded)) {
        isAllRowsExpandedRef.current = true;
        toggleAllRowsExpanded(true);
      }
      if (isAllRowsExpanded && onlyGroupedFlatRows.every(g => !g.isExpanded)) {
        isAllRowsExpandedRef.current = false;
        toggleAllRowsExpanded(false);
      }
    }
  }, [state.expanded]);

  function handleOnScroll(target: HTMLElement) {
    const { scrollLeft } = target;
    headerRef.current?.scrollTo({ left: scrollLeft });
  }

  function handleScrollEnd() {
    isTableScrolling.current = false;
  }

  function handleScrollStart() {
    isTableScrolling.current = true;
  }

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      return (
        <RowItem<D>
          aggregateColumns={state.aggregateColumns ?? []}
          index={index}
          isEditing={isEditMode.current}
          isResizing={isTableStateChanging.current}
          prepareRow={prepareRow}
          row={rows[index]}
          style={style}
          toggleRowExpanded={handleToggleRowExpanded}
          onCellDataChange={onCellDataChange}
        />
      );
    },
    [prepareRow, rows, visibleColumns, state, rowHeights.current, isEditMode.current]
  );

  function onDragEnd(result: DropResult) {
    if (result.destination?.droppableId === "groupedArea") {
      setGroupBy(Array.from(new Set([...state.groupBy, result.draggableId])));
      isDragActive.current = false;
      return;
    }
    const colOrder = visibleColumns.map(o => o.id);
    const sIndex = result.source.index;
    const dIndex = result.destination?.index;

    if (typeof sIndex === "number" && typeof dIndex === "number" && sIndex !== dIndex) {
      colOrder.splice(sIndex, 1);
      colOrder.splice(dIndex, 0, result.draggableId);
      setColumnOrder(colOrder);
    }

    isDragActive.current = false;
  }

  function removeColFromGroupBy(id: string) {
    const groupedColumnsSet = new Set(state.groupBy);
    groupedColumnsSet.delete(id);
    setGroupBy(Array.from(groupedColumnsSet));
  }

  function handleColumnRemoveableCheck(id: string): boolean {
    const columnFilter = stateRef.current?.filters.find(f => f.id === id);
    const isGroupByColumn = (stateRef.current?.groupBy ?? []).includes(id);

    if (!columnFilter && !isGroupByColumn) {
      return true;
    }
    const filter = (columnFilter?.value as unknown) as FilterConfigurations;

    if (isGroupByColumn || !_.isEmpty(filter?.lineFilter) || (filter?.filterSet && filter?.filterSet.length !== 0)) {
      const decission = window.confirm(t(translationKeys.grid.removeColumnConfirmation));

      return decission;
    }

    return true;
  }

  function handleViewDelete() {
    setLastViewUpdates(Date.now());
  }

  function handleViewSave<D extends BaseRow>(viewState: PersistedViewConfig<D>, oldView?: ViewConfigurations) {
    setLastViewUpdates(Date.now());
    if (oldView?.name && oldView.name === appliedView.name) {
      setAppliedView(viewState);
    }
  }

  const renderHeader = React.useCallback(() => {
    const hasAggregatedColumn = visibleColumns.find(col => col.aggregate);
    const hasGroupedColumn = visibleColumns.find(col => col.isGrouped);
    const showAggregateRow = hasAggregatedColumn && !hasGroupedColumn;
    const height
      = showFilter && showAggregateRow ? 100 : showAggregateRow ? 65 : showFilter ? 60 : 30;
    return headerGroups.map(headerGroup => (
      // eslint-disable-next-line react/jsx-key
      <HeaderGroupContainer {...headerGroup.getHeaderGroupProps()} $height={height}>
        {headerGroup.headers.map((col, index) => {
          const column = col as HeaderColumn<D>;
          const existingFilters = stateRef.current?.filters?.find(f => f.id === column.id);

          return (
            <HeaderItem<D>
              key={column.id}
              aggregateColumns={state.aggregateColumns ?? []}
              column={column}
              columnOrder={stateRef.current?.columnOrder ?? []}
              columns={allColumns}
              columnsMovable={columnsMovable}
              defaultFilterConfigurations={existingFilters?.value ?? { filterSet: [] }}
              dispatch={dispatch}
              index={index}
              isColumnRemoveable={handleColumnRemoveableCheck}
              isDragActive={isDragActive.current}
              isGrouped={state.groupBy.length > 0}
              isResizing={isTableStateChanging.current}
              rows={rows}
              showFilter={showFilter}
              visibleColumns={visibleColumns}
              onFilterUpdate={handleFilterChange}
              onRemoveColumn={handleRemoveColumn}
              onSearchFilter={handleLineFilterChange}
            />
          );
        })}
      </HeaderGroupContainer>
    ));
  }, [headerGroups, columnsMovable, showFilter, state, rows]);

  // Const title = t(translationKeys.grid.title);

  function toggleAllGrouping() {
    const allExpanded = !isAllRowsExpandedRef.current;
    isAllRowsExpandedRef.current = allExpanded;
    toggleAllRowsExpanded(allExpanded);
  }

  function getItemSize(index: number) {
    const row = rows[index];
    if (row.isGrouped && state.aggregateColumns?.length > 0) {
      return rowHeights.current.get(row.id) ?? 60;
    }
    return 30;
  }

  const isAggregated = state.aggregateColumns?.length > 0;

  useEffect(() => {
    if (isAggregated && onlyGroupedFlatRows.length > 0) {
      const groupedRowHeights = new Map<string, number>();
      rows.forEach(row => {
        if (row.isGrouped) {
          groupedRowHeights.set(row.id, 60);
        }
      });
      rowHeights.current = groupedRowHeights;
    } else {
      rowHeights.current = new Map();
    }
    variableListRef.current?.resetAfterIndex(0);
  }, [rows, isAggregated, onlyGroupedFlatRows]);

  return (
    <Wrapper>
      <WrapperCard className="grid-card" elevation={3}>
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={() => {
            isDragActive.current = groupable;
          }}
        >
          <HeaderWrapper>
            {title && (
              <Title>
                {title} ({filteredRows.length})
              </Title>
            )}
            {groupable && (
              <Droppable direction="horizontal" droppableId="groupedArea">
                {droppableProvided => {
                  return (
                    <>
                      <GroupAreaLabel>{t(translationKeys.grid.group.title)}:</GroupAreaLabel>
                      <GroupAreaWrapper ref={droppableProvided.innerRef}>
                        {state.groupBy.map(columnId => {
                          const label = columnId;// Commented out: t(translationKeys.grid.columns[columnId as keyof ColumnNames]);
                          return (
                            <GroupItem key={columnId}>
                              {label}
                              <GroupItemDeleteIcon>
                                <GridIcon
                                  color="white"
                                  icon="cross"
                                  iconSize={12}
                                  onClick={() => removeColFromGroupBy(columnId)}
                                />
                              </GroupItemDeleteIcon>
                            </GroupItem>
                          );
                        })}
                        {droppableProvided.placeholder}
                        {state.groupBy.length > 0 && (
                          <GroupExpandLink onClick={toggleAllGrouping}>
                            <GridIcon
                              icon={isAllRowsExpandedRef.current ? "double-chevron-right" : "double-chevron-down"}
                              iconSize={12}
                            />
                            <GroupExpandLinkText>
                              {isAllRowsExpandedRef.current
                                ? t(translationKeys.grid.group.closeAll)
                                : t(translationKeys.grid.group.openAll)}
                            </GroupExpandLinkText>
                          </GroupExpandLink>
                        )}
                      </GroupAreaWrapper>
                    </>
                  );
                }}
              </Droppable>
            )}
            <RightContainer>
              {enableViews && (
                <>
                  <ButtonContainer>
                    <Button text={t(translationKeys.grid.defaultView)} onClick={restDefaultView} />
                  </ButtonContainer>
                  {!isEmpty(appliedView.name) && (
                    <Tooltip content={appliedView.name} position="bottom">
                      <SelectedViewName>{appliedView.name}</SelectedViewName>
                    </Tooltip>
                  )}
                  <FavouriteView<D>
                    currentView={appliedView}
                    gridKey={gridKey}
                    lastUpdates={lastViewUpdates}
                    onViewSelect={handleViewSelect}
                  />
                </>
              )}
              {editable && (
                <>
                  <RightItemSeparator />
                  <EditModeToggle isEdit={isEditMode.current} onChange={handleEditClick} />
                </>
              )}
              {selectionMode && (
                <>
                  <RightItemSeparator />
                  <SelectionModeSwitch
                    checked={isSelectionMode}
                    label={t(translationKeys.grid.selectionMode)}
                    onChange={handleSelectionModeChange}
                  />
                </>
              )}
            </RightContainer>
          </HeaderWrapper>
          <GridArea ref={gridAreaRef}>
            <GridAreaWrapper>
              <GridWrapper maxWidth={totalColumnsWidth} width={gridAreaRef.current?.clientWidth}>
                <div {...getTableProps()} className="table-container table-quadrant-stack">
                  <div ref={headerRef} className="table-top-container table-quadrant-stack">
                    {renderHeader()}
                  </div>
                  <div onContextMenu={showContextMenu}>
                    <Scrollable
                      ItemRenderer={RenderRow}
                      autoHeightMax={gridAreaRef.current?.clientHeight ? gridAreaRef.current.clientHeight - 30 : undefined}
                      forwardedRef={variableListRef}
                      height={650}
                      itemCount={rows.length}
                      itemSize={getItemSize}
                      overscanCount={50}
                      width={totalColumnsWidth}
                      onListScroll={handleOnScroll}
                      onListScrollEnd={handleScrollEnd}
                      onListScrollStart={handleScrollStart}
                    />
                  </div>
                </div>
              </GridWrapper>
              <CogMenuWrapper>
                <CogMenu<D>
                  appliedView={appliedView}
                  columns={allColumns}
                  enableViews={enableViews}
                  filterable={filterable}
                  gridKey={gridKey}
                  isColumnRemoveable={handleColumnRemoveableCheck}
                  moveableColumns={moveableColumns}
                  persistedColumn={getPersistableColumns()}
                  tableRef={stateRef.current}
                  onColumnMovableSelect={handleColumnsMovable}
                  onColumnRemove={handleRemoveColumn}
                  onColumnShow={(id: string) => {
                    setColumnOrder([...visibleColumns.map(vc => vc.id), id]);
                  }}
                  onDeleteView={handleViewDelete}
                  onSaveView={handleViewSave}
                  onShowFilterSelect={handleShowFilter}
                  onViewSelect={handleViewSelect}
                />
              </CogMenuWrapper>
            </GridAreaWrapper>
          </GridArea>
        </DragDropContext>
      </WrapperCard>
    </Wrapper>
  );

  function handleShowFilter(show: boolean) {
    setShowFilter(show);
  }

  function handleColumnsMovable(movable: boolean) {
    setColumnsMovable(movable);
  }

  function handleEditClick() {
    const editMode = isEditMode.current;
    if (editMode && (localUpdates.current.length > 0 || editUpdates.current.size > 0)) {
      setData(currentState => {
        localUpdates.current.forEach(d => {
          const index = currentState.findIndex(c => c.rowId === d.rowId);
          if (index > -1) {
            currentState[index] = d;
          }
        });
        editUpdates.current.forEach((cellUpdates, rowId) => {
          const index = currentState.findIndex(c => c.rowId === rowId);
          let currentRow = currentState[index];
          if (currentRow && cellUpdates.updates.size > 0) {
            let isDirty = false;
            cellUpdates.updates.forEach((value, columnId) => {
              const prevCellValue = currentRow[columnId as keyof D];
              if (value !== prevCellValue) {
                currentRow = { ...currentRow, [columnId]: value };
                isDirty = true;
              }
            });
            if (isDirty) {
              currentRow.lastUpdateDate = Date.now();
              currentState[index] = currentRow;
            }
          }
        });

        const newState: D[] = [];
        Object.assign(newState, currentState);
        localUpdates.current = [];
        editUpdates.current = new Map();
        return newState;
      });
    }
    isEditMode.current = !editMode;
  }

  function getPersistableColumns() {
    let columns = [];

    if ((stateRef.current?.columnOrder ?? []).length === 0) {
      // No column ordering taken place, hence just set visible columns only
      columns = visibleColumns.map(c => c.id);
    } else {
      // We need to honor the column ordering...
      const visibleColumnsIds = new Set(visibleColumns.map(c => c.id));
      columns = (stateRef.current?.columnOrder ?? []).filter(co => visibleColumnsIds.has(co));
    }

    return columns;
  }

  function handleSelectionModeChange(checked: boolean) {
    const selectionColumn = allColumns.find(c => c.id === "selection");
    const selectionMode = checked;
    selectionColumn?.toggleHidden(!selectionMode);
    const columnOrder = state.columnOrder.filter(c => c !== "selection");
    const finalColumns = columnOrder.length === 0 ? visibleColumns.map(c => c.id) : columnOrder;
    setColumnOrder(selectionMode ? ["selection", ...finalColumns] : finalColumns);
    setIsSelectionMode(selectionMode);
    if (!selectionMode) {
      toggleAllRowsSelected(false);
    }
  }

  function showContextMenu(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (isSelectionMode && selectedFlatRows.length > 0) {
      e.preventDefault();
      ContextMenu.show(
        <GridMenu>
          <MenuItem
            text={t(translationKeys.grid.gridContextMenu.delete)}
            onClick={() => {
              const updatedData = data.filter(d => !selectedFlatRows.some(s => s.original.rowId === d.rowId));
              toggleAllRowsSelected(false);
              setData(updatedData);
            }}
          />
        </GridMenu>,
        { left: e.clientX, top: e.clientY }
      );
    }
  }

  function onCellDataChange<T>(cell: Cell<D, T>, value: CellValue<T>) {
    const rowId = cell.row.original.rowId;
    const columnId = cell.column.id;
    const editUpdatesMap = editUpdates.current;
    const cellUpdatableMap = editUpdatesMap.get(rowId)?.updates ?? new Map<IdType<D>, T>();
    cellUpdatableMap.set(columnId, value);
    editUpdatesMap.set(rowId, { updates: cellUpdatableMap });
    editUpdates.current = editUpdatesMap;
  }

  function handleToggleRowExpanded() {
    if (isAllRowsExpandedRef.current) {
      isAllRowsExpandedRef.current = false;
    }
  }
}
