import React from "react";
import {
  LayerUsage,
  MarkerPopUp,
  MarkerToolTip,
  SetBoundsRectangles,
  WebClusterWrapper,
  WebMapContainer,
  WebMarker,
  WebPolyLine
} from "@ais/ui";
import { PageContainer, WidgetCard } from "./common.styles";

export const MapFunc = () => {
  const props = {
    zoom: 3,
    scrollWheelZoom: true,
    center: { lat: 22.5726, lng: -40.4796 },
  };

  const outerBounds: [number, number][] = [
    [22.5726, -40.4796],
    [24.5726, 40.4796],
  ];

  const positions = [
    {
      lat: 22.5726,
      lng: 88.3638,
      alt: 2,
    },
    {
      lat: 23.8401,
      lng: 23.8401,
      alt: 1,
    },
  ];

  return (
    <>
      <PageContainer>
        <WidgetCard>
          <WebMapContainer bounds={outerBounds} {...props}>
            <LayerUsage>
              {positions?.length > 0 ? (
                <>
                  <WebClusterWrapper>
                    <WebPolyLine polyOptions={{ color: "blue" }} positions={positions} />
                    <WebMarker drag isClickable icon="aisTruck" position={positions[positions.length - 1]}>
                      <>
                        <MarkerToolTip>23</MarkerToolTip>
                        <MarkerPopUp />
                      </>
                    </WebMarker>
                    <WebMarker drag isClickable icon="aisBell" position={positions[0]}>
                      <>
                        <MarkerToolTip>1997</MarkerToolTip>
                        <MarkerPopUp />
                      </>
                    </WebMarker>
                  </WebClusterWrapper>
                </>
              ) : null}
            </LayerUsage>
            <SetBoundsRectangles />
          </WebMapContainer>
        </WidgetCard>
      </PageContainer>
    </>
  );
};
