import { InputGroup } from "@blueprintjs/core";
import styled from "styled-components";

export const CardWidgetItemContainer = styled.div`
  display: column;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &.column {
    flex-direction: column;
  }
`;

export const CardWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 15px;
`;

export const CardWrapper2 = styled.div`
  width: 100%;
  flex: 1;
  margin: 2px;
  padding: 2px 5px 2px 5px;
  border: 1px solid #00000010;
  border-radius: 10px;
  box-shadow: 2px 2px 3px #00000029;

  &.active {
      background-color: grey;
  }
  &.selectable {
    cursor: pointer;
  }
  &.fixedCard {
    width: 200px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 4px;
  margin: 5px;
  padding: 15px 5px 5px 5px;
  cursor: pointer;
`;

export const SearchCard = styled(InputGroup)`
width: 100%;
`;
