import styled from "styled-components";

export const CarrierContainer = styled.div`
margin: 3px ;
padding: 2px;
  display: flex;
  width: 100%;
`;

export const ImageContainer = styled.div`
  float:left;
  display: block;
  height: 55px;
`;

export const RoundedImage = styled.img`
    border-radius: 10px;
    width: 100%;
    height: 100%;
    align-items: center;
`;

export const CarrierInfo = styled.div`
  float: left;
  width: 100%;
`;

export const Row = styled.div`
display: flex;
width: 100%;
padding: 0em;
margin: 0em;
`;

export const RowContent = styled.span`
margin-left: 5px;
margin-right: 5px;
font-size: 13px;
  &.status {
   font-size: 11px;
   border-radius: 4px;
   color: white;
   width: 60px;
   height: 15px;
   text-align: center;
   float:left;
   opacity: 1;
    &.online {
        background: #7FBF91 0% 0% no-repeat padding-box;
    }
    &.offline {
       background: red 0% 0% no-repeat padding-box;
    }
    &.unkown {
      background: #CCCCCC 0% 0% no-repeat padding-box;
    }
  }
   &.datetime {

  }
  &.carrierName {
    text-align: left;
    font: normal normal bold 16px/15px Calibri;
    letter-spacing: 0px;
    color: #505050;
    opacity: 1;
  }
`;

export const TrafficLight = styled.div`
  margin: 2px;
  background-color: #333333;
  width: 25px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3px 1px;
  border-radius: 3px;
`;
export const TrafficBulp = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: gray;
  &.red {
    background-color: red;
  }
  &.yellow {
    background-color: yellow;
  }
  &.green {
    background-color: green;
  }
`;
