export const Constants = {
  storage: {
    partitionKey: "partition",
  },
  persistence: {
    viewPartitionKey: "viewConfig",
    columnMovable: "columnMovable",
    currentView: "currentView",
    selectedLanguage: "selectedLanguage",
  },
};
