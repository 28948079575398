export type StoredMap = ReadonlyArray<[string, string]>;

export class StoragePartition {
  private readonly storage: Storage;
  private readonly partitionKey: string;
  private readonly data: Map<string, string>;

  constructor(storage: Storage, partition: string) {
    const partitionKey = `partition-${partition}`;
    const json = storage.getItem(partitionKey);
    const data = json ? JSON.parse(json) as StoredMap : [];

    this.storage = storage;
    this.data = new Map<string, string>(data);
    this.partitionKey = partitionKey;
  }

  public get length(): number {
    return Array.from(this.data.keys()).length;
  }

  public getItem(key: string): string | null {
    return this.data.get(key) ?? null;
  }

  public setItem(key: string, value: string): void {
    this.data.set(key, value);
    this.updateStorage();
  }

  public key(index: number): string | null {
    return Array.from(this.data.keys())[index] || null;
  }

  public removeItem(key: string): void {
    this.data.delete(key);
    this.updateStorage();
  }

  public clear(): void {
    this.data.clear();
    this.updateStorage();
  }

  private updateStorage() {
    const data: StoredMap = Array.from(this.data.entries());
    const json = JSON.stringify(data);
    this.storage.setItem(this.partitionKey, json);
  }
}
