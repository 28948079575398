import styled from "styled-components";
import { DateInput as BPDateInput, Dialog as BPDialog, Icon as BPIcon, Switch as BPSwitch, Button } from "@ais/ui";

export const Dialog = styled(BPDialog)`
  position: relative;
  width: max-content;
  border-radius: 20px;
  padding: 36px 40px 0px;
  background: #ffffff;
  .in-active {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const CloseIcon = styled(BPIcon)`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  background: ${props => props.theme.primary};
  color: #ffffff;
  border-radius: 100%;
  padding: 4px;
`;

export const Text = styled.div`
  color: ${props => props.theme.primary};
  font-weight: 600;
  padding: 4px 8px;
`;

export const ShadowBox = styled.div`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px ${props => props.theme.primary};
  padding: 4px;
`;

export const Actions = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin: 15px auto;
`;

export const Icon = styled(BPIcon)`
  color: ${props => props.theme.primary};
  cursor: pointer;
`;

export const SelectButton = styled(Button)`
  width: 130px;
  border-radius: 12px;
  border: 1px solid ${props => props.theme.primary};
  & .bp3-icon {
    margin-left: auto !important;
  }
  &:focus {
    outline: none;
  }
`;

export const Inline = styled.div`
  display: flex;
  margin: 8px;
`;

export const Input = styled.input`
  margin-left: 8px;
  border-radius: 12px;
  width: 130px;
  border: 1px solid ${props => props.theme.primary};
  &:focus {
    outline: none;
  }
`;

export const Switch = styled(BPSwitch)`
  margin-bottom: 0px;
  & .bp3-control-indicator {
    outline: none !important;
  }
  & input:checked ~ .bp3-control-indicator {
    background: ${props => props.theme.primary} !important;
  }
`;

export const DateInput = styled(BPDateInput)`
  input {
    margin-left: 8px;
    border-radius: 12px;
    width: 130px !important;
    border: 1px solid ${props => props.theme.primary};
    &:focus {
      outline: none;
    }
  }
  .bp3-datepicker-day-wrapper {
    padding: 0px;
  }
`;
