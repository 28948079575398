import React, { useEffect, useState } from "react";
import { CarrierView, Message } from "@aisalfaplan/troponic";
import { isNumber } from "lodash";
import { MessageGrid, TranslationResource } from "@ais/grid";
import { useNavSelection, useTranslator, useTroponic } from "@ais/core";
import { AisIntent, Spinner, SpinnerSize } from "@ais/ui";
import { PageHeader } from "../../components/page-header/pageHeader";
import { PageContainer } from "./common.styles";

export const MessageGridView = (): React.ReactElement => {
  const { t, translationKeys } = useTranslator<TranslationResource>();
  const { navigatorSelection } = useNavSelection();
  const troponic = useTroponic();

  const [carrier, setCarrier] = useState<CarrierView>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsMessageLoading] = useState(false);

  const fetchListOfMessages = async () => {
    setIsMessageLoading(true);

    // To-do: We need to query parameters to filter messages
    const feedResult = await troponic.Message.query({ feedOptions: { pageSize: 5 } });

    setMessages(feedResult.results);
    setIsMessageLoading(false);
  };

  const renderLoader = () => {
    return (
      <Spinner
        colour={AisIntent.Primary}
        size={SpinnerSize.Small}
      />
    );
  };

  useEffect(() => {
    fetchListOfMessages();
  }, []);

  useEffect(() => {
    setCarrier(navigatorSelection?.carrier ?? undefined);
  }, [navigatorSelection?.carrier]);

  const currentMobile = carrier?.devices[0]?.deviceResourceId;
  const filteredMessages = isNumber(currentMobile) ? messages.filter(m => m.sender === currentMobile || m.recipient === currentMobile) : messages;

  const headline = `${t(translationKeys.messageGrid.title)}`;

  return (
    <>
      <PageHeader headLine={headline} />
      <PageContainer>
        {isLoading
          ? renderLoader()
          : <MessageGrid data={filteredMessages} />}
      </PageContainer>
    </>
  );
};
