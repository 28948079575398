import React from "react";
import { LayerGroup, LayersControl } from "react-leaflet";
import { OpenMapTileLayer, OpenMapTileLayerBw } from "./tileLayers";

const { BaseLayer, Overlay } = LayersControl;

export interface OverLayProps{
  children?: React.ReactNode;
}

export const LayerUsage = (props:OverLayProps) => {
  return (
    <LayersControl>
      <BaseLayer checked name="OpenStreetMap">
        <OpenMapTileLayer />
      </BaseLayer>
      <BaseLayer name="Mapnik-BW">
        <OpenMapTileLayerBw />
      </BaseLayer>
      <Overlay checked name="Drawings">
        <LayerGroup>
          {props.children};
        </LayerGroup>
      </Overlay>
    </LayersControl>
  );
};
