import React from "react";
import { DialogCloseReason, DialogView, DialogViewProps } from "./dialogView";

interface DialogImageAreaViewProps extends Omit<DialogViewProps, "onSubmit"> {
  onSubmit: () => void;
  description: string;
  imagePath: string;
  imageName?: string;
}

export const DialogImageAreaView = (props: DialogImageAreaViewProps) => {
  const { onSubmit, onClose, description, imagePath, imageName, ...restProps } = props;

  const handleOnSubmit = () => {
    // TODO find a proper place for that :)
    const anchor = document.createElement("a");
    const extension = imagePath.split(".").pop();
    anchor.href = URL.createObjectURL(new Blob([imagePath], { type: "image/*}" }));
    anchor.download = imageName ?? "image." + extension ?? ".jpg";
    anchor.click();

    // Logic to save can be here or on callee ;
    onSubmit();
  };

  const handleOnClose = (reason: DialogCloseReason) => {
    onClose(reason);
  };

  return (
    <DialogView okIcon="floppy-disk" onClose={handleOnClose} onSubmit={handleOnSubmit} {...restProps}>
      <div>
        <div>{description}</div>
        <img src={imagePath} />
      </div>
    </DialogView>
  );
};
