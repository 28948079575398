import styled from "styled-components";
import { Icon as BPIcon, Menu as BPMenu, Tooltip as BPTooltip } from "@ais/ui";

export interface IconProps {
  open?: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-end;
  user-select: none;
`;
export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  user-select: none;
`;

export const Tooltip = styled(BPTooltip)``;

export const TooltipsContent = styled.div`
  background-color: ${props => props.theme.background};
`;

export const FabItem = styled.div`
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin: 5px;
`;

export const FavIconWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  height: 24px;
  width: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  outline: none;
`;

export const Icon = styled(BPIcon)<IconProps>`
  cursor: ${props => props.open ? null : "pointer"};
`;

export const FavouriteViewMenu = styled(BPMenu)`
  min-width: 200px;
  max-width: 300px;
  .bp3-menu-item {
    color: ${props => props.theme.primary};
    font-size: 11px;
    font-weight: bold;
    :focus {
      outline: none;
    }
  }

  .bp3-icon {
    color: ${props => props.theme.primary};
  }
`;
