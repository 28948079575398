import React from "react";
import { useTranslator } from "@ais/core";
import { TranslationResource } from "locales";
import { PageHeader } from "../../components/page-header/pageHeader";
import { PageContainer, WidgetCard } from "./common.styles";

export const ModuleSideLogo = (): React.ReactElement => {
  const { t, translationKeys } = useTranslator<TranslationResource>();
  return (
    <>
      <PageHeader headLine={t(translationKeys.aisweb.moduleComponent)}>Filter</PageHeader>
      <PageContainer>
        <WidgetCard>MainView</WidgetCard>
      </PageContainer>
    </>
  );
};
