import React from "react";
import styled from "styled-components";

const CustomTextArea = styled.textarea`
  width: 100%;
  height: 50%;
  max-width: 100%;
  max-height: 75%;
`;

export const TextArea = (props: {placeHolder: string; value: string}) => {
  return (
    <CustomTextArea placeholder={props.placeHolder} value={props.value} />
  );
};
