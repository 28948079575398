import i18next, { Resource, StringMap, TOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { i18nextPlugin as i18showTranslations } from "translation-check";

export type LanguageKey = "de" | "en" | "hi";

export type ConvertedToObjectType<T> = {
  [P in keyof T]: T[P] extends string ? string : ConvertedToObjectType<T[P]>;
};

export interface TranslationService<T> {
  readonly language: string;
  readonly translationKeys: ConvertedToObjectType<T>;
  changeLanguage: (lng: string) => Promise<void>;
  t: (key: string | string[], options?: string | TOptions<StringMap> | undefined) => string;
}

export interface TranslationSettings<T> {
  languages: { [key in LanguageKey]: T };
  showTranslations?: boolean;
}

export class AISTranslationService<T> implements TranslationService<T> {
  private readonly keys: ConvertedToObjectType<T>;

  constructor(settings: TranslationSettings<T>) {
    this.keys = {} as ConvertedToObjectType<T>;
    this.init(settings);
  }

  public get language(): string {
    return i18next.language;
  }

  public get translationKeys(): ConvertedToObjectType<T> {
    return this.keys;
  }

  public async changeLanguage(lng: string) {
    await i18next.changeLanguage(lng);
  }

  public t(key: string | string[], options?: string | TOptions<StringMap> | undefined) {
    return i18next.t(key, options);
  }

  private init(settings: TranslationSettings<T>) {
    const { languages, showTranslations = true } = settings;
    const translationsJson: Resource = {};
    Object.keys(languages)
      .forEach(key => translationsJson[key] = { translation: languages[key as LanguageKey] });

    i18next
      // Load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
      // learn more: https://github.com/i18next/i18next-http-backend
      // .use(Backend)
      // Detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // Pass the i18n instance to react-i18next.
      .use(initReactI18next);

    if (showTranslations) {
      i18next.use(i18showTranslations);
    }

    i18next.init(
      {
        resources: translationsJson,
        fallbackLng: "en",
        debug: true,

        interpolation: {
          escapeValue: false, // Not needed for react as it escapes by default
        },
      },
      () => this.convertLanguageJsonToObject(languages.en, this.keys)
    );
  }

  private convertLanguageJsonToObject(obj: any, dict: any, current?: string) {
    Object.keys(obj).forEach(key => {
      const currentLookupKey = current ? `${current}.${key}` : key;
      if (typeof obj[key] === "object") {
        dict[key] = {};
        this.convertLanguageJsonToObject(obj[key], dict[key], currentLookupKey);
      } else {
        dict[key] = currentLookupKey;
      }
    });
  }
}
