import { en as gridEn } from "@ais/grid";

export const en = {
  ...gridEn,
  common: {
    online: "online",
    offline: "offline",
    unbound: "unbound",
    driver: "driver: {{driver.driverName}}",
    coDriver: "co-driver: {{driver.driverName}}",
    search: "search",
  },
  user: {
    language: "language",
    changePassword: "Change password",
    logout: "Sign out",
  },
  aisweb: {
    dashboard: "Dashboard",
    aisComponent: "Ais - View",
    chatComponent: {
      total: "Total Values",
      show: "SHOW",
    },
    chartComponent: {
      title: "Chart - View",
      mainView: "Main View",
    },
    baseData: "base data",
    chatPage: "Chat",
    orderPage: "Orders",
    mapComponent: "Map - View",
    headerMapMessagesCount: "Total messages ({{amount, number}})",
    moduleComponent: "Module - View",
    securityComponent: "Security - View",
    jobsGreyComponent: "JobsGrey - View",
    jobsComponent: {
      dateOfDelivery: "Date of Delivery",
      sender: "Sender",
      recipient: "Recipient",
      goods: "Goods",
    },
  },
  i18nFeature: {
    selectLanguage: "Select Language",
    description: "Scalable apps need to support multiple languages, easily add and support multiple languages. Change the language below to see how instantly it updates the page without refreshing.",
  },
  loginPage: {
    welcomeMessage: "Welcome to AIS alfaplan!",
    invalidLoginMessage: "Invalid username or password.",
  },
  templates: {
    helloMessage: "Hello {{name, string}}! Hope you are doing good",
  },
  modal: {
    okButton: "Ok",
    cancelButton: "Cancel",
  },
};
