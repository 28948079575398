import {
  User,
  UserManager,
  WebStorageStateStore
} from "oidc-client";

export interface AccessTokenServiceSettings {
  scope: string;
  authority: string;
  clientId: string;
  authRedirectUrl: string;
  silentRedirectUrl: string;
  postLogoutRedirectUrl: string;
  responseType: string;
  clientSecret: string;
  changePasswordRoute: string;
  onIdentityChanged?: (identity: User | null) => Promise<void>;
}

export class AuthService {
  public readonly userManager: UserManager;
  public readonly settings: AccessTokenServiceSettings;
  public user?: User;

  constructor(settings: AccessTokenServiceSettings) {
    this.settings = settings;

    this.userManager = new UserManager({
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      response_mode: "query",
      authority: this.settings.authority,
      client_id: this.settings.clientId,
      redirect_uri: this.settings.authRedirectUrl,
      response_type: this.settings.responseType,
      scope: this.settings.scope,
      client_secret: this.settings.clientSecret,
      revokeAccessTokenOnSignout: true,
      automaticSilentRenew: true,
      silent_redirect_uri: settings.silentRedirectUrl,
      post_logout_redirect_uri: settings.postLogoutRedirectUrl,
    });

    this.userManager.events.addUserLoaded(this.loadIdentity);
    this.userManager.events.addUserSignedOut(this.handleSignOut);
  }
  public loadIdentity = async (): Promise<User | null> => {
    const identity = await this.userManager.getUser();

    if (identity) {
      await this.setIdentity(identity);
    }
    return identity;
  };

  public signInRedirect = async (): Promise<void> => {
    const redirectPath = window.location.pathname.includes("logout") ? "/" : window.location.pathname;
    await this.userManager.signinRedirect({
      state: redirectPath,
    });
  };

  public signInRedirectCallBack = async (): Promise<void> => {
    const identity = await this.userManager.signinRedirectCallback();
    await this.setIdentity(identity);
  };

  public silentRenewal = async (): Promise<User> => this.userManager.signinSilent();

  public silentCallBack = async (): Promise<void> => {
    try {
      await this.userManager.signinSilentCallback();
      const identity = await this.userManager.getUser();
      if (identity) {
        await this.setIdentity(identity);
      } else {
        this.signoutRedirect();
      }
    } catch {
      this.signoutRedirect();
    }
  };

  public signoutRedirect = async (): Promise<void> => {
    const userIdToken = (await this.userManager.getUser())?.id_token;
    this.userManager.clearStaleState();
    this.userManager.removeUser();
    this.userManager.signoutRedirect({
      id_token_hint: userIdToken,
    });
  };

  public setIdentity = async (identity: User | null): Promise<void> => {
    this.settings.onIdentityChanged?.(identity);
  };

  private handleSignOut = async (): Promise<void> => {
    await this.setIdentity(null);
  };
}
