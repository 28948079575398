import React, { memo } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import { Icon, IconName } from "../icon/icon";

interface ButtonExtensionProps {
  round?: boolean;
  icon?: IconName | Core.MaybeElement;
  iconSize?: number;

  /**
   * Collapses the button and expands on hover.
   * @default false
   */
  expandable?: boolean;
}

// We may need to work on this again
export interface ButtonProps extends ButtonExtensionProps, Omit<Core.ButtonProps, "icon" | "onClick">, React.ButtonHTMLAttributes<HTMLButtonElement> { }

export const Button = memo(function Button(props: ButtonProps) {
  return (
    <Core.Button {...toButtonNativeProps(props)} />
  );
});

// We may need to work on this again
export interface AnchorButtonProps extends ButtonExtensionProps, Omit<Core.AnchorButtonProps, "type" | "icon" | "onClick">, React.AnchorHTMLAttributes<HTMLAnchorElement> { }

export const AnchorButton = memo(function AnchorButton(props: AnchorButtonProps) {
  return (
    <Core.AnchorButton {...toAnchorButtonNativeProps(props)} />
  );
});

function toButtonNativeProps(props: ButtonProps): Core.ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement> {
  const { className, expandable, icon, iconSize, onClick, rightIcon, round, ...restProps } = props;

  const classes = classnames(className, {
    "bp3-round": round === true,
    expandable,
    "no-right-element": !rightIcon,
  });

  const nativeIcon: Core.MaybeElement | undefined = icon
    ? <Icon icon={icon} iconSize={iconSize} />
    : undefined;

  return {
    ...restProps,
    className: classes,
    onClick: (evt: React.MouseEvent) => onClick?.(evt as React.MouseEvent<HTMLButtonElement>),
    icon: nativeIcon,
    rightIcon,
  };
}

function toAnchorButtonNativeProps(props: AnchorButtonProps): Core.IAnchorButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement> {
  const { className, expandable, icon, onClick, rightIcon, round, type, ...restProps } = props;

  const classes = classnames(className, {
    "bp3-round": round === true,
    expandable,
    "no-right-element": !rightIcon,
  });

  const nativeIcon: Core.MaybeElement | undefined = icon
    ? <Icon icon={icon} />
    : undefined;

  return {
    ...restProps,
    className: classes,
    onClick: (evt: React.MouseEvent) => onClick?.(evt as React.MouseEvent<HTMLAnchorElement>),
    icon: nativeIcon,
    rightIcon,
  };
}
