import { Column, ColumnInstance, Meta, TableToggleAllRowsSelectedProps, UseGroupByColumnProps } from "react-table";

export const customVisibleColumnsHooks = <D extends object = {}>(defaultColumnOrder: string[]) => (
  columns: ColumnInstance<D>[],
  {
    instance: {
      state: { groupBy, columnOrder },
    },
  }: Meta<D>
): Column<D>[] => {
  const orderedColumns: ColumnInstance<D>[] = (columnOrder.length === 0 ? defaultColumnOrder : columnOrder).reduce(
    (acc: ColumnInstance<D>[], id: string) => {
      const c = columns.find(col => col.id === id);
      if (c) {
        acc.push(c);
      }

      return acc;
    },
    []
  );

  orderedColumns.forEach(column => {
    const groupedColumn = (column as unknown) as UseGroupByColumnProps<D>;
    groupedColumn.isGrouped = groupBy.includes(column.id);
    groupedColumn.groupedIndex = groupBy.indexOf(column.id);
  });

  return orderedColumns;
};

export function getToggleAllRowsSelectedProps<D extends object = {}>(
  props: Partial<TableToggleAllRowsSelectedProps>,
  { instance }: Meta<D>
): Partial<TableToggleAllRowsSelectedProps>[] {
  return [
    props,
    {
      onChange() {
        instance.toggleAllRowsSelected();
      },
      style: {
        cursor: "pointer",
      },
      checked: instance.isAllRowsSelected,
    },
  ];
}
