import React from "react";
import { HeadFilter, HeaderViewStyle, Headline, IconStyle } from "../common.styles";

interface PageHeaderProps {
  headLine: string;
  children?: React.ReactNode;
}

export const PageHeader = (props: PageHeaderProps) => {
  return (
    <HeaderViewStyle>
      <Headline>{props.headLine}</Headline>
      <IconStyle icon="aisSettings" />
      <IconStyle icon="aisReset" />
      <HeadFilter>{props.children}</HeadFilter>
    </HeaderViewStyle>
  );
};
