import styled from "styled-components";
import { Menu as BPMenu, Button } from "@ais/ui";

export const Menu = styled(BPMenu)`
  min-width: 0px;
`;

export const MenuButton = styled(Button)`
  :focus {
    outline: none;
  }
`;
