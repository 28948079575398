import styled from "styled-components";
import { Button } from "../button/button";

export const ChatWindowBody = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 400px;
  margin: 0px 0px 15px auto;
`;

export const ChatWindowFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ChatTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChatFrom = styled.div`
  margin: 10px;
`;

export const ChatInfo = styled.div`
  margin: 10px;
  border: solid;
  text-align-last: center;
`;

export const ChatWindowView = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 20;
`;

export const ChatReplyButton = styled(Button)`
  marginRight: 10px;
`;

export const ChatTimeStamp = styled.div`
  margin: 0px 0px 0px 150px;
`;
