import React, { useEffect, useRef, useState } from "react";
import { useTranslator, useUserProfile } from "@ais/core";
import { NonIdealState, Tooltip, useLocalStorage } from "@ais/ui";

import { TranslationResource } from "locales";
import { BaseRow, PersistedViewConfig, ViewConfigurations } from "types";
import { Debouncer, resolveViewConfig, storeViewConfig } from "../../../utils";
import { ActionCard } from "../../../components";
import { ShareView } from "./shareViewDialog";

import { SaveViewDialog } from "./saveViewDialog";
import {
  ActionContainer,
  CloseIcon,
  Container,
  ContainerBody,
  Dialog,
  HTMLTable,
  HeaderRow,
  Input,
  NameCell,
  RowItem,
  Switch,
  TableHeader,
  TableSwitchHeader
} from "./selectView.styles";

export interface SelectViewProps<D extends BaseRow> {
  isOpen: boolean;
  gridKey: keyof TranslationResource;
  onSelect: (view: PersistedViewConfig<D>) => void;
  onClose: () => void;
  onEditViewSelect: () => void;
  mode?: "edit" | "select";
  onEdit: (view: ViewConfigurations, oldView?: ViewConfigurations) => void;
  onDelete: (views: ViewConfigurations[]) => void;
}

export const SelectView = <D extends BaseRow>(props: SelectViewProps<D>) => {
  const { isOpen, gridKey, onEdit, mode = "edit", onClose } = props;
  const localStorage = useLocalStorage();
  const user = useUserProfile();
  const { t, translationKeys } = useTranslator<TranslationResource>();

  const [viewConfigurations, setViewConfigurations] = useState<PersistedViewConfig<D>[]>([]);
  const [filteredViewConfigurations, setFilteredViewConfigurations] = useState<PersistedViewConfig<D>[]>([]);
  const [selectedIndices, setSelectedIndices] = useState<Set<String>>(new Set());
  const [showShareViewDialog, setShowShareViewDialog] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const debouncer = useRef(new Debouncer(1000));
  const selectedIndicesRef = useRef(selectedIndices).current;

  useEffect(() => {
    const view = resolveViewConfig<D>(localStorage, gridKey);

    setViewConfigurations(view?.configurations ?? []);
  }, [isEditing]);

  useEffect(() => {
    debouncer.current?.delay(filterViewConfigurations);
  }, [searchInput, viewConfigurations]);

  const checkededViews = filteredViewConfigurations.filter(fc => selectedIndicesRef.has(fc.name));
  const visibleCount = checkededViews.length;
  const editable = checkededViews.every(fv => fv.owner === user.id);

  const isEmptyRecord = filteredViewConfigurations.length === 0;

  return (
    <Dialog canEscapeKeyClose canOutsideClickClose isOpen={isOpen} onClose={onClose}>
      <CloseIcon icon="cross" iconSize={20} onClick={onClose} />
      <Input large leftIcon="search" type="search" value={searchInput} onChange={handleInputChange} />
      <Container>
        <ContainerBody>
          <HTMLTable striped>
            <thead>{headerRenderer()}</thead>
            {isEmptyRecord
              ? emptyRenderer()
              : (
                <tbody>
                  {filteredViewConfigurations.sort((a, b) => a.name.localeCompare(b.name)).map(renderViewConfigurations)}
                </tbody>
              )}
          </HTMLTable>
        </ContainerBody>
        <ActionContainer>
          <ActionCard
            disabled={visibleCount !== 1}
            name={t(translationKeys.grid.selectView.selectView)}
            onClick={handleSelectView}
          />
          {mode === "edit" && (
            <>
              <ActionCard
                disabled={visibleCount === 0}
                name={t(translationKeys.grid.selectView.shareView)}
                onClick={openShareViewDialog}
              />
              <ActionCard
                disabled={!(visibleCount !== 0 && editable)}
                name={t(translationKeys.grid.selectView.deleteView)}
                onClick={handleViewDelete}
              />
              <ActionCard
                disabled={!(visibleCount === 1 && editable)}
                name={t(translationKeys.grid.selectView.editView)}
                onClick={() => setIsEditing(true)}
              />
            </>
          )}
        </ActionContainer>
      </Container>
      <ShareView<D>
        isOpen={showShareViewDialog}
        selectedViews={filteredViewConfigurations.filter(v => selectedIndicesRef.has(v.name) && v.owner === user.id)}
        onClose={hideShareViewDialog}
        onShare={handleShareView}
      />
      {isEditing && (
        <SaveViewDialog
          defaultViewConfig={filteredViewConfigurations.find(v => selectedIndicesRef.has(v.name))}
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onSave={(...args) => {
            onEdit(...args);
            setIsEditing(false);
          }}
        />
      )}
    </Dialog>
  );

  function hideShareViewDialog() {
    setShowShareViewDialog(false);
  }

  function openShareViewDialog() {
    setShowShareViewDialog(true);
  }

  function handleShareView() {
    setShowShareViewDialog(false);
  }

  function headerRenderer() {
    return (
      <HeaderRow>
        <TableSwitchHeader />
        <TableHeader>{t(translationKeys.grid.selectView.name)}</TableHeader>
        <TableHeader>{t(translationKeys.grid.selectView.visibility)}</TableHeader>
        <TableHeader>{t(translationKeys.grid.selectView.filter)}</TableHeader>
        <TableHeader>{t(translationKeys.grid.selectView.grouping)}</TableHeader>
        <TableHeader>{t(translationKeys.grid.selectView.column)}</TableHeader>
        <TableHeader>{t(translationKeys.grid.selectView.favourite)}</TableHeader>
      </HeaderRow>
    );
  }

  function emptyRenderer() {
    return (
      <tbody>
        <tr>
          <td colSpan={7}>
            <NonIdealState description={String(t(translationKeys.grid.selectView.noData))} />
          </td>
        </tr>
      </tbody>
    );
  }

  function handleInputChange(value: string) {
    setSearchInput(value);
  }

  function renderViewConfigurations(view: PersistedViewConfig<D>) {
    return (
      <RowItem key={view.name}>
        <td>
          <Switch checked={selectedIndicesRef.has(view.name)} onChange={(checked: boolean) => handleSwitchChange(checked, view.name)} />
        </td>
        <td>
          <Tooltip content={view.name}>
            <NameCell>{view.name}</NameCell>
          </Tooltip>
        </td>
        <td>{view.visibility}</td>
        <td>{String(view.filter.filter)}</td>
        <td>{String(view.filter.table)}</td>
        <td>{String(view.filter.grouping)}</td>
        <td>{String(view.filter.favourite)}</td>
      </RowItem>
    );
  }

  function handleSelectView() {
    const { onSelect, onClose } = props;
    const viewName = selectedIndicesRef.values().next().value;
    const view = filteredViewConfigurations.find(vc => vc.name === viewName);

    if (view) {
      onSelect(view);
      onClose();
    }
  }

  function handleViewDelete() {
    const { onDelete } = props;
    const viewsToRemove: String[] = [];

    selectedIndicesRef.forEach(viewName => {
      viewsToRemove.push(viewName);
    });

    let viewConfig = resolveViewConfig<D>(localStorage, gridKey);

    if (viewConfig) {
      onDelete(viewConfig.configurations.filter(c => viewsToRemove.includes(c.name)));

      viewConfig = {
        ...viewConfig,
        configurations: viewConfig.configurations.filter(c => !viewsToRemove.includes(c.name)),
      };
    }

    storeViewConfig<D>(localStorage, gridKey, viewConfig);

    setViewConfigurations(viewConfig?.configurations ?? []);
    selectedIndicesRef.clear();
    setSelectedIndices(selectedIndicesRef);
  }

  function handleSwitchChange(checked: boolean, viewName: string) {
    if (checked) {
      selectedIndicesRef.add(viewName);
    } else {
      selectedIndicesRef.delete(viewName);
    }
    setSelectedIndices(selectedIndicesRef);
  }

  function filterViewConfigurations() {
    const filteredViewConfig = viewConfigurations.filter(vc =>
      vc.name.toLowerCase().includes(searchInput.toLocaleLowerCase()));

    setFilteredViewConfigurations(filteredViewConfig);
  }
};
