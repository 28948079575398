import React from "react";
import { useTranslation, useTranslator, useUserProfile } from "@ais/core";
import { AisIcons, MenuView, MenuViewItem, PopoverControlledView, Svg } from "@ais/ui";

import { TranslationResource } from "locales";
import { AppConfig } from "../../common";
import { IconLink, ProfileContainer } from "./userProfileView.styles";

export const UserProfileView = () => {
  const user = useUserProfile();
  const { t, translationKeys } = useTranslator<TranslationResource>();
  const { changeLanguage } = useTranslation<TranslationResource>();
  const logoutPath = "signout-oidc-callback";
  const changepwPath = AppConfig?.accessTokenServiceSettings.authority + AppConfig.accessTokenServiceSettings.changePasswordRoute;
  const flag = (icon: string) => {
    return (
      <Svg height={20} src={icon} width={20} />
    );
  };

  const onChosen = (lang: string) => {
    changeLanguage(lang);
  };

  const userProfileMenu: MenuViewItem[] = [
    { id: "3", text: user.name ?? "-", disabled: true },
    { id: "0", divider: true, title: "a new divider" },
    {
      id: "4",
      text: t(translationKeys.user.language),
      subMenu: true,
      children: [
        { id: "de", icon: flag(AisIcons.de), text: "Deutsch (de)", onChosen },
        { id: "en", icon: flag(AisIcons.gb), text: "English (en)", onChosen },
        { id: "hi", icon: flag(AisIcons.in), text: "हिन्दी (hi)", onChosen },
      ],
    },
    { id: "2", text: t(translationKeys.user.changePassword), active: false, path: changepwPath },

    { id: "1", text: t(translationKeys.user.logout), active: false, path: logoutPath },
  ];
  const handleUserProfileClick = () => {};

  return (
    <ProfileContainer>
      <PopoverControlledView content={<MenuView items={userProfileMenu} />} onInteraction={handleUserProfileClick}>
        <IconLink icon="person" />
      </PopoverControlledView>
    </ProfileContainer>
  );
};
