import React from "react";
import { WebMapStyled } from "./webMapContainer.styles";

export interface WebMapProps {
  bounds?: [number, number][];
  scrollWheelZoom?: boolean;
  zoom?: number;
  // Center: { lat: number; lng: number };
  children?: React.ReactNode;
}

export type MapPosition = {
  lat: number;
  lng: number;
  alt?: number;
  resourceId?: string;
};

export const WebMapContainer = (props: WebMapProps) => {
  return (
    <WebMapStyled
      bounds={props.bounds}
      // Center={props.center}
      scrollWheelZoom={props.scrollWheelZoom}
      zoom={props.zoom}
    >
      {props.children}
    </WebMapStyled>
  );
};
