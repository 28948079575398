import styled from "styled-components/macro";
import { Checkbox, DateInput, InputGroup, NumericInput } from "@ais/ui";

export const InputField = styled(InputGroup)`
  background-color: inherit;
  padding: 0px;
  .bp3-input {
    background-color: inherit;
    box-shadow: none;
    color: #4d4f5c;
    font-size: 12px;
    border-radius: 0px;
    :focus {
      border-bottom: 1px solid ${p => p.theme.primary};
      box-shadow: none;
      border-radius: 0px;
      color: ${p => p.theme.primary};
    }
    padding: 0px;
    text-overflow: ellipsis;
    flex: 1;
    height: 30px;
  }
`;

export const NumericInputField = styled(NumericInput).attrs({
  buttonPosition: "none",
})`
  background-color: inherit;
  padding: 0px;
  .bp3-input {
    background-color: inherit;
    box-shadow: none;
    color: #4d4f5c;
    font-size: 12px;
    border-radius: 0px;
    :focus {
      border-bottom: 1px solid ${p => p.theme.primary};
      box-shadow: none;
      border-radius: 0px;
      color: ${p => p.theme.primary};
    }
    padding: 0px;
    text-overflow: ellipsis;
    flex: 1;
    height: 30px;
  }

  .bp3-input-group {
    width: 100%;
  }
`;

export const DateInputField = styled(DateInput).attrs({
  buttonPosition: "none",
})`
  background-color: inherit;
  padding: 0px;
  .bp3-input {
    background-color: inherit;
    box-shadow: none;
    color: #4d4f5c;
    font-size: 12px;
    border-radius: 0px;
    :focus {
      border-bottom: 1px solid ${p => p.theme.primary};
      box-shadow: none;
      border-radius: 0px;
      color: ${p => p.theme.primary};
    }
    padding: 0px;
    text-overflow: ellipsis;
    flex: 1;
    height: 30px;
  }

  .DayPicker-Body {
    border-top: 1px solid ${p => p.theme.primaryBackGround};
    border-left: 1px solid ${p => p.theme.primaryBackGround};
  }

  .DayPicker-Weekday {
    font-size: 10px;
    text-transform: uppercase;
  }

  .DayPicker-Day {
    width: 40px;
    :focus {
      outline: 0;
    }
    font-size: 12px;
    box-shadow: inset 0 -1px 0 ${p => p.theme.primaryBackGround}, inset -1px 0 0 ${p => p.theme.primaryBackGround};
    border-radius: 0px;
  }

  .DayPicker-Day--selected {
    background-color: ${p => p.theme.primary} !important;
    border-radius: 0px !important;
  }

  .bp3-icon-double-caret-vertical {
    display: none;
  }

  .DayPicker-NavBar {
    padding-top: 5px;
    .bp3-icon {
      color: ${p => p.theme.primary};
    }
  }

  .bp3-html-select {
    select {
      font-size: 12px;
      color: ${p => p.theme.primary};
    }
  }
  .bp3-datepicker-caption {
    padding-top: 2px;
    margin: 0 25px 0px;
  }
  .bp3-timepicker-input-row {
    height: 60px;
    line-height: 48px;
    font-size: 24px;
    box-shadow: none;
    display: flex;
    align-items: center;
    color: ${p => p.theme.primary};
  }
  .bp3-timepicker-input {
    height: 50px;
    width: 50px;
    font-weight: inherit;
    color: inherit;
    background-color: ${p => p.theme.primaryBackGround};
    font-weight: bold;
    :focus {
      outline: 0;
      box-shadow: none;
    }
    user-select: none;
  }
  .bp3-timepicker-divider-text {
    font-size: inherit;
    margin: 0 4px;
    color: inherit;
  }
  .bp3-popover-target {
    width: 100%;
  }
`;

export const CheckboxField = styled(Checkbox)`
  margin-bottom: 0px;
  :focus {
    outline: none;
  }
  .bp3-menu {
    font-size: 12px !important;
  }
`;

export const StatusLabel = styled.label`
  cursor: pointer;
`;
