import React from "react";
import * as Core from "@blueprintjs/core";

export const ButtonGroup = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <Core.ButtonGroup>
      {children}
    </Core.ButtonGroup>
  );
};
