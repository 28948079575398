import React, { createContext, useEffect, useState } from "react";
import { Message } from "@aisalfaplan/troponic";
import { useTroponic } from "@ais/core";

export interface MessagesList {
  messages: Message[];
}

export interface ChildComponentProps {
  children: React.ReactNode | JSX.Element;
}
export const MessageContext = createContext<MessagesList>({ messages: [] });

export const MessagesProvider = ({ children }: ChildComponentProps): JSX.Element => {
  const troponic = useTroponic();
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    getAllMessages();
  }, []);

  return (
    <MessageContext.Provider
      // We need to Fix the Re-Render Issue here, disabline the auto check for now
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        messages,
      }}
    >
      {children}
    </MessageContext.Provider>
  );

  async function getAllMessages() {
    const feedResult = await troponic.Message.fetch({});
    const messageList: Message[] = feedResult.results.map(x => ({
      sender: x.sender,
      recipient: x.recipient,
      dateSent: x.dateSent,
      resourceId: x.resourceId,
      tenantId: x.tenantId,
      editor: x.editor,
      messageText: x.messageText,
      timeStamp: x.timeStamp,
      driverRecipient: x.driverRecipient,
    }));
    setMessages(messageList);
  }
};
