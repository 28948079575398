import styled from "styled-components";

export const PageContainer = styled.div`
  background: white;
  align-self: center;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
  padding: 10px;
`;

export const WidgetCard = styled.div`
  overflow: auto;
`;

export const CardContainer = styled.div`
width: 100% ;
height: 100% ;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const DashboardCard = styled(WidgetCard)`
  width: 250px;
  height: 75px;
`;
