import styled from "styled-components";
import { Card } from "@ais/ui";

export const AppContainers = styled.div`
  width:100%;
  height: 100%;
  display: flex;
`;

export const MainViewContainer = styled.div`
 display: flex;
 flex: 1;
 flex-direction: column;
 width: 100%;
 height: 100%;
 padding: 0px 20px 10px 10px;
 overflow-y: auto;
 overflow-x: hidden;
`;

export const RightViewContainer = styled.div`
 display: flex;
 flex: 1;
 flex-direction: column;
 min-width: 400px;
 max-width: 400px;
 width: 400px;
 height: 100%;
 padding: 0px 0px 10px 10px;
 overflow-y: auto;
 overflow-x: hidden;
`;

export const WidgetCard = styled(Card)`
  flex: 1;
  align-items: center;
  overflow: auto;
  opacity: 1;
  margin:0;
`;
