import MC1 from "./1.png";
import MC2 from "./2.png";
import MC3 from "./3.png";
import MC4 from "./4.png";
import MC5 from "./5.png";
import MC6 from "./6.png";
import MC7 from "./7.png";
import Cub from "./Cub-icon.png";
import Flippy from "./Flippy-icon.png";
import Petunie from "./Petunie-icon.png";
import TheMole from "./TheMole-icon.png";

const MobileCarriersIcons = {
  MC1,
  MC2,
  MC3,
  MC4,
  MC5,
  MC6,
  MC7,
};

export const HappyTreeFriends = {
  Cub,
  Flippy,
  Petunie,
  TheMole,
};

const list: { key: number; value: string }[] = [
  { key: 1, value: MobileCarriersIcons.MC1 },
  { key: 2, value: MobileCarriersIcons.MC2 },
  { key: 3, value: MobileCarriersIcons.MC3 },
  { key: 4, value: MobileCarriersIcons.MC4 },
  { key: 5, value: MobileCarriersIcons.MC5 },
  { key: 6, value: MobileCarriersIcons.MC6 },
  { key: 7, value: MobileCarriersIcons.MC7 },
];

export const ImageAssets = (typeId: number) => list.find(en => en.key === typeId)?.value ?? MC1;
