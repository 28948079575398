import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useTranslator } from "@ais/core";
import { MenuItem, Popover, useLocalStorage } from "@ais/ui";

import { TranslationResource } from "locales";
import { BaseRow, PersistedViewConfig } from "types";
import { resolveViewConfig } from "../../../utils";
import { Container, FavIconWrapper, FavouriteViewMenu, Icon, PopoverContainer, Tooltip } from "./favouriteView.styles";

export interface FavouriteViewProps<D extends BaseRow> {
  gridKey: keyof TranslationResource;
  currentView?: PersistedViewConfig<D>;
  onViewSelect: (view: PersistedViewConfig<D>) => void;
  lastUpdates: number;
}

export const FavouriteView = <D extends BaseRow>(props: FavouriteViewProps<D>) => {
  const { currentView, onViewSelect, lastUpdates, gridKey } = props;
  const localStorage = useLocalStorage();
  const theme = useTheme();
  const { t, translationKeys } = useTranslator<TranslationResource>();

  const [viewConfigs, setViewConfigs] = useState(() => {
    return resolveViewConfig<D>(localStorage, gridKey);
  });

  const [disabled, setDisabled] = useState(() => {
    return (viewConfigs?.configurations ?? []).filter(vc => vc.filter.favourite).length === 0;
  });

  useEffect(() => {
    const view = resolveViewConfig<D>(localStorage, gridKey);
    if (view) {
      view.configurations = (view?.configurations ?? []).filter(vc => vc.filter.favourite);
    }

    setDisabled((view?.configurations ?? []).length === 0);
    setViewConfigs(view);

    return () => setViewConfigs(undefined);
  }, [localStorage, lastUpdates]);

  const popoverContent = (
    <Container>
      <FavouriteViewMenu>{viewConfigs?.configurations.filter(vc => vc.filter.favourite).map(handleMenuSelect)}</FavouriteViewMenu>
    </Container>
  );

  return (
    <PopoverContainer>
      <Popover autoFocus={false} content={popoverContent} disabled={disabled} position="bottom-right">
        <Tooltip content={t(translationKeys.grid.tooltip.noFavView)} disabled={!disabled} position="bottom-right">
          <FavIconWrapper tabIndex={-1}>
            <Icon
              color={disabled ? theme.neutrals.darkSilver : theme.primary}
              icon="star"
              iconSize={20}
              open={disabled}
            />
          </FavIconWrapper>
        </Tooltip>
      </Popover>
    </PopoverContainer>
  );

  function handleMenuSelect(view: PersistedViewConfig<D>) {
    return (
      <MenuItem
        key={view.name}
        icon={<Icon icon={view.name === currentView?.name ? "tick" : "blank"} />}
        text={view.name}
        onClick={() => onViewSelect(view)}
      />
    );
  }
};
