import React, { useState } from "react";
import L from "leaflet";
import { Marker } from "react-leaflet";
import { AisIcons } from "../../assets/icons";
import { MapPosition } from "./webMapContainer";

export interface WebMarkerProps {
  onClick?: () => Promise<void>;
  isClickable?: boolean;
  drag?: boolean;
  key?: string;
  position: MapPosition;
  icon: keyof typeof AisIcons;
}

export const WebMarker = (props: React.PropsWithChildren<WebMarkerProps>) => {
  const [isSelected, setIsSelected] = useState(false);

  const mapEventHandlers = () => ({
    click: () => {
      if (props.isClickable) {
        setIsSelected(!isSelected);
        if (props.onClick) {
          props.onClick();
        }
      }
    },
  });

  return (
    <Marker
      draggable={props.drag}
      eventHandlers={mapEventHandlers()}
      icon={L.icon({ iconUrl: AisIcons[props.icon], iconSize: isSelected ? [25, 35] : [20, 30], className: isSelected ? "marker-green" : "marker-red" })}
      position={props.position}
    >
      {props.children}
    </Marker>
  );
};
