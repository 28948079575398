import React from "react";
import { AisIntent, NonIdealState, Spinner, SpinnerSize } from "@ais/ui";
import { AuthService } from "../../auth";
import { FullCenterScreen } from "../../styles";
import { WebAppText } from "../../text";

export interface SignOutProps {
  tokenService: AuthService;
}

export class SignOut extends React.Component<SignOutProps> {
  constructor(props: SignOutProps) {
    super(props);
  }

  public async componentDidMount() {
    await this.props.tokenService.signoutRedirect();
  }

  public render() {
    return (
      <FullCenterScreen>
        <NonIdealState
          icon={<Spinner colour={AisIntent.Primary} size={SpinnerSize.Standard} />}
          title={WebAppText.SignOut}
        />
      </FullCenterScreen>
    );
  }
}
