import React from "react";
import { Position } from "@aisalfaplan/troponic";
import { minBy } from "lodash";
import { convertGeoMinSecToGeoDecimal, useTranslator } from "@ais/core";
import { EditableColumnWithStrictAccessor } from "types";
import { TranslationResource } from "../locales";
import { Grid } from "../components";
import { PositionColumnNames, PositionData, columnConfig } from "./columnConfig";

export interface PositionGridProps {
  data: Position[];
}

export const PositionGrid = (props: PositionGridProps) => {
  const { data } = props;
  const { t, translationKeys } = useTranslator<TranslationResource>();

  const leastOdometer = minBy(data, d => d.odometer ?? 0)?.odometer ?? 0;

  const positionData: PositionData[] = data.map(p => ({
    rowId: Number(p.resourceId),
    lastUpdateDate: Date.now(),
    timeStamp: new Date(p.date),
    state: `${p.state.name ?? ""} ${p.state.description ?? ""}`,
    startStart: new Date(p.state.startDate),
    startEnd: p.state.endDate ? new Date(p.state.endDate) : undefined,
    timespan: p.state.stateTimespan,
    speed: p.speed,
    odometer: p.odometer,
    odometerDiff: (p.odometer ?? 0) - leastOdometer,
    amountSatellites: p.amountSatellites,
    street: p.address.street,
    city: p.address.city,
    gasLevel: p.gasLevel,
    latitude: convertGeoMinSecToGeoDecimal(p.latitude),
    longitude: convertGeoMinSecToGeoDecimal(p.longitude),
  }));

  return <Grid<PositionData> columnConfig={getColumnConfig()} gridKey="positionGrid" initialData={positionData} />;

  function getColumnConfig(): EditableColumnWithStrictAccessor<PositionData>[] {
    return columnConfig.map(c => ({
      ...c,
      Header: t(translationKeys.positionGrid.columns[c.accessor as keyof PositionColumnNames]),
    }));
  }
};
