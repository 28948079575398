import styled, { createGlobalStyle } from "styled-components";
import { Icon, Menu } from "@ais/ui";

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    background-color: ${p => p.theme.background};
  }

  body.fontLoaded {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .bp3-tooltip {
    .bp3-popover-content {
      background: white;
      color: black;
    }

    .bp3-popover-arrow-fill {
      fill: white;
    }
  }
`;

export const GridIcon = styled(Icon)`
  :focus {
    outline: none;
  }
  display: flex;
  cursor: pointer;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GridMenu = styled(Menu)`
  min-width: 200px;
  max-width: 300px;
  .bp3-menu-item {
    color: ${props => props.theme.primary};
    font-size: 11px;
    font-weight: bold;
    :focus {
      outline: none;
    }
  }

  .bp3-icon {
    color: ${props => props.theme.primary};
  }
`;
