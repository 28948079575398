import { FocusStyleManager } from "@blueprintjs/core";

// Native Blueprint Components
export { Classes, Utils } from "@blueprintjs/core";
export { Classes as DateClasses } from "@blueprintjs/datetime";
export { Classes as SelectClasses } from "@blueprintjs/select";

// Component Replacement
export { ButtonProps, AnchorButtonProps, Button, AnchorButton } from "./components/button";
export { ButtonGroup } from "./components/button-group";
export { Card, CardProps } from "./components/card-view";
export { CheckboxProps, Checkbox } from "./components/checkbox-view";
export { DateTimeProps, DateTime, DateRangePicker, DateRangePickerProps, DateInput, DateInputProps, DateRangeInput, DateRangeInputProps as DateRangeInputProps } from "./components/date-views";
export { FilterDropDown } from "./components/filter-view";
export { IconProps, Icon, IconName, extendedIconNames } from "./components/icon";
export { NonIdealStateProps, NonIdealState } from "./components/nonidealstate";
export { SpinnerProps, SpinnerSize, AisIntent, Spinner } from "./components/spinner-view";
export { SvgProps, Svg } from "./components/svg";
export { SwitchProps, Switch } from "./components/switch-view";
export { AisIcons } from "./assets/icons/index";
export { MenuView, MenuViewItem } from "./components/menu-view";
export { SearchInput } from "./components/input";
export { PopoverControlledView, PopoverUncontrolledView } from "./components/popover-view";
export { KeyValue } from "./components/key-value-view";
export { CardWidget, CardWidgetProps, CardWidgetItem } from "./components/card-widget";
export { Dialog, DialogView, DialogTextAreaView, DialogCloseReason, DialogImageAreaView } from "./components/dialog-view";
export { TextArea } from "./components/text-area";
export { ChatWindow, ChatMessageProps, StackWindow, StackWindowProps, StackWindowItem } from "./components/chat-window-view";
export { TabsView } from "./components/tabs-view";

export { Select } from "./components/select";
export { InputGroup } from "./components/inputgroup";

// TODO: Create separate custom component
export {
  Alert,
  IAlertProps,
  Popover,
  Tag,
  Intent,
  NumericInput,
  Tooltip,
  HTMLTable,
  MenuDivider,
  MenuItem,
  ContextMenu,
  Menu
} from "@blueprintjs/core";
export { IItemRendererProps } from "@blueprintjs/select";
export { DateRange, IDateInputProps } from "@blueprintjs/datetime";

// Custom Components
export {
  WebMapContainer,
  LayerUsage,
  OpenMapTileLayer,
  WebPolyLine,
  MapPosition,
  WebMarker,
  MarkerToolTip,
  MarkerPopUp,
  WebClusterWrapper,
  SetBoundsRectangles
} from "./components/map";

// Theme
export * from "./styles/theme-setting/customtheme";

// Storage
export * from "./storagePartition";

// Hooks
export * from "./hooks";

// Disable focus outline, recommended by Blueprint
FocusStyleManager.onlyShowFocusOnTabs();
