import React, { useState } from "react";
import { Button, Classes } from "@blueprintjs/core";
import { CardWidgetContainer, CardWidgetItemContainer, CardWrapper2, SearchCard } from "./cardWidget.styles";

interface SearchInputProps {
  disabled?: boolean;
  placeholder?: string;
  type?: "text" | "password";
  value?: string;
  onChange: (filterValue: string) => void;

}

const SearchInput = (props: SearchInputProps) => {
  const [value, setValue] = useState<string>(props.value ?? "");

  const updateValue = (filter: string) => {
    setValue(filter);
    props.onChange(filter);
  };
  const onValueChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
    updateValue(ev.target.value);
  };

  const clearButton = (
    <Button
      disabled={!(value.length > 0)}
      icon="delete"
      onClick={() => updateValue("")}
    />
  );

  return (
    <SearchCard
      disabled={props.disabled}
      leftIcon="search"
      placeholder={props.placeholder}
      rightElement={clearButton}
      type={props.type ?? "text"}
      value={value}
      onChange={onValueChanged}
    />
  );
};

export interface CardWidgetItem {
  key: string | number;
}

export interface CardWidgetProps<T extends object> {
  columnContainer?: boolean;
  fullWidth?: boolean;
  onClick?: (item: T) => void;
  items: T[];
  render: (item: T, skeletonClass?: string) => React.ReactElement;
  getKey: (item: T) => number | string;
  isActive: (item: T) => boolean;
  textFilter?: {
    value?: string;
    disabled?: boolean;
    placeHolder?: string;
    onFilter: (filtertext: string) => void;
  };
  isSkeleton?: boolean;
}

export const CardWidget = <T extends object>(props: CardWidgetProps<T>) => {
  const render = (item: T) => {
    return props.render(item, props.isSkeleton ? Classes.SKELETON : undefined);
  };

  const onClick = (item: T) => {
    if (props.isSkeleton) {
      return;
    }
    const c = props.onClick ?? (() => null);
    c(item);
  };

  const getClassName = (item: T) => {
    return (props.isActive(item) ? " active " : "") + (props.textFilter ? " selectable " : "") + (!props.fullWidth ? " fixedCard " : "");
  };
  return (
    <CardWidgetContainer>
      {props.textFilter ? (
        <SearchInput disabled={props.textFilter.disabled} placeholder={props.textFilter.placeHolder ?? ""} value={props.textFilter.value} onChange={props.textFilter.onFilter!} />
      )
        : null}
      <CardWidgetItemContainer className={props.columnContainer ? "column" : ""}>
        {
          props.items.map((item: T) => {
            return (
              <CardWrapper2 key={props.getKey(item)} className={getClassName(item)} onClick={() => onClick(item)}>
                {render(item)}
              </CardWrapper2>
            );
          })
        }
      </CardWidgetItemContainer>
    </CardWidgetContainer>
  );
};
