import { createContext } from "react";
import { StoragePartition } from "../storagePartition";

export interface StorageContext {
  readonly storage: {
    readonly local: StoragePartition;
    readonly session: StoragePartition;
  };
}

export const StorageContext = createContext<StorageContext>({
  storage: {
    get local(): StoragePartition {
      throw new Error("StorageContext is not initialized");
    },
    get session(): StoragePartition {
      throw new Error("StorageContext is not initialized");
    },
  },
});
