import React, { useState } from "react";
import * as Core from "@blueprintjs/datetime";
import { IPopoverProps } from "@blueprintjs/core";

export interface DateInputProps {
  maxDate?: Date;
  minDate?: Date;
  placeholder?: string;
  value?: Date | null;
  popoverProps?: Partial<IPopoverProps> & object;
  formatDate?(date: Date, locale?: string): string;
  onChange(selectedDate: Date, isUserChange: boolean): void | undefined;
  onError?(errorDate: Date): void;
  parseDate?(str: string, locale?: string): Date | false | null;
}

export const DateInput = (props: DateInputProps) => {
  const {
    maxDate,
    minDate,
    placeholder,
    popoverProps,
    value,
    formatDate,
    onChange,
    onError,
    parseDate,
  } = props;

  const [date, setDate] = useState<Date>(value ?? new Date());

  const handleDateChange = (date: Date, isUserChange: boolean) => {
    setDate(date);
    onChange?.(date, isUserChange);
  };

  return (
    <>
      <Core.DateInput
        fill
        formatDate={value => formatDate ? formatDate(value) : value?.toLocaleDateString() ?? ""}
        maxDate={maxDate}
        minDate={minDate}
        parseDate={str => parseDate ? parseDate(str) : new Date(Date.parse(str))}
        placeholder={placeholder}
        popoverProps={popoverProps}
        value={date}
        onChange={handleDateChange}
        onError={onError}
      />
    </>
  );
};
