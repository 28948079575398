import React from "react";
import { Button, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

export interface Arraytypes {
  id: number;
  messenger: string;
}

const Items: Arraytypes[] = [{ id: 1, messenger: "Alle" }, { id: 2, messenger: "Soham" }, { id: 3, messenger: "Nikitha" }, { id: 4, messenger: "Ben" }, { id: 5, messenger: "Debasish" }, { id: 6, messenger: "Thomas" }];

export const FilterDropDown: React.FunctionComponent = () => {
  const items = Items;

  const itemRenderer = (items: Arraytypes) => {
    return (
      <MenuItem
        key={items.id}
        shouldDismissPopover
        text={items.messenger}
      />
    );
  };
  const handleClick = (): void => {};

  return (
    <div>
      <Select
        filterable={false}
        itemRenderer={itemRenderer}
        items={items}
        onItemSelect={handleClick}
      >
        <Button rightIcon="caret-down" text="Alle" />
      </Select>
    </div>
  );
};
