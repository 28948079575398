import React from "react";
import * as Core from "@blueprintjs/datetime";
import { Classes } from "@blueprintjs/core";
import { maxDate, minDate } from "./constants";

export interface DateRangePickerProps {
  onChange: (dateRange: Core.DateRange) => void;
  value: Core.DateRange;
}

export const DateRangePicker = (props: DateRangePickerProps) => {
  const handleDateChange = (date: Core.DateRange) => {
    props.onChange?.(date);
  };

  return (
    <Core.DateRangePicker
      className={Classes.ELEVATION_1}
      maxDate={maxDate}
      minDate={minDate}
      shortcuts={false}
      value={props.value}
      onChange={handleDateChange}
    />
  );
};
