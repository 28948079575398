import React, { useState } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import { useTranslator } from "@ais/core";

import {
  Button,
  ChatMessageProps,
  ChatWindow,
  Checkbox,
  DateInput,
  DateRange,
  DateRangeInput,
  DateRangePicker,
  DateRangePickerProps,
  DateTime,
  DateTimeProps,
  DialogCloseReason,
  DialogImageAreaView,
  DialogTextAreaView,
  Icon,
  Intent,
  MenuView,
  MenuViewItem,
  PopoverControlledView,
  PopoverUncontrolledView,
  StackWindow,
  StackWindowItem,
  Switch,
  TabsView
} from "@ais/ui";

import { TranslationResource } from "locales";
import { LanguageSwitch } from "../../components/language/languageSwitch";

import { PageHeader } from "../../components/page-header/pageHeader";
import { PageContainer, WidgetCard } from "./job.styles";

const randomDate = (start: Date, end: Date) => {
  return new Date(start.getTime() + (Math.random() * (end.getTime() - start.getTime())));
};

const messages: ChatMessageProps[] = [
  { from: "Debasish", sent: randomDate(new Date(2022, 0, 1), new Date()), message: "I am hero" },
  { from: "Debasish", sent: randomDate(new Date(2022, 0, 1), new Date()), message: "Koles" },
  { from: "Rahul", sent: randomDate(new Date(2022, 0, 1), new Date()), message: "Raul" },
];

export const ShowCase = (): React.ReactElement => {
  const { t, translationKeys } = useTranslator<TranslationResource>();
  const [online, setOnline] = useState(false);
  const [checked, setChecked] = useState(false);
  const [time, setTime] = useState("");
  const [date, setDateRange] = useState<DateRange>([null, null]);
  const [dateRangeInput, setDateRangeInput] = useState<DateRange>([null, null]);
  const [dateInput, setDateInput] = useState<Date | null>(null);
  const [popoverIsOpen, setPopoverState] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isTextDialogOpen, setTextDialogOpen] = useState(false);
  const [isImageViewDialogOpen, setImageViewDialogOpen] = useState(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [messageData, setMessageData] = useState<string>();
  const items: MenuViewItem[] = [
    { id: "1", text: "Huhu", active: true, subMenu: true, children: [{ id: "13", text: "Deba", active: true }, { id: "14", text: "Thomas", active: false }] },
    { id: "2", text: "Haha", shortcut: "Win+i" },
    { id: "0", divider: true },
    { id: "3", text: "Lulu" },
    { id: "4", text: "Lala", shortcut: "Win+u", disabled: true },

  ];

  const changedHandler = (selectedDate: Date): void => {
    setTime(selectedDate?.toISOString());
  };

  const dateTimeViewProps: DateTimeProps = {
    onChange: changedHandler,
    showTime: true,
  };

  const handleDateChange = (date: DateRange) => {
    setDateRange(date);
  };

  const dateTimeRangeViewProps: DateRangePickerProps = {
    onChange: handleDateChange,
    value: date,
  };

  const handleChange = (checked: boolean) => {
    setChecked(checked);
  };

  const handleSwitchChange = (checked: boolean) => {
    setOnline(checked);
  };

  const handleDateInputChange = (selectedDate: Date) => {
    setDateInput(selectedDate);
  };

  const handleDateRangeInput = (date: DateRange) => {
    setDateRangeInput(date);
  };

  const handlePopoverInteraction = (nextOpenState: boolean) => {
    setPopoverState(nextOpenState);
  };

  const handleDialog = () => {
    setDialogOpen(!isDialogOpen);
  };

  const handleTextDialog = () => {
    setTextDialogOpen(!isTextDialogOpen);
  };

  const handleTextAreaSubmit = (data?: string) => {
    setMessageData(data);
    setTextDialogOpen(!isTextDialogOpen);
  };

  const handleOnClosing = (reason: DialogCloseReason) => {
    if (reason === DialogCloseReason.Outside) {
      // DO something .. with data
    }

    setTextDialogOpen(!isTextDialogOpen);
  };

  const handleOnImageDialogClosing = (reason: DialogCloseReason) => {
    if (reason === DialogCloseReason.Outside) {
      // DO something ..
    }

    setImageViewDialogOpen(!isImageViewDialogOpen);
  };

  const handleImageDialog = () => {
    setImageViewDialogOpen(!isImageViewDialogOpen);
  };

  const handleImageSave = () => {
    // Logic for saving / download image from api --
    setImageViewDialogOpen(!isImageViewDialogOpen);
  };
  const handleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen);
  };

  // Get Information that a message was acknowledged
  const handleStackWindowClose = (item: StackWindowExtendendItem) => {
    // eslint-disable-next-line no-console
    console.log("StackWindowItem closed:" + item.message + " - " + item.from);
  };

  // Mapping the StackWindow and ChatMessageProps together
  interface StackWindowExtendendItem extends ChatMessageProps, StackWindowItem { }

  const mm: StackWindowExtendendItem[] = messages.map(m => ({
    ...m,
    title: m.from,
    body: m.message,
    footer: m.sent.toISOString(),
  }));
  const getTab1 = () => (<DateRangePicker {...dateTimeRangeViewProps} />);

  const getTab2 = () => (<WidgetCard>Here is an awesome thing happening</WidgetCard>);

  return (
    <>
      <PageHeader headLine="Showcase">Filter</PageHeader>
      <PageContainer>
        <WidgetCard>
          <table>
            <tr>
              <td>
                <DateTime showTime onChange={changedHandler} />
              </td>
              <td>
                <DateTime {...dateTimeViewProps} />
              </td>
              <td>
                showBar?: boolean <br />
                changed?: (newDate: Date)<br />
                showTime?: boolean
                <div> {time}</div>
              </td>
              <td>
                <DateRangePicker {...dateTimeRangeViewProps} />
              </td>
              <td>Selected date range is: {dayjs(date[0]).format("DD/MM/YYYY")} - {dayjs(date[1]).format("DD/MM/YYYY")}</td>
            </tr>
            <tr>
              <td>
                <DateInput
                  placeholder="Select Date"
                  value={dateInput}
                  onChange={handleDateInputChange}
                />
              </td>
              <td>
                <DateRangeInput
                  value={dateRangeInput}
                  onChange={handleDateRangeInput}
                />
              </td>
            </tr>
            <tr>
              <td><Switch disabled label={t(translationKeys.orderGrid.title)} /></td>
              <td>
                <Switch innerLabel="aus" innerLabelChecked="an" label={t(translationKeys.grid.group.title)} onChange={handleSwitchChange} />
                <span>isOnline? {online ? "on" : "off"}</span>
              </td>
              <td>
                <Checkbox checked disabled label={t(translationKeys.grid.shareView.share)} />
                <Checkbox label={t(translationKeys.loginPage.invalidLoginMessage)} onChange={handleChange} />
                <span>isChecked? {checked ? "ja" : "nein"}</span>
              </td>
            </tr>
            <tr>
              <td><MenuView items={items} /></td>
              <td><MenuView items={items} /></td>
              <td><LanguageSwitch /></td>
              <td>Template with Name Hans Dampf: {t(translationKeys.templates.helloMessage, { name: "Hans Dampf" })}</td>
              <td />
            </tr>
            <tr>
              <td colSpan={3}>
                <IconDiv>
                  <IconSpan> <Icon icon="aisArrowDownWhite" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisArrowDown" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisArrowRight" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisBell" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisChartBars" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisChartLines" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisChartPie" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisChat" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisClose" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisDashboardGrey" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisDashboard" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisFilterDate" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisFilterOnline" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisFilterVehicle" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisFilter" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisGrid" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisJobClipboard" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisJobSuccess" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisJob" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisLogo" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisMagnifier" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisMapCarrierFlat" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisMapCarrierHeavy" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisMapCarrierTruckSmall" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisMapCarrierTruck" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisMapCarrierVan" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisMapMarker" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisMasterData" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisMessageReceived" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisMessageSent" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisModules" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisMore" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisProducts" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisReset" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisSecurity" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisSettings" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisSpaceman" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisTrendDown" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisTrendUp" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisTruck" iconSize={25} /> </IconSpan>
                  <IconSpan> <Icon icon="aisViewSingle" iconSize={25} /> </IconSpan>
                </IconDiv>
              </td>
            </tr>
          </table>
          <PopoverControlledView
            content={(
              <div>
                <h5>Popover Title</h5>
                <p>...</p>
                <Button text="Close popover" />
              </div>
            )}
            isOpen={popoverIsOpen}
            onInteraction={handlePopoverInteraction}
          >
            <Icon color={Intent.PRIMARY} icon="aisLogo" iconSize={40} />
          </PopoverControlledView>
          <PopoverUncontrolledView
            content={(
              <div>
                <h5>Popover Title</h5>
                <p>...</p>
                <Button text="Close popover" />
              </div>
            )}
          />
          <Button text="Send" onClick={handleDialog} />
          <Button text="TextArea" onClick={handleTextDialog} />
          <Button text="ImageDialog" onClick={handleImageDialog} />
          <Button text="Overlay view" onClick={handleChatWindow} />
          <p>Message is: {messageData}</p>
          <DialogTextAreaView
            isOpen={isTextDialogOpen}
            title="Text Area"
            onClose={handleOnClosing}
            onSubmit={handleTextAreaSubmit}
          />
          <DialogImageAreaView
            description="Hello describe me !"
            imageName="favoriteDog.jpg"
            imagePath="https://picsum.photos/id/237/200/300.jpg"
            isOpen={isImageViewDialogOpen}
            title="Title Part"
            onClose={handleOnImageDialogClosing}
            onSubmit={handleImageSave}
          />
          <StackWindow
            items={mm}
            title="messages"
            onClose={handleStackWindowClose}
          />
          <ChatWindow messages={messages} open={isChatWindowOpen} title="Text Messages" onClose={handleChatWindow} />
          <TabsView
            tabs={
              [{ id: "rx", title: t(translationKeys.grid.shareView.share), content: getTab1() }, { id: "ax", title: t(translationKeys.grid.cogMenu.showFilter), content: getTab2() }]
            }
          />
        </WidgetCard>
      </PageContainer>
    </>
  );
};

const IconDiv = styled.div`
background: #dbdb98`;

const IconSpan = styled.span`
margin: 15px;
padding: 15px;
width: 30px;
height: 30px;
`;
