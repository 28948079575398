import { BaseRow, EditableColumnWithStrictAccessor } from "types";

export interface MessageData extends BaseRow {
  sender?: number;
  recipient?: number;
  driverSender?: number;
  dateSent: Date;
  dateReceived?: Date;
  dateRead?: Date;
  editor: string;
  messageText: string;
  timeStamp: Date;
  driverRecipient: number;
}

export type MessageColumnNames = Omit<MessageData, keyof BaseRow>;

export const columnConfig: EditableColumnWithStrictAccessor<MessageData>[] = [
  { accessor: "sender" },
  { accessor: "recipient" },
  { accessor: "driverSender" },
  {
    accessor: "dateSent",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Cell: ({ value }) => value.toLocaleString("de-DE"),
  },
  {
    accessor: "dateReceived",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Cell: ({ value }) => value?.toLocaleString("de-DE"),
  },
  {
    accessor: "dateRead",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Cell: ({ value }) => value?.toLocaleString("de-DE"),
  },
  { accessor: "editor" },
  { accessor: "messageText" },
  {
    accessor: "timeStamp",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Cell: ({ value }) => value.toLocaleString("de-DE"),
  },
  { accessor: "driverRecipient" },
];
