import React from "react";
import { CarrierWidget } from "../../pages/carrierWidget";
import { RightHeader } from "../../../components/page-header/rightHeader";
import { UserProfileView } from "../../../components/user-profile-view";
import {
  AppContainers,
  MainViewContainer,
  RightViewContainer
} from "./layoutStyles";

export const UserLayout: React.FC<{}> = (props: React.PropsWithChildren<{}>) => {
  return (
    <AppContainers>
      <MainViewContainer>
        {props.children}
      </MainViewContainer>
      <RightViewContainer>
        <RightHeader>
          <UserProfileView />
        </RightHeader>
        <CarrierWidget />
      </RightViewContainer>
    </AppContainers>
  );
};
