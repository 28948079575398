import React from "react";
import { Icon } from "@ais/ui";
import packageJson from "../../../package.json";

import { Container, NavGroup, NavLink, Version } from "./appNav.styles";

export const AppNav = () => {
  const iconSizeModule = 20;
  const iconSizeNavHead = 50;
  return (
    <Container>
      <NavGroup>
        <NavLink to="/ais">
          <Icon icon="aisLogo" iconSize={iconSizeNavHead} />
        </NavLink>
        <NavLink to="/dashboard">
          <Icon icon="aisDashboardGrey" iconSize={iconSizeModule} />
        </NavLink>
        <NavLink to="/messages">
          <Icon icon="aisChat" iconSize={iconSizeModule} />
        </NavLink>
        <NavLink to="/map">
          <Icon icon="aisProducts" iconSize={iconSizeModule} />
        </NavLink>
        <NavLink to="/orders">
          <Icon icon="aisJobSuccess" iconSize={iconSizeModule} />
        </NavLink>
        <NavLink to="/basedata">
          <Icon icon="aisJob" iconSize={iconSizeModule} />
        </NavLink>
        <NavLink to="/tenant-admin">
          <Icon icon="aisSecurity" iconSize={iconSizeModule} />
        </NavLink>
        <NavLink to="/driving times">
          <Icon icon="aisModules" iconSize={iconSizeModule} />
        </NavLink>
        {/* <NavLink to="/map_func">
          <Icon icon="aisSpaceman" iconSize={iconSizeModule} />
        </NavLink> */}
      </NavGroup>
      {/* <NavGroup>
        <Link to="/dashboard"> <Icon color="grey" icon="cog" iconSize={30} /></Link>
      </NavGroup> */}
      <div>
        <Version>{packageJson.version}</Version>
        {/* TODO: discuss this <Version>{packageJson.dependencies["@aisalfaplan/troponic"]}</Version> */}
      </div>
    </Container>
  );
};
