import styled from "styled-components";
import { Card } from "@ais/ui";

export const PageContent = styled.div`
 display: flex;
 flex-direction: row;
 overflow-x: auto;
`;

export const PageContainer = styled.div`
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100%;
 padding:0 10px;
`;

export const WidgetCard = styled(Card)`
  flex: 1;
  border-radius: 5px;
  opacity: 1;
  height: 100%;
  overflow-y: auto;
`;
