import React from "react";
import { Redirect } from "react-router-dom";
import { AisIntent, NonIdealState, Spinner, SpinnerSize } from "@ais/ui";
import { AccessTokenContext } from "@ais/core";
import { AuthService } from "../../auth";
import { FullCenterScreen } from "../../styles";
import { WebAppText } from "../../text";

export interface SignInProps {
  tokenService: AuthService;
  tokenContext: AccessTokenContext | null;
}

export interface SignInState {
  loadedIdentity: boolean;
}

export class SignIn extends React.Component<SignInProps, SignInState> {
  constructor(props: SignInProps) {
    super(props);

    this.state = {
      loadedIdentity: false,
    };
  }

  public async componentDidMount() {
    await this.props.tokenService.signInRedirectCallBack();
    this.setState({
      loadedIdentity: true,
    });
  }

  public render() {
    const { loadedIdentity } = this.state;

    if (loadedIdentity) {
      if (this.props.tokenContext) {
        const { state } = this.props.tokenContext.token.identity;
        return (
          <Redirect to={state ?? "/"} />
        );
      }

      return (
        <span>Error</span>
      );
    }

    // Show something while we load their identity
    return (
      <FullCenterScreen>
        <NonIdealState
          icon={<Spinner colour={AisIntent.Primary} size={SpinnerSize.Standard} />}
          title={WebAppText.SignIn}
        />
      </FullCenterScreen>
    );
  }
}
