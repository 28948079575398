import React from "react";
import { Carrier } from "@aisalfaplan/troponic";
import { getDate, useTranslator } from "@ais/core";
import { EditableColumnWithStrictAccessor } from "types";
import { TranslationResource } from "../locales";
import { Grid } from "../components";
import { CarrierColumnNames, CarrierData, columnConfig } from "./columnConfig";

export interface CarrierGridProps {
  data: Carrier[];
}

export const CarrierGrid = (props: CarrierGridProps) => {
  const { data } = props;
  const { t, translationKeys } = useTranslator<TranslationResource>();

  const carrierData: CarrierData[] = data.map(p => ({
    rowId: Number(p.resourceId),
    lastUpdateDate: Date.now(),
    name: p.name,
    license: p.license,
    licenseExpiryDate: getDate(p.licenseExpiryDate ?? "2020-12-26T18:24:11.225Z"), // To-Do
  }));

  return <Grid<CarrierData> columnConfig={getColumnConfig()} gridKey="carrierGrid" initialData={carrierData} />;

  function getColumnConfig(): EditableColumnWithStrictAccessor<CarrierData>[] {
    return columnConfig.map(c => ({
      ...c,
      Header: t(translationKeys.carrierGrid.columns[c.accessor as keyof CarrierColumnNames]),
    }));
  }
};
