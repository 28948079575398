import styled from "styled-components";
import { Icon as BPIcon } from "@ais/ui";

export const Container = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
`;

export interface IconProps {
  open: boolean;
}

export const Icon = styled(BPIcon)<IconProps>`
  :focus {
    outline: none;
  }
  display: flex;
  cursor: pointer;
  color: ${props => props.open ? props.theme.icon.default : props.theme.icon.muted};
`;

export const Checkbox = styled.input.attrs({
  type: "checkbox",
})`
  opacity: 0;
  width: 0;
  height: 0;
  :checked + .slider {
    background-color: ${props => props.theme.primaryBackGround};
  }

  :checked + .slider:before {
    transform: translateX(24px);
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
`;

export const LeftIconContainer = styled.div`
  flex: 1;
  padding-left: 5px;
`;

export const RightIconContainer = styled.div`
  padding-right: 7px;
`;

export const Slider = styled.span.attrs({
  className: "slider",
})`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.primaryBackGround};
  transition: 0.4s;
  border-radius: 24px;

  :before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;

    background-color: ${props => props.theme.primary};
    transition: 0.4s;
    border-radius: 50%;
  }
`;
