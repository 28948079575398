import { hi as gridHi } from "@ais/grid";
import { TranslationResource } from "./types";

export const hi: TranslationResource = {
  ...gridHi,
  common: {
    online: "online",
    offline: "offline",
    unbound: "unbound",
    driver: "driver: {{driver.driverName}}",
    coDriver: "co-driver: {{driver.driverName}}",
    search: "search",
  },
  user: {
    language: "भाषा: हिन्दी",
    changePassword: "पासवर्ड बदलें",
    logout: "साइन आउट",
  },
  aisweb: {
    dashboard: "डैशबोर्ड",
    aisComponent: "Ais - दृश्य",
    chatComponent: {
      total: "समस्त मूल्यों",
      show: "प्रदर्शन",
    },
    chartComponent: {
      title: "चार्ट - दृश्य",
      mainView: "मुख्य-दृश्य",
    },
    baseData: "base data",
    chatPage: "बातचीत",
    orderPage: "आदेश",
    mapComponent: "नक्शा - दृश्य",
    headerMapMessagesCount: "Total messages ({{amount, number}})",
    moduleComponent: "Module - दृश्य",
    securityComponent: "Security - दृश्य",
    jobsGreyComponent: "JobsGrey - दृश्य",
    jobsComponent: {
      dateOfDelivery: "पहुंचाने की तारीख",
      sender: "प्रेषक",
      recipient: "प्राप्तकर्ता",
      goods: "माल",
    },
  },
  i18nFeature: {
    selectLanguage: "Select Language",
    description: "Scalable apps need to support multiple languages, easily add and support multiple languages. Change the language below to see how instantly it updates the page without refreshing.",
  },
  loginPage: {
    welcomeMessage: "Welcome to AIS alfaplan!",
    invalidLoginMessage: "Invalid username or password.",
  },
  templates: {
    helloMessage: "Hello {{name, string}}! Hope you are doing good",
  },
  modal: {
    okButton: "Ok",
    cancelButton: "Cancel",
  },
};
