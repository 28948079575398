import React from "react";
import * as Core from "@blueprintjs/core";

export interface SpinnerProps {
  size?: SpinnerSize;
  /**
     * A value between 0 and 1 (inclusive) representing how far along the operation is.
     * Values below 0 or above 1 will be interpreted as 0 or 1 respectively.
     * Omitting this prop will result in an "indeterminate" spinner where the head spins indefinitely.
  */
  value?: number;

  colour?: AisIntent;
}
export enum SpinnerSize {
  Small,
  Standard,
  Large
}

// If we end up using this more, we would need to move to a common folder
export enum AisIntent {
  None,
  Primary,
  Success,
  Warning,
  Danger
}

export const Spinner = (props: SpinnerProps) => {
  return (
    <Core.Spinner
      {...toNativeProps(props)}
    />
  );
};

function toNativeProps(props: SpinnerProps): Core.SpinnerProps {
  const { size, colour, ...restProps } = props;
  return {
    ...restProps,
    size: toBluePrintSize(size),
    intent: toBluePrintIntent(colour),
  };
}
function toBluePrintSize(size?: SpinnerSize): (Core.SpinnerSize | undefined) {
  switch (size) {
    case SpinnerSize.Small:
      return Core.SpinnerSize.SMALL;
    case SpinnerSize.Large:
      return Core.SpinnerSize.LARGE;
    case SpinnerSize.Standard:
      return Core.SpinnerSize.STANDARD;
    default:
      return undefined;
  }
}
function toBluePrintIntent(colour?: AisIntent): (Core.Intent | undefined) {
  switch (colour) {
    case AisIntent.None:
      return Core.Intent.NONE;
    case AisIntent.Primary:
      return Core.Intent.PRIMARY;
    case AisIntent.Success:
      return Core.Intent.SUCCESS;
    case AisIntent.Warning:
      return Core.Intent.WARNING;
    case AisIntent.Danger:
      return Core.Intent.WARNING;
    default:
      return undefined;
  }
}
