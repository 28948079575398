import { MapContainer } from "react-leaflet";
import styled from "styled-components";

interface MapContainerProps {
  $height?: number;
}
export const WebMapStyled = styled(MapContainer) <MapContainerProps>`
  height: ${props => props.$height ?? "500px"};
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  z-index: 20;
`;
