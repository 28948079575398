import React from "react";
import * as Core from "@blueprintjs/select";
import { Menu, MenuItem } from "@blueprintjs/core";

export interface SelectProps<T> extends Core.ISelectProps<T> {
  /** Whether the menu items in the select menu should use a large appearance. */
  large?: boolean;
}
export class Select<T> extends React.PureComponent<SelectProps<T>> {
  public static defaultProps = {
    popoverProps: {
      minimal: true,
    },
  };

  private readonly TypedSelect = Core.Select.ofType<T>();

  public static ofType<T>() {
    return Select as new (props: SelectProps<T>) => Select<T>;
  }

  public render() {
    const {
      itemListRenderer = this.renderItemList,
      popoverProps = {},
      noResults = <MenuItem disabled icon="zoom-out" text="No results." />,
      ...restProps
    } = this.props;
    const { minimal, usePortal, ...restPopoverProps } = popoverProps;

    return (
      <this.TypedSelect
        {...restProps}
        itemListRenderer={itemListRenderer}
        noResults={noResults}
        popoverProps={{
          minimal: minimal ?? true,
          ...restPopoverProps,
        }}
      />
    );
  }

  private renderItemList = (listProps: Core.IItemListRendererProps<T>) => {
    const { initialContent, noResults, large } = this.props;
    const menuContent = Core.renderFilteredItems(listProps, noResults, initialContent);
    return (
      <Menu large={large} ulRef={listProps.itemsParentRef}>
        {menuContent}
      </Menu>
    );
  };
}

export function highlightText(text: string, query: string): React.ReactNode {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars);

  if (words.length === 0) {
    return [text];
  }

  const regexp = new RegExp(words.join("|"), "gi");
  const tokens: React.ReactNode[] = [];

  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }

  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }

  return tokens;
}

export function escapeRegExpChars(text: string) {
  return text.replace(/([!$()*+./:=?[\\\]^{|}])/g, "\\$1");
}
