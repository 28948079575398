import React, { useMemo, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Draggable, DraggableProvidedDraggableProps, DraggableStateSnapshot, Droppable } from "react-beautiful-dnd";
import {
  ColumnInstance,
  HeaderGroup,
  IdType,
  Row,
  TableDispatch,
  UseFiltersColumnProps,
  UseGroupByColumnProps,
  UseResizeColumnsColumnProps,
  UseSortByColumnProps
} from "react-table";
import { useTranslator } from "@ais/core";
import { ContextMenu, MenuDivider, MenuItem } from "@ais/ui";

import { TranslationResource } from "locales";
import { AggregateActions, ContextMenuItem, CountAggregateActions } from "types";
import { Debouncer, FilterConfigurations, ResourceFilterSet } from "../../../utils";
import {
  AggregationText,
  FilterDialog,
  aggregateColumn,
  getAggregationAction,
  getAggregationText
} from "../../../components";

import { GridIcon, GridMenu } from "../../../styles/global";
import {
  AggregatedWrapper,
  ColumnActionArea,
  ColumnHeader,
  ColumnHeaderCellContainer,
  ColumnHeaderContainer,
  ColumnLabel,
  DragIconContainer,
  Input
} from "./headerItem.styles";

const contextMenuItems: ContextMenuItem[] = [
  "sortAscending",
  "sortDescending",
  "resetWidth",
  "removeColumn",
  "groupByColumn",
  "openFilterWindow",
];

const countAggregateMenuItems: CountAggregateActions[] = ["countAll", "countNotEmpty"];

const aggregateMenuItems: AggregateActions[] = ["sum", "minimum", "maximum", "mean", ...countAggregateMenuItems];

export type HeaderColumn<D extends {}> = HeaderGroup<D> &
  UseSortByColumnProps<D> &
  UseResizeColumnsColumnProps<D> &
  UseFiltersColumnProps<D> &
  UseGroupByColumnProps<D>;

export interface HeaderElementProps<D extends {}> {
  aggregateColumns: { id: IdType<D>; operation: string }[];
  column: HeaderColumn<D>;
  columns: ColumnInstance<D>[];
  columnOrder: string[];
  defaultFilterConfigurations: FilterConfigurations;
  index: number;
  isDragActive: boolean;
  isGrouped: boolean;
  isResizing: boolean;
  rows: Row<D>[];
  visibleColumns: ColumnInstance<D>[];
  dispatch: TableDispatch;
  isColumnRemoveable: (id: string) => boolean;
  onFilterUpdate: (columnId: string, fc: ResourceFilterSet[]) => void;
  onRemoveColumn: (id: string) => void;
  onSearchFilter?: (id: string, value: string) => void;
  showFilter: boolean;
  columnsMovable: boolean;
}

function HeaderElement<D>(props: HeaderElementProps<D>) {
  const {
    column,
    dispatch,
    index,
    showFilter,
    columnsMovable,
    onSearchFilter,
    defaultFilterConfigurations,
    onFilterUpdate,
    columns,
    onRemoveColumn,
    isGrouped,
    aggregateColumns,
    isColumnRemoveable,
    rows,
  } = props;
  const { t, translationKeys } = useTranslator<TranslationResource>();
  const debouncer = useRef(new Debouncer(1000));

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [numericMenuItemSelected, setNumericMenuItemSelected] = useState<Map<string, AggregateActions>>(new Map());

  const { isSorted, isSortedDesc, getSortByToggleProps, getResizerProps, isResizing, id, preFilteredRows, canGroupBy } = column;
  const label = column.render("Header");
  const { style, ...headerProps } = column.getHeaderProps(getSortByToggleProps());
  const headerStyle: React.CSSProperties = { ...style, position: "absolute" };

  const aggregate = aggregateColumns.find(c => c.id === id);
  let aggregatedComponent: JSX.Element | undefined;
  if (!isGrouped && aggregate) {
    const value = aggregateColumn<D>(id, rows, aggregate.operation as AggregateActions);
    aggregatedComponent = <AggregationText action={aggregate.operation as AggregateActions} value={value} />;
  }

  const columnValue = _.first(preFilteredRows.map(r => r.values[id]).filter(c => !!c));

  const filterType = useMemo(() => {
    const isDate = moment(columnValue, "D.M.Y, H:m:s").isValid();
    if (columnValue && typeof columnValue === "number") {
      return "number";
    } else if (columnValue && isDate) {
      return "date";
    }
    return "text";
  }, [id, columnValue]);

  if (id === "selection") {
    return (
      <div className="table-column-header-cell" style={headerStyle}>
        {column.render("Header")}
      </div>
    );
  }

  return (
    <Droppable direction="horizontal" droppableId={id} isDropDisabled={!columnsMovable}>
      {(droppableProvided, droppableSnapshot) => {
        const showHighlight = droppableSnapshot.isDraggingOver && droppableSnapshot.draggingOverWith !== id;
        const showFilterIcon = defaultFilterConfigurations.filterSet.length > 0;

        return (
          <div className="table-column-header-cell" style={headerStyle}>
            <ColumnHeaderCellContainer>
              <Draggable
                key={id}
                draggableId={id}
                index={index}
                isDragDisabled={!columnsMovable && (column.isGrouped || !canGroupBy)}
              >
                {(provided, snapshot) => (
                  <ColumnHeaderContainer>
                    <ColumnHeader
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ ...getItemStyle(snapshot, provided.draggableProps) } as React.CSSProperties}
                      {...headerProps}
                      ref={provided.innerRef}
                      // TODD: why div has a title ? title={label}
                      onContextMenu={handleShowContexMenu}
                    >
                      <ColumnLabel>{label}</ColumnLabel>
                      {!snapshot.isDragging && (
                        <ColumnActionArea>
                          {isSorted && (
                            <GridIcon icon={isSortedDesc ? "caret-down" : "caret-up"} iconSize={14} />
                          )}
                          {showFilterIcon && (
                            <GridIcon icon="filter" iconSize={14} onClick={onFilterIconClick} />
                          )}
                        </ColumnActionArea>
                      )}
                      {snapshot.isDragging && (
                        <DragIconContainer>
                          <GridIcon icon="caret-down" iconSize={14} />
                        </DragIconContainer>
                      )}
                    </ColumnHeader>
                    {droppableProvided.placeholder}
                    <div
                      ref={droppableProvided.innerRef}
                      {...getResizerProps?.()}
                      className={`resizer ${isResizing || showHighlight ? "isResizing" : ""}`}
                    />
                  </ColumnHeaderContainer>
                )}
              </Draggable>
              <ColumnHeader>
                <ColumnLabel>{label}</ColumnLabel>
              </ColumnHeader>
              {showFilter && (
                <Input
                  key={defaultFilterConfigurations.lineFilter ?? "lineFilterInput"}
                  defaultValue={defaultFilterConfigurations.lineFilter ?? ""}
                  onChange={onSearch}
                />
              )}
              <AggregatedWrapper>{aggregatedComponent}</AggregatedWrapper>
            </ColumnHeaderCellContainer>
            {isFilterOpen && (
              <FilterDialog
                columnName={label}
                defaultFilter={defaultFilterConfigurations.filterSet}
                isOpen={isFilterOpen}
                type={filterType}
                onClose={() => handleFilterDialog(false)}
                onFilterUpdate={(fc: ResourceFilterSet[]) => onFilterUpdate(id, fc)}
              />
            )}
          </div>
        );
      }}
    </Droppable>
  );

  function handleShowContexMenu(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // Must prevent default to cancel parent's context menu
    e.preventDefault();
    // Invoke static API, getting coordinates from mouse event
    const { id, preFilteredRows } = column;
    const row = preFilteredRows[0]?.original;
    const isNumericColumn = row && typeof row[id as keyof D] === "number"; // TODO: this logic need improvement
    const numericMenuItem = isNumericColumn ? aggregateMenuItems : countAggregateMenuItems;
    const menuItems: (ContextMenuItem | AggregateActions)[] = isLastVisibleColumn()
      ? [...contextMenuItems.filter(c => c !== "removeColumn")]
      : contextMenuItems;

    ContextMenu.show(
      <GridMenu>
        {menuItems.map(key => {
          const icon = key === "groupByColumn" && column.isGrouped ? "small-tick" : "blank";
          return renderMenuItem(
            key,
            t(translationKeys.grid.contextMenu[key]),
            () => handleMenuItemSelect(key, column),
            icon
          );
        })}
        <MenuDivider />
        {numericMenuItem.map(key => {
          const icon = numericMenuItemSelected?.get(id) === key ? "small-tick" : "blank";
          return renderMenuItem(
            key,
            t(translationKeys.grid.contextMenu[key]),
            () =>
              handleMenuItemSelect(key, column),
            icon
          );
        })}
      </GridMenu>,
      { left: e.clientX, top: e.clientY }
    );
  }

  function renderMenuItem(key: ContextMenuItem | AggregateActions, text: string, onSelect: () => void, icon: "small-tick" | "blank") {
    return (
      <>
        <MenuItem key={key} icon={icon} text={text} onClick={onSelect} />
        {(key === "sortDescending" || key === "removeColumn") && (
          <MenuDivider />
        )}
      </>
    );
  }

  function isLastVisibleColumn() {
    const visibleColumns = columns.filter(c => c.isVisible);
    return visibleColumns.length === 1;
  }

  function onSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event?.target;
    const { id } = column;
    debouncer.current?.delay(() => {
      onSearchFilter?.(id, value);
    });
  }

  function handleFilterDialog(val: boolean) {
    setIsFilterOpen(val);
  }

  function handleAggregationSelected(key: string, item: AggregateActions) {
    numericMenuItemSelected.set(key, item);
    setNumericMenuItemSelected(numericMenuItemSelected);
  }

  function onFilterIconClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    handleFilterDialog(true);
    e.stopPropagation();
  }

  function getItemStyle(snapshot: DraggableStateSnapshot, providedProps: DraggableProvidedDraggableProps) {
    const { isDragging, isDropAnimating } = snapshot;
    const { style } = providedProps;
    return {
      ...style,
      userSelect: "none",
      ...isDragging && { boxShadow: "none", width: 100, backgroundColor: "#efecf4ab" },
      ...!isDragging && { transform: "translate(0,0)" },
      ...isDropAnimating && { transitionDuration: "0.001s" },
    };
  }

  function handleMenuItemSelect(key: ContextMenuItem | AggregateActions, column: HeaderColumn<D>) {
    if (numericMenuItemSelected?.get(column.id) === key) {
      numericMenuItemSelected.delete(column.id);
      column.Aggregated = undefined;
      column.aggregate = undefined;
      dispatch({ type: "columnAggregate", columnId: column.id });
      return;
    }
    handleAggregationSelected?.(column.id, key as AggregateActions);

    switch (key) {
      case "sortAscending":
        column.toggleSortBy(false);
        break;
      case "sortDescending":
        column.toggleSortBy(true);
        break;
      case "resetWidth":
        dispatch({ type: "resetWidth", columnId: column.id }); // TODO: write a proper action file
        break;
      case "removeColumn":
        if (isColumnRemoveable(column.id)) {
          column.toggleHidden(true);
          if (column.isGrouped) {
            column.toggleGroupBy();
          }
          onRemoveColumn(column.id);
        }
        break;
      case "groupByColumn":
        column.toggleGroupBy();
        break;
      case "openFilterWindow":
        handleFilterDialog(true);
        break;
      case "sum":
      case "minimum":
      case "maximum":
      case "countAll":
      case "countNotEmpty":
      case "mean":
        column.Aggregated = ({ value }) => getAggregationText(key, value);
        column.aggregate = getAggregationAction(key);
        dispatch({ type: "columnAggregate", columnId: column.id, operation: key, apply: true });
        break;
    }
  }
}

// TODO: Investigate further how to use propsAreEqualWith so many cases
function propsAreEqual<D>(prevProps: HeaderElementProps<D>, nextProps: HeaderElementProps<D>) {
  if (
    nextProps.isResizing
    || prevProps.isResizing !== nextProps.isResizing
    || nextProps.isDragActive
    || nextProps.aggregateColumns.some(c => c.id === nextProps.column.id)
  ) {
    return false;
  }

  if (!_.isEqual(prevProps.showFilter, nextProps.showFilter)) {
    return false;
  }

  if (!_.isEqual(prevProps.columnsMovable, nextProps.columnsMovable)) {
    return false;
  }

  if (prevProps.column.isGrouped !== nextProps.column.isGrouped) {
    return false;
  }

  if (nextProps.column.isSorted) {
    return false;
  }
  const prevVisibleColumns = prevProps.visibleColumns.map(v => v.id);
  const nextVisibleColumns = nextProps.visibleColumns.map(v => v.id);

  if (!_.isEqual(prevVisibleColumns, nextVisibleColumns)) {
    return false;
  }

  if (!_.isEqual(prevProps.columnOrder, nextProps.columnOrder)) {
    return false;
  }

  if (!_.isEqual(prevProps.defaultFilterConfigurations.filterSet, nextProps.defaultFilterConfigurations.filterSet)) {
    return false;
  }

  return prevProps.column.id === nextProps.column.id;
}

export const HeaderItem = React.memo(HeaderElement, propsAreEqual) as typeof HeaderElement;
