import React from "react";
import * as Core from "@blueprintjs/core";
import { Icon, IconName } from "../icon/icon";

export interface NonIdealStateProps {
  icon?: IconName | JSX.Element | false | null | undefined;
  title?: React.ReactNode;
  description?: React.ReactChild;
  children?: React.ReactNode;
}

export const NonIdealState = (props: NonIdealStateProps) => {
  return (
    <Core.NonIdealState
      {...toNativeProps(props)}
    />
  );
};

function toNativeProps(props: NonIdealStateProps): Core.NonIdealStateProps {
  const { icon, ...restProps } = props;

  const nativeIcon: Core.MaybeElement | undefined = icon
    ? <Icon icon={icon} iconSize={Icon.SIZE_LARGE} />
    : undefined;

  return {
    ...restProps,
    icon: nativeIcon,
  };
}
